import { Col, Layout, Menu, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AddressTypeMaster from "./AddressTypeMaster";
import CountryMaster from "./CountryMaster";
import CurrencyMaster from "./CurrencyMaster";
import QuotationStatusMaster from "./QuotationStatusMaster";
import RoleMaster from "./RoleMaster";
import StateMaster from "./StateMaster";
import TaxMaster from "./TaxMaster";
import UnitMaster from "./UnitMaster";
import BankDetailsMaster from "./BankDetailsMaster";
import { selectHasPermission } from "../../store/slices/authSlice";

const Master = () => {
  const hasPermission = useSelector(selectHasPermission);

  const masterItems = [
    { key: "role", label: "Roles", component: RoleMaster, permission: "role" },
    {
      key: "unit",
      label: "Unit",
      component: UnitMaster,
      permission: "unit-master",
    },
    {
      key: "tax",
      label: "Tax",
      component: TaxMaster,
      permission: "tax-master",
    },
    // {
    //   key: "currency",
    //   label: "Currency",
    //   component: CurrencyMaster,
    //   permission: "currency-master",
    // },
    {
      key: "address-type",
      label: "Address Type",
      component: AddressTypeMaster,
      permission: "address-type-master",
    },
    {
      key: "country",
      label: "Country",
      component: CountryMaster,
      permission: "countries",
    },
    {
      key: "state",
      label: "State",
      component: StateMaster,
      permission: "states",
    },
    {
      key: "quotation-status",
      label: "Quotation Status",
      component: QuotationStatusMaster,
      permission: "quotation-status-master",
    },
    {
      key: "bank-details",
      label: "Bank Details",
      component: BankDetailsMaster,
      permission: "company-bank-master",
    },
  ];

  const authorizedItems = masterItems.filter((item) =>
    hasPermission(item.permission, "read")
  );

  const [selectItem, setSelectItem] = useState(authorizedItems[0]?.key);
  const location = useLocation();
  const { type } = location.state || {};

  useEffect(() => {
    if (type) {
      setSelectItem(type);
    }
  }, [type]);

  useEffect(() => {
    if (type && authorizedItems.some((item) => item.key === type)) {
      setSelectItem(type);
    } else if (authorizedItems.length > 0 && !selectItem) {
      setSelectItem(authorizedItems[0].key);
    }
  }, [type, authorizedItems, selectItem]);

  const items = authorizedItems.map((item) => ({
    label: <h1 className="text-[15px]">{item.label}</h1>,
    key: item.key,
  }));

  if (authorizedItems.length === 0) {
    return <div>You don't have permission to access any master data.</div>;
  }

  const showMasterPage = () => {
    const selectedItem = authorizedItems.find(
      (item) => item.key === selectItem
    );
    return selectedItem ? <selectedItem.component /> : null;
  };

  return (
    <div className="bg-white rounded-[12px]  border-full border border-[#BCB4B2] ant-custom-menu">
      <Row className="flex bg-white rounded-full   selected-items">
        <Col span={5} className=" border-[#BCB4B2] border-r selected-items">
          <Layout className="!rounded-[12px] inner-permission-layout">
            <h1 className="pl-[27px] text-[20px] font-monaSansMedium pt-[14px]">
              Master Data
            </h1>

            <Menu
              onSelect={({ key }) => setSelectItem(key)}
              className="pt-2 pb-4 rounded-[12px] master-data-menu"
              theme={"light"}
              mode="inline"
              selectedKeys={[selectItem]}
              items={items}
            />
          </Layout>
        </Col>
        <Col span={19} className="px-[20px]">
          {showMasterPage()}
        </Col>
      </Row>
    </div>
  );
};

export default Master;
