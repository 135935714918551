import { Button, Form, Image, Input } from "antd";
import React, { useState } from "react";
import { MdMailOutline } from "react-icons/md";
import { FiAlertTriangle } from "react-icons/fi";
import loginLogo from "../../assets/img/loginLogoMain.svg";
import { Link, useNavigate } from "react-router-dom";
import back from "../../assets/img/backArrow.svg";
import { actionForgotPassword } from "../../store/services/authService";
import { useDispatch, useSelector } from "react-redux";

const ForgotPassword = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [forgotPasswordForm] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const forgotstatus = useSelector((state) => state.auth);

  const handleSubmit = (values) => {
    dispatch(actionForgotPassword({ values, navigate }))
      .unwrap()
      .then((successMessage) => {
        setErrorMessage(""); // Clear error on success
      })
      .catch((err) => {
        setErrorMessage(err); // Set error message on failure
      });
  };

  return (
    <>
      <div className="md:flex">
        <div className="w-full md:w-1/2 min-h-svh login-bg"></div>
        <div className="w-full md:w-1/2 bg-white min-h-svh flex flex-col justify-center items-center">
          <div>
            <Image src={loginLogo} alt="logo" preview={false} />
          </div>
          <div className="flex items-center flex-col">
            <p className="text-black text-[30px] md:text-[35px] lg:text-[40px] font-monaSansMedium">
              Forgot Password
            </p>
            <p className="text-[#7C7C7C] text-[18px] md:text-[20px] font-monaSansRegular w-[80%] lg:w-[60%] text-center">
              Provide your account email address to receive an email to reset
              your password.
            </p>
          </div>
          <div className="w-[70%] lg:w-[60%] mt-2 lg:mt-4">
            <Form
              form={forgotPasswordForm}
              className=""
              layout="vertical"
              onFinish={handleSubmit}
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    message: "Please input valid email!",
                  },
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  className="py-[10px] rounded-[10px]"
                  suffix={<MdMailOutline color="#8D8D8D" />}
                />
              </Form.Item>
               {/* Display error message if exists */}
               {errorMessage && (
                <div className="bg-[#f52e2e26] p-3 w-full rounded-md font-monaSansMedium text-center flex items-center gap-x-2 text-[15px] text-[#f52e2e] mb-4">
                  <FiAlertTriangle className="size-4" />
                  <p>{errorMessage}</p>
                </div>
              )}
              <Form.Item>
                <Button
                   loading={forgotstatus?.forgotPasswordLoader}
                  type="primary"
                  className="root-btn text-[20px] mt-2 md:text-base text-sm border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
                  block
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
              <Form.Item className="mt-1">
                <Link
                  to="/login"
                  className="flex justify-center items-center gap-x-2"
                >
                  <Image
                    src={back}
                    alt="back"
                    preview={false}
                  />
                  <p className="text-secondary hover:text-secondary/90 md:text-[20px] text-[16px] font-monaSansMedium">
                    Back to sign in
                  </p>
                </Link>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
