import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../config/web-config';

const RequestQuotationIframe = () => {
    const { id } = useParams();
    const [iframeUrl, setIframeUrl] = useState('');
    const [error, setError] = useState(null);
    const [iframeLoaded, setIframeLoaded] = useState(false);

    useEffect(() => {
        if (!BASE_URL) {
            console.error('BASE_URL is not defined');
            setError('Configuration error: BASE_URL is not defined');
            return;
        } let local = true;
        const turl = local ? "http://localhost:3000" : BASE_URL;
        const url = `${turl}/quotation/create/${id}`;
        console.log('Iframe URL:', url);
        setIframeUrl(url);
    }, [id]);

    const handleIframeLoad = () => {
        console.log('Iframe loaded successfully');
        setIframeLoaded(true);
    };

    const handleIframeError = () => {
        console.error('Iframe failed to load');
        setError('Failed to load the quotation form. Please try again later.');
    };

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className='inline-flex items-center justify-start flex-col w-full py-5 gap-y-5'>
            <h2 className='font-monaSansSemiBold text-center text-xl mb-3'>Request Quotation</h2>
            {!iframeLoaded && <div>Loading...</div>}
            {iframeUrl && (
                <iframe
                    src={iframeUrl}
                    className='w-11/12 lg:w-1/2'
                    style={{
                        minHeight: "100vh",
                        margin: "auto",
                        display: iframeLoaded ? "block" : "none"
                    }}
                    title="Request Quotation Form"
                    allowFullScreen
                    onLoad={handleIframeLoad}
                    onError={handleIframeError}
                />
            )}

        </div>
    );
};

export default RequestQuotationIframe;