import React from "react";
import { ConfigProvider } from "antd";

const ConfigProviderWrapper = ({ children }) => (
  <ConfigProvider
    theme={{
      components: {
        Input: {
          controlHeight: 40,
        },
        Table: {
          cellPaddingBlock: 10,
        },
      },
    }}
  >
    {children}
  </ConfigProvider>
);

export default ConfigProviderWrapper;
