import { Button, Form, Image, Input } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import loginLogo from "../../assets/img/loginLogoMain.svg";
import { actionResetPassword } from "../../store/services/authService";

const ResetPassword = () => {
  const [resetPasswordForm] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resetStatus = useSelector((state) => state.auth);
  const token = useLocation();
  const tokenSubmit = token.search.split("=")[1];

  useEffect(() => {
    if (!tokenSubmit) {
      navigate("/login");
    }
  }, [tokenSubmit, navigate]);

  const onFinish = (values) => {
    const request = {
      password: values.password,
      token: tokenSubmit,
    };
    dispatch(actionResetPassword({ request, navigate }));
  };
  return (
    <>
      <div className="md:flex">
        <div className="w-full md:w-1/2 min-h-svh login-bg"></div>
        <div className="w-full md:w-1/2 bg-white min-h-svh flex flex-col justify-center items-center">
          <div>
            <Image
              src={loginLogo}
              width={200}
              height={200}
              alt="logo"
              preview={false}
            />
          </div>
          <div className="flex items-center flex-col">
            <p className="text-black text-[30px] md:text-[35px] lg:text-[40px] font-monaSansMedium">
              Reset Password
            </p>
            <p className="text-[#7C7C7C] text-[18px] md:text-[20px] font-monaSansRegular w-[80%] lg:w-[70%] text-center">
              Your new password must be different from previous used passwords.
            </p>
          </div>
          <div className="w-[70%] lg:w-[60%]">
            <Form
              form={resetPasswordForm}
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                  {
                    min: 8,
                    message:
                      "passwords must be longer than or equal to 8 characters !",
                  },
                  {
                    max: 20,
                    message: "Password cannot exceed 20 characters!",
                  },
                ]}
              >
                <Input.Password
                  placeholder="New password"
                  className="py-[10px]"
                />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirm_new_password"
                style={{ marginBottom: 15 }}
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your new password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirm New Password"
                  className="py-[10px]"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={resetStatus?.resetPasswordLoader}
                  type="primary"
                  className="root-btn text-[20px] mt-3 lg:mt-5 md:text-base text-sm border-none font-popinsMedium tracking-wider min-h-[45px]"
                  block
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
