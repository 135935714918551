import {
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  PrinterOutlined,
  WhatsAppOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  ConfigProvider,
  Dropdown,
  Input,
  Modal,
  Pagination,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomSearchInput from "../common/CustomSearchInput";
import PageTitle from "../common/PageTitle";
import SortIcon from "../common/SortIcon";
import { getFirstLetterCapital, getFullName } from "../helper/common-function";
import DeleteModal from "../common/DeleteModal";
import { actionGetAllProducts } from "../../store/services/productService";
import {
  actionDeleteQuotation,
  actionGetQuotationDetails,
  actionGetQuotationInvoiceData,
  actionGetQuotations,
  actionQuotationSendEmail,
} from "../../store/services/quotationService";
import { actionGetQuotationStatusMaster } from "../../store/services/quotationStatusMasterService";
import Invoice from "./Invoice";
import ViewQuotation from "./ViewQuotation";

const createOptions = (data, labelKey, valueKey) =>
  data?.map((item) => ({
    key: item[valueKey],
    label: item[labelKey],
    value: item[valueKey],
  })) || [];

export const getStatusClass = (status) => {
  const baseClasses = "px-3 py-1.5 rounded-[5px] text-sm font-monaSansMedium";

  const statusConfig = {
    expired: "text-[#DD1B1B] border border-[#DD1B1B]",
    active: "text-[#0587FF] border border-[#0587FF]",
    completed: "text-[#15A02B] border border-[#15A02B]",
    pending: "text-[#C69619] border border-[#C69619]",
    "on hold": "text-[#FF9800] border border-[#FF9800]",
    canceled: "text-[#E53935] border border-[#E53935]",
    "under negotiation": "text-[#673AB7] border border-[#673AB7]",
    rejected: "text-[#F44336] border border-[#F44336]",
    sent: "text-[#00BCD4] border border-[#00BCD4]",
    "awaiting customer response": "text-[#3F51B5] border border-[#3F51B5]",
    accepted: "text-[#4CAF50] border border-[#4CAF50]",
    draft: "text-[#607D8B] border border-[#607D8B]",
    approved: "text-[#009688] border border-[#009688]",
  };

  return `${baseClasses} ${statusConfig[status.toLowerCase()] || ""}`;
};

const Quotations = () => {
  const [search, setSearch] = useState("");
  const offsetRef = useRef(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    getQuotationsLoader,
    deleteQuotationLoader,
    quotations,
    allQuotationsCount,
    quotationSendEmailLoader,
    getQuotationInvoiceDataLoader,
  } = useSelector((state) => state.quotation);
  const { getQuotationStatusMasterLoader, quotationStatusMasterData } =
    useSelector((state) => state.quotationStatusMaster);

  const [dropdownVisible, setDropdownVisible] = useState({});
  const [quotationId, setQuotationId] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [viewQuotationModalOpen, setViewQuotationModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({ status: "" });

  const getApiCall = (
    offset = 0,
    search = "",
    orderByColumn = "",
    order = "",
    status = ""
  ) => {
    dispatch(
      actionGetQuotations({
        offset,
        orderByColumn,
        order,
        search,
        status,
      })
    );
  };

  useEffect(() => {
    const status = searchParams.get("status") || "";
    getApiCall(offsetRef.current, "", "", "", status);
  }, [searchParams]);

  useEffect(() => {
    dispatch(actionGetQuotationStatusMaster());
  }, []);

  const quotationStatusOptions = useMemo(
    () =>
      createOptions(
        quotationStatusMasterData,
        "quotation_status",
        "quotation_status"
      ),
    [quotationStatusMasterData]
  );

  const columns = [
    {
      title: "Customer / Company Name",
      key: "customer",
      width: 250,
      fixed: "left",
      dataIndex: "customer",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,

      render: (text, data) => (
        <>
          <span
            className="cursor-pointer text-blue-500 hover:text-blue-600"
            onClick={() => {
              setQuotationId(data.id);
              setViewQuotationModalOpen(true);
            }}
          >
            {getFirstLetterCapital(text?.customer_name)}
          </span>
        </>
      ),
    },
    {
      title: "TIN Number",
      key: "tin_number",
      width: 150,
      dataIndex: "tin_number",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span>{text || "-"}</span>,
    },

    {
      title: "Status",
      key: "quotation_status",
      width: 150,
      ellipsis: true,
      dataIndex: "quotation_status",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span
          style={{
            maxWidth: 250,
            display: "inline-block",
            textAlign: "center",
            minWidth: 100,
          }}
          className={getStatusClass(text?.quotation_status.toLowerCase())}
        >
          {text?.quotation_status}
        </span>
      ),
    },
    {
      title: "Quotation Date",
      key: "quotation_date",
      width: 150,
      dataIndex: "quotation_date",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span>{text ? dayjs(text).format("D MMM YYYY") : "-"}</span>
      ),
    },
    {
      title: "Expiry Date",
      key: "expiry_date",
      width: 150,
      dataIndex: "expiry_date",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span>{text ? dayjs(text).format("D MMM YYYY") : "-"}</span>
      ),
    },
    {
      title: "Owner",
      key: "created_by",
      width: 200,
      dataIndex: "created_by",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <div className="flex items-center">
          <p className="ml-2">
            {getFullName(text?.first_name, text?.last_name)}
          </p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown.Button
            open={dropdownVisible[data.id]}
            onOpenChange={(visible) =>
              setDropdownVisible((prev) => ({ ...prev, [data.id]: visible }))
            }
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () =>
                    navigate("/update-quotation", {
                      state: { quotationId: data.id },
                    }),
                },
                {
                  label: <span>Email</span>,
                  key: "email",
                  icon: <MailOutlined />,
                  onClick: (e) => {
                    console.log(e, "e");
                    e.domEvent.stopPropagation();
                    dispatch(actionQuotationSendEmail(data.id)).then(
                      (result) => {
                        console.log(result, "result");
                        if (result.type.endsWith("/fulfilled")) {
                          setDropdownVisible((prev) => ({
                            ...prev,
                            [data.id]: false,
                          }));
                        }
                      }
                    );
                  },
                },
                {
                  label: <span>Print</span>,
                  key: "print",
                  icon: <PrinterOutlined />,
                  onClick: () => {
                    const quotationId = data?.id;
                    dispatch(actionGetQuotationInvoiceData(quotationId));
                  },
                },
                {
                  label: "Delete",
                  key: "Delete",
                  danger: true,
                  icon: <DeleteOutlined />,
                  onClick: () => {
                    setIsDeleteModalOpen(true);
                    setQuotationId(data.id);
                  },
                },
              ].filter(Boolean),
            }}
          >
            Action
          </Dropdown.Button>
        </>
      ),
    },
  ];

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      // getApiCall(offsetRef.current, e.target.value);
      getApiCall(
        offsetRef.current,
        e.target.value,
        "",
        "",
        searchParams.get("status") || ""
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      // getApiCall(offsetRef.current, e.target.value);
      getApiCall(
        offsetRef.current,
        e.target.value,
        "",
        "",
        searchParams.get("status") || ""
      );
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    getApiCall(
      offsetRef.current,
      search,
      sorterParams.field,
      sorterParams.order,
      searchParams.get("status") || ""
    );
  };

  const handleCloseQuotationDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setQuotationId("");
  };

  const handleCloseQuotationViewModal = () => {
    setViewQuotationModalOpen(false);
    setQuotationId("");
  };
  const modalStyles = {
    mask: {
      backdropFilter: "blur(10px)",
    },
  };

  return (
    <>
      <Spin spinning={getQuotationInvoiceDataLoader}>
        <PageTitle
          title="Quotations"
          buttonText="Add Quotation"
          buttonLink="/create-quotation"
        />
        <section className="grid 2xl:grid-cols-[80%_20%] xl:grid-cols-[75%_25%] lg:grid-cols-[60%_40%] gap-3">
          <Space.Compact className="w-full custom-input relative flex mb-5">
            <Input
              value={search}
              onChange={handleSearch}
              onBlur={handleBlurAndPressEnter}
              onPressEnter={handleBlurAndPressEnter}
              size="large"
              className="hover:border-[#BCB4B2] max-h-[45px] h-[45px] bg-transparent"
              id="none-border"
              allowClear
              prefix={<SearchOutlined className="text-[#7C7C7C]" />}
              placeholder="Search"
            />
          </Space.Compact>
          <ConfigProvider
            theme={{
              components: { Select: { colorBgContainer: "white" } },
            }}
          >
            <Select
              allowClear
              showSearch
              loading={getQuotationStatusMasterLoader}
              disabled={getQuotationStatusMasterLoader}
              placeholder="Select Status"
              optionFilterProp="children"
              className="!bg-transparent"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              onChange={(value) => {
                if (value) {
                  setSearchParams({ status: value });
                } else {
                  setSearchParams({});
                }
                offsetRef.current = 0;
                getApiCall(0, search, "", "", value || "");
              }}
              options={quotationStatusOptions}
              value={searchParams.get("status") || undefined}
            />
          </ConfigProvider>
        </section>

        <Table
          sticky
          rowKey="id"
          scrollToFirstRowOnChange={true}
          sortDirections={["ascend", "descend"]}
          onChange={handleSortChange}
          bordered
          scroll={{
            x: "max-content",
            y: window.innerWidth < 1500 ? "49vh" : "61vh",
          }}
          pagination={false}
          columns={columns}
          dataSource={quotations}
          loading={getQuotationsLoader || quotationSendEmailLoader}
        />
        <div className="flex justify-end mt-3">
          <Pagination
            hideOnSinglePage
            responsive={true}
            current={offsetRef.current + 1}
            className="flex items-center"
            total={allQuotationsCount}
            onChange={(e) => {
              offsetRef.current = e - 1;
              getApiCall(
                offsetRef.current,
                search,
                "",
                "",
                searchParams.get("status") || ""
              );
            }}
          />

          <DeleteModal
            title="Are you sure you want to delete this quotation ?"
            description="This action is irreversible."
            loading={deleteQuotationLoader}
            onCancel={handleCloseQuotationDeleteModal}
            onConfirm={() =>
              dispatch(
                actionDeleteQuotation({
                  quotationId,
                  handleCloseQuotationDeleteModal,
                })
              )
            }
            isVisible={isDeleteModalOpen}
          />
          <Modal
            className=""
            centered={true}
            footer={false}
            width={900}
            handleReset={handleCloseQuotationViewModal}
            afterClose={handleCloseQuotationViewModal}
            open={viewQuotationModalOpen}
            title=""
            onCancel={handleCloseQuotationViewModal}
            onOk={handleCloseQuotationViewModal}
            styles={modalStyles}
          >
            <ViewQuotation quotationId={quotationId} />
          </Modal>
        </div>
      </Spin>
    </>
  );
};

export default Quotations;
