import { Button, Checkbox, Form, Image, Input } from "antd";
import React, { useState } from "react";
import { FiAlertTriangle } from "react-icons/fi";
import { MdMailOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import loginLogo from "../../assets/img/loginLogoMain.svg";
import { actionLogin } from "../../store/services/authService";

const Login = () => {
  const [errorMessage, setErrorMessage] = useState(""); // New state for error message
  const [loginForm] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loginLoader } = useSelector((state) => state.auth);

  const handleFinish = (values) => {
    const { remember_me, ...credentials } = values;

    dispatch(actionLogin({ credentials, navigate, remember_me }))
      .unwrap()
      .then(() => {
        setErrorMessage("");
      })
      .catch((err) => {
        setErrorMessage(err);
      });
  };

  return (
    <>
      <div className="md:flex">
        <div className="w-full md:w-1/2 min-h-svh login-bg"></div>
        <div className="w-full md:w-1/2 bg-white min-h-svh flex flex-col justify-center items-center">
          <div>
            <Image src={loginLogo} alt="logo" preview={false} />
          </div>
          <div className="text-center">
            <p className="text-black text-[30px] md:text-[35px] lg:text-[40px] font-monaSansMedium">
              Log in to your Account
            </p>
            <p className="text-[#7C7C7C] text-[18px] md:text-[20px] font-monaSansRegular">
              Please Enter your Information Below
            </p>
          </div>
          <div className="w-[70%] lg:w-[60%]">
            <Form
              form={loginForm}
              className=""
              layout="vertical"
              onFinish={handleFinish}
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    message: "Please input valid email!",
                  },
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  className="py-[10px] rounded-[10px]"
                  suffix={<MdMailOutline color="#8D8D8D" />}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  className="py-[10px] rounded-[10px]"
                />
              </Form.Item>
              <div className="flex justify-between items-start">
                <Form.Item name="remember_me" valuePropName="checked">
                  <Checkbox className="text-sm font-monaSansRegular">
                    Remember Me
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <Link
                    to="/forgot-password"
                    className="text-primary hover:text-primary/90 font-monaSansMedium"
                  >
                    Forgot Password?
                  </Link>
                </Form.Item>
              </div>
              {/* Display error message if exists */}
              {errorMessage && (
                <div className="bg-[#f52e2e26] p-3 w-full rounded-md font-monaSansMedium text-center flex items-center gap-x-2 text-[15px] text-[#f52e2e] mb-4">
                  <FiAlertTriangle className="size-4" />
                  <p>{errorMessage}</p>
                </div>
              )}
              <Form.Item>
                <Button
                  loading={loginLoader}
                  type="primary"
                  className="root-btn text-[20px] mt-2 md:text-base text-sm border-none rounded-[10px] font-popinsMedium tracking-wider min-h-[45px]"
                  block
                  htmlType="submit"
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
