import { createSlice } from "@reduxjs/toolkit";
import {
  actionGetCompanyDetail,
  actionUpdateCompanyDetail,
} from "../services/companyDetailService";

const companyDetailSlice = createSlice({
  name: "companyDetailMaster",
  initialState: {
    getCompanyDetailLoader: false,
    updateCompanyDetailLoader: false,
    companyDetail: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(actionGetCompanyDetail.pending, (state) => {
        state.getCompanyDetailLoader = true;
      })
      .addCase(actionGetCompanyDetail.fulfilled, (state, action) => {
        state.companyDetail = action.payload.data;
        state.getCompanyDetailLoader = false;
      })
      .addCase(actionGetCompanyDetail.rejected, (state) => {
        state.getCompanyDetailLoader = false;
      })

      //action update company details
      .addCase(actionUpdateCompanyDetail.pending, (state) => {
        state.updateCompanyDetailLoader = true;
      })
      .addCase(actionUpdateCompanyDetail.fulfilled, (state, action) => {
        state.companyDetail = action.payload;

        state.updateCompanyDetailLoader = false;
      })
      .addCase(actionUpdateCompanyDetail.rejected, (state) => {
        state.updateCompanyDetailLoader = false;
      });
  },
});
export default companyDetailSlice.reducer;
