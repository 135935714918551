import { Button, message, Spin } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import logoInvoice from "../../assets/img/logoInvoiceMain.png";
import {
  actionDownloadQuotation,
  actionGetQuotationDetails,
  actionGetQuotationInvoiceData,
} from "../../store/services/quotationService";
import { amountInWords, formatAddress } from "../helper/common-function";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { toast } from "sonner";

const ViewQuotation = ({ quotationId }) => {
  const dispatch = useDispatch();

  const { myCurrency } = useSelector((state) => state.auth);
  const {
    getQuotationDetailsData,
    getQuotationDetailsLoader,
    getQuotationInvoiceDataLoader,
  } = useSelector((state) => state.quotation);
  useEffect(() => {
    if (quotationId) {
      dispatch(actionGetQuotationDetails(quotationId));
    }
  }, [quotationId]);

  const downloadInvoice = () => {
    // setInvoiceLoader(true);
    axios({
      url: `${BASE_URL}/quotation/invoice/${quotationId}`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "quotation.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      const res = await axios.get(
        `${BASE_URL}/quotation/invoice/${quotationId}`
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        const { url } = data;

        const fileResponse = await fetch(url);
        const blob = await fileResponse.blob();

        const downloadUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = "quotation.pdf";
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      console.error("Error downloading quotation:", error);
      toast.error("Failed to download the quotation");
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Spin spinning={getQuotationDetailsLoader}>
      <div>
        <div className="w-full bg-white flex justify-center  py-5 mt-2">
          <div className="bg-white border w-full border-[#A1A1A1]">
            <div className="flex justify-between items-center border-b border-[#A1A1A1] p-4">
              <img src={logoInvoice} alt="Company Logo" className="h-16" />
              {/* Company Details */}
              <div className="text-right">
                <h1 className="text-[20px] font-monaSansSemiBold">
                  PROFORMA INVOICE
                </h1>
                <p className="text-[13px] font-monaSansMedium">
                  #44 KN1 RD, Muhima, Kigali
                </p>
                <p className="text-[13px] font-monaSansMedium">
                  info@finefurniture.rw | www.finefurniture.rw
                </p>
                <p className="text-[13px] font-monaSansMedium">
                  +250 788 330 000 | +250 738 330 000
                </p>
              </div>
            </div>

            {/* Invoice and Customer Details */}
            <div className="flex justify-between text-black">
              {/* Invoice Information */}
              <div className="w-[50%] py-4 pl-4 flex gap-x-3 border-r border-[#A1A1A1]">
                <div className="font-monaSansBold text-[13px]">
                  <p>Proforma Invoice No:</p>
                  <p>Quotation Date:</p>
                  <p>Validity:</p>
                  <p>Due Date:</p>
                  <p>TIN:</p>
                </div>
                <div className="font-monaSansMedium text-[13px]">
                  <p>
                    {getQuotationDetailsData.invoice_number
                      ? getQuotationDetailsData.invoice_number
                      : "-"}
                  </p>
                  <p>
                    {dayjs(getQuotationDetailsData.quotation_date).format(
                      "DD/MM/YYYY"
                    )}
                  </p>
                  <p>{getQuotationDetailsData.validity || "-"}</p>
                  <p>
                    {getQuotationDetailsData.expiry_date
                      ? dayjs(getQuotationDetailsData.expiry_date).format(
                          "DD/MM/YYYY"
                        )
                      : "-"}
                  </p>
                  <p>{getQuotationDetailsData.tin_number || "-"}</p>
                </div>
              </div>

              {/* Customer Details */}
              <div className="w-[50%]">
                <div className="bg-[#2B2526] text-white py-2 text-center">
                  Customer Details
                </div>
                <div className="py-2 pl-3 text-[13px]">
                  <div className="flex items-start justify-between">
                    <p className="w-[30%] font-semibold">Company Name:</p>
                    <p className="w-[65%] text-left font-monaSansMedium">
                      {getQuotationDetailsData?.customer?.customer_name || "-"}
                    </p>
                  </div>
                  <div className="flex items-start justify-between">
                    <p className="w-[30%] font-semibold">Address:</p>
                    <p className="w-[65%] text-left font-monaSansMedium">
                      {formatAddress(
                        getQuotationDetailsData?.customer
                          ?.customers_address_master[0]
                      )}
                    </p>
                  </div>
                  <div className="flex items-start justify-between">
                    <p className="w-[30%] font-semibold">Mobile Number:</p>
                    <p className="w-[65%] text-left font-monaSansMedium">
                      {`+${getQuotationDetailsData?.customer?.country_code} ${getQuotationDetailsData?.customer?.phone}`}
                    </p>
                  </div>
                  <div className="flex items-start justify-between">
                    <p className="w-[30%] font-semibold">TIN Number:</p>
                    <p className="w-[65%] text-left font-monaSansMedium">
                      {getQuotationDetailsData?.customer?.tin_number || "-"}
                    </p>
                  </div>
                  <div className="flex items-start justify-between">
                    <p className="w-[30%] font-semibold">Email:</p>
                    <p className="w-[65%] text-left font-monaSansMedium">
                      {getQuotationDetailsData?.customer?.email || "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Items Table */}
            <table className="w-full">
              <thead>
                <tr className="border-t border-[#a1a1a1] text-[15px] font-semibold bg-[#F9F6F4]">
                  <th className="px-2 py-2 text-left border-b border-[#a1a1a1] w-[5%]">
                    #
                  </th>
                  <th className="border-l border-b border-[#a1a1a1] pl-2 py-2 text-left w-[7%]">
                    Model No
                  </th>
                  <th className="border-l border-b border-[#a1a1a1] px-2 py-2 text-left w-[22%]">
                    Description
                  </th>
                  <th className="border-l border-b border-[#a1a1a1] px-2 py-2 text-left w-[18%]">
                    Image
                  </th>

                  <th className="border-l border-b border-[#a1a1a1] px-2 py-2 text-left w-[8%]">
                    Qty
                  </th>
                  <th className="border-l border-b border-[#a1a1a1] px-2 py-2 text-left w-[9%]">
                    Rate ({myCurrency})
                  </th>
                  <th className="border-l border-b border-[#a1a1a1] px-2 py-2 text-left w-[9%]">
                    Discount
                  </th>
                  <th className="border-l border-b border-[#a1a1a1] px-2 py-2 text-left w-[9%]">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody className="font-monaSansMedium text-black">
                {getQuotationDetailsData?.quotation_items?.map(
                  (item, index) => (
                    <tr key={index} className="border-b border-[#a1a1a1]">
                      <td className="px-2 py-2 text-left border-b border-[#a1a1a1] text-[15px]">
                        {index + 1}
                      </td>
                      <td className="border-l border-b border-[#a1a1a1] pl-2 py-2 text-left text-[13px]">
                        <div>
                          <p className="font-monaSansRegular">
                            {item.material?.material_name}
                          </p>
                          <p className="text-[15px] font-monaSansMedium leading-3">
                            - {item.material.material_number}
                          </p>
                        </div>
                      </td>

                      <td className="border-l border-b border-[#a1a1a1] pl-2 py-2 text-left text-[12px] text-[#676767] font-monaSansMedium">
                        {item.quotation_items_description || "-"}
                      </td>
                      <td className="border-l border-b border-[#a1a1a1] py-2">
                        <img
                          src={item.material.material_image?.url}
                          alt="Image"
                          className="object-cover rounded-md mx-auto"
                          style={{ height: "162px", width: "162px" }} // Set the height and width here
                        />
                      </td>

                      <td className="border-l border-b border-[#a1a1a1] pl-2 py-2 text-left ">
                        {item.quantity || "-"}
                      </td>
                      <td className="border-l border-b border-[#a1a1a1] pl-2 py-2 text-left ">
                        {item.quotation_items_rate || "-"}
                      </td>
                      <td className="border-l border-b border-[#a1a1a1] pl-2 py-2 text-left">
                        {item.discount || "-"}
                      </td>
                      <td className="border-l border-b border-[#a1a1a1] pl-2 py-2 text-left">
                        {item.total_item_amount || "-"}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>

            {/* Summary Section */}
            <div className="grid grid-cols-2">
              {/* Total in Words */}
              <div className="border-r border-[#a1a1a1] p-4">
                <p className="text-[13px] font-monaSansBold">Total in Words</p>
                <p className="text-[#7C7C7C] text-[12px] font-monaSansMedium">
                  {amountInWords(getQuotationDetailsData?.total, myCurrency) ||
                    "-"}
                </p>
                <p className="text-[13px] font-monaSansBold mt-1">
                  Terms & Conditions
                </p>
                <p className="text-[#7C7C7C] text-[12px] font-monaSansMedium">
                  {getQuotationDetailsData.terms_and_conditions || "-"}
                </p>
                <div className="flex h-[100px] justify-between mt-2">
                  <div>
                    <p className="text-[13px] font-monaSansBold">
                      Prepared by:
                    </p>
                    {/* <img
                    src={item.material_image?.url}
                    alt="Image"
                    className="h-12 w-12 object-cover rounded-md"
                  /> */}
                    {/* <p className="text-[13px] font-monaSansMedium">-</p> */}
                  </div>
                  <div className="text-right">
                    <p className="text-[13px] font-monaSansBold">
                      Authorised by:
                    </p>
                    {/* {getQuotationDetailsData.authorized_by} */}
                    {/*  <img
                    src={getQuotationDetailsData.authorized_by}
                    alt="Image"
                    className="h-12 w-12 object-cover rounded-md"
                  /> */}
                  </div>
                </div>
              </div>

              {/* Amount Summary */}
              <div className="flex flex-col px-3 py-4">
                {/* <div className="flex justify-between">
                <p className="text-[15px] font-monaSansSemiBold">
                  Sub Total (RWF)
                </p>
                <p className="text-[15px] font-monaSansSemiBold">
                  {getQuotationDetailsData.sub_total}
                </p>
              </div> */}
                {/* {getQuotationDetailsData.taxes &&
                getQuotationDetailsData.taxes.length > 0 &&
                getQuotationDetailsData.taxes.map((tax,index) => (
                  <div key={index} className="flex justify-between">
                    <p className="text-[15px] font-monaSansMedium">
                      {tax.name}
                    </p>
                    <p className="text-[15px] font-monaSansMedium text-[#7C7C7C]">
                      {tax.tax_rate}%
                    </p>
                  </div>
                ))} */}

                {/* <div className="flex justify-between">
                <p className="text-[15px] font-monaSansMedium">Tax Amount</p>
                <p className="text-[15px] font-monaSansMedium text-[#7C7C7C]">
                  {getQuotationDetailsData.tax_amount}
                </p>
              </div> */}
                <div className="flex justify-between w-full">
                  <p className="text-[15px] font-monaSansBold">
                    Total ({myCurrency})
                  </p>
                  <p className="text-[15px] font-monaSansBold">
                    {getQuotationDetailsData.total}
                  </p>
                </div>
                <p className="text-[13px] font-monaSansMedium text-black">
                  VAT 18% Included
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-x-4 justify-end">
          <Button
            className="cancel-btn text-white hover:text-white py-5 flex-center radius w-[150px]"
            loading={isDownloading}
            onClick={handleDownload}
            disabled={isDownloading}
          >
            {isDownloading ? "Downloading..." : "Download"}
          </Button>
          <Button
            className="root-btn text-white hover:text-white py-5 flex-center radius w-[150px]"
            loading={getQuotationInvoiceDataLoader}
            onClick={() => dispatch(actionGetQuotationInvoiceData(quotationId))}
          >
            Print
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default ViewQuotation;
