import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import axios from "axios";
import { toast } from "sonner";
import { handleDeleteResponse, handleGetResponse } from "./commonService";
import { handleErrorMessage } from "../../components/helper/common-function";

//add State Master
export const actionAddStateMaster = createAsyncThunk(
  "actionAddStateMaster",
  async (
    { req, handleCancel, setSelectedCountry },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios.post(`${BASE_URL}/states`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        setSelectedCountry && setSelectedCountry(data?.country?.id);
        dispatch(actionGetStates({ id: data?.country?.id }));
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete State Master
export const actionDeleteStateMaster = createAsyncThunk(
  "actionDeleteStateMaster",
  async ({ countryId, id, setEditingKey }) => {
    return handleDeleteResponse(`states/${countryId}/${id}`, id, setEditingKey);
  }
);

//get single State Master
export const actionGetSingleStateMaster = createAsyncThunk(
  "actionGetSingleCountryTypeMaster",
  ({ countryId, id }) => {
    return handleGetResponse(`states/${countryId}/${id}`);
  }
);

//update State Master
export const actionEditStateMaster = createAsyncThunk(
  "actionEditStateMaster",
  async (
    { id, req, handleCancel, setSelectedCountry },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axios.patch(`${BASE_URL}/states/${id}`, req);
      const { status, message: customMsg, data } = response.data;

      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        setSelectedCountry && setSelectedCountry(data?.country?.id);
        dispatch(actionGetStates({ id: data?.country?.id }));
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to get all states of a particular country
export const actionGetStates = createAsyncThunk(
  "actionGetStates",
  async ({
    id = "",
    search = "",
    orderByColumn = "",
    order = "",
    offset = 0,
  } = {}) => {
    const queryParams = new URLSearchParams();

    if (search) queryParams.append("search", search);
    queryParams.append("limit", 10);
    if (orderByColumn) queryParams.append("orderByColumn", orderByColumn);
    if (order) queryParams.append("order", order);
    if (offset) queryParams.append("offset", offset);

    // Construct the final URL
    const url = `states/${id}?${queryParams.toString()}`;

    // Call the API
    return handleGetResponse(url);
  }
);

export const actionGetAllStates = createAsyncThunk(
  "actionGetAllStates",
  (id) => {
    return handleGetResponse(`states/${id}?getall=YES`);
  }
);

// Action to get All states of particular country
export const actionAllStatesIndex = createAsyncThunk(
  "actionAllStatesIndex",
  async ({ countryId, index }, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${BASE_URL}/states/${countryId}?getall=YES`);
      const { status, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        return res.data;
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
