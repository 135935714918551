import { createBrowserRouter, Navigate } from "react-router-dom";
import ForgotPassword from "../components/auth/ForgotPassword";
import Login from "../components/auth/Login";
import ResetPassword from "../components/auth/ResetPassword";
import Root from "../components/common/Root";
import {
  PrivateRoute,
  PrivateRouteWithoutLayout,
  PublicRoute,
} from "../components/helper/RouteHoc";
import Master from "../components/masters/Master";
import CreateQuotation from "../components/quotations/CreateQuotation";
import Quotations from "../components/quotations/Quotations";
import CreateUser from "../components/user/CreateUser";
import Users from "../components/user/Users";
import Customers from "../components/customers/Customers";
import CreateCustomer from "../components/customers/CreateCustomer";
import AddRole from "../components/masters/AddRole";
import ModulePermissions from "../components/masters/ModulePermission";
import CompanyDetail from "../components/company/CompanyDetail";
import ChangePassword from "../components/auth/ChangePassword";
import CreateProduct from "../components/products/CreateProduct";
import Products from "../components/products/Products";
import Invoice from "../components/quotations/Invoice";
import Dashboard from "../components/dashboard/Dashboard";
import { useSelector } from "react-redux";
import { selectHasPermission } from "../store/slices/authSlice";
import NotFound from "../components/common/NotFound";
import ViewCustomer from "../components/customers/ViewCustomer";
import RequestQuotation from "../components/quotations/RequestQuotation";
import RequestQuotationForm from "../components/quotations/RequestQuotationForm";
import RequestQuotationIframe from "../components/quotations/RequestQuotationIframe";

const ProtectedRoute = ({ element, requiredPermission }) => {
  const { authMeLoader } = useSelector((state) => state.auth);
  const hasPermission = useSelector(selectHasPermission);

  if (authMeLoader) {
    return null;
  }

  if (!requiredPermission) {
    return element;
  }

  return hasPermission(
    requiredPermission.resource,
    requiredPermission.action
  ) ? (
    element
  ) : (
    <Navigate to="/" replace />
  );
};

export const router = createBrowserRouter([
  {
    element: <PublicRoute />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
    ],
  },

  {
    element: <PrivateRoute component={<Root />} />,
    children: [
      {
        path: "/",
        index: true,
        element: <ProtectedRoute element={<Dashboard />} />,
      },
      {
        path: "/quotations",
        element: (
          <ProtectedRoute
            element={<Quotations />}
            requiredPermission={{ resource: "quotation", action: "read" }}
          />
        ),
      },
      {
        path: "/request-quotations",
        element: (
          <ProtectedRoute
            element={<RequestQuotation />}
            requiredPermission={{
              resource: "request-for-quotation",
              action: "read",
            }}
          />
        ),
      },
      {
        path: "/update-quotation",
        element: (
          <ProtectedRoute
            element={<CreateQuotation />}
            requiredPermission={{ resource: "quotation", action: "update" }}
          />
        ),
      },
      {
        path: "/masters",
        element: <ProtectedRoute element={<Master />} />,
      },
      {
        path: "/create-quotation",
        element: (
          <ProtectedRoute
            element={<CreateQuotation />}
            requiredPermission={{ resource: "quotation", action: "create" }}
          />
        ),
      },
      {
        path: "/view-customer",
        element: <ViewCustomer />,
      },
      {
        path: "/users",
        element: (
          <ProtectedRoute
            element={<Users />}
            requiredPermission={{ resource: "users", action: "read" }}
          />
        ),
      },
      {
        path: "/create-user",
        element: (
          <ProtectedRoute
            element={<CreateUser />}
            requiredPermission={{ resource: "users", action: "create" }}
          />
        ),
      },
      {
        path: "/update-user",
        element: (
          <ProtectedRoute
            element={<CreateUser />}
            requiredPermission={{ resource: "users", action: "update" }}
          />
        ),
      },
      {
        path: "/customers",
        element: (
          <ProtectedRoute
            element={<Customers />}
            requiredPermission={{ resource: "customers", action: "read" }}
          />
        ),
      },
      {
        path: "/create-customer",
        element: (
          <ProtectedRoute
            element={<CreateCustomer />}
            requiredPermission={{ resource: "customers", action: "create" }}
          />
        ),
      },
      {
        path: "/update-customer",
        element: (
          <ProtectedRoute
            element={<CreateCustomer />}
            requiredPermission={{ resource: "customers", action: "update" }}
          />
        ),
      },
      {
        path: "/assign-permission",
        element: (
          <ProtectedRoute
            element={<ModulePermissions />}
            requiredPermission={{ resource: "role", action: "update" }}
          />
        ),
      },

      {
        path: "/products",
        element: (
          <ProtectedRoute
            element={<Products />}
            requiredPermission={{ resource: "material", action: "read" }}
          />
        ),
      },
      {
        path: "/create-product",
        element: (
          <ProtectedRoute
            element={<CreateProduct />}
            requiredPermission={{ resource: "material", action: "create" }}
          />
        ),
      },
      {
        path: "/update-product",
        element: (
          <ProtectedRoute
            element={<CreateProduct />}
            requiredPermission={{ resource: "material", action: "update" }}
          />
        ),
      },
      {
        path: "/company-detail",
        element: (
          <ProtectedRoute
            element={<CompanyDetail />}
            requiredPermission={{ resource: "company", action: "read" }}
          />
        ),
      },
      {
        path: "/change-password",
        element: <ChangePassword />,
      },
    ],
  },
  {
    element: <PrivateRouteWithoutLayout />,
    children: [
      {
        path: "/quotation-invoice/:id",
        element: <Invoice />,
      },
    ],
  },
  {
    element: <RequestQuotationIframe />,
    path: "/quotation/request/:id",
  },
  {
    element: <RequestQuotationForm />,
    path: "/quotation/create/:id",
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
