import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import axios from "axios";
import { toast } from "sonner";
import { handleDeleteResponse, handleGetResponse } from "./commonService";
import { handleErrorMessage } from "../../components/helper/common-function";

//get all listing of the users
export const actionGetUsers = createAsyncThunk(
  "actionGetUsers",
  async ({ offset = "", search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `users?limit=10&offset=${offset}${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//add user
export const actionAddUser = createAsyncThunk(
  "actionAddUser",
  async ({ req, navigate, form }) => {
    try {
      const response = await axios.post(`${BASE_URL}/users`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            user_id: data?.user_id,
          });
        navigate && navigate("/users");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error);
      handleErrorMessage(error);
    }
  }
);

//delete user
export const actionDeleteUser = createAsyncThunk(
  "actionDeleteUser",
  async ({ userId, handleCloseUserDeleteModal }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${BASE_URL}/users/${userId}`);
      const { status, message: customMsg } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCloseUserDeleteModal && handleCloseUserDeleteModal(false);
        return userId;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get single user
export const actionGetSingleUser = createAsyncThunk(
  "actionGetSingleUser",
  (id) => {
    return handleGetResponse(`users/${id}`);
  }
);

//update user
export const actionEditUser = createAsyncThunk(
  "actionEditUser",
  async ({ id, req, navigate }) => {
    try {
      const response = await axios.put(`${BASE_URL}/users/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/users");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
