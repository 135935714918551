import {
  AutoComplete,
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Image,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import addButton from "../../assets/img/add-icon.svg";
import delteIcon from "../../assets/img/deleteMaster.svg";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionGetAllProducts } from "../../store/services/productService";
import {
  actionAddRequestQuotation,
  actionEditRequestQuotation,
} from "../../store/services/quotationService";
import PhoneInputComponent from "../common/PhoneInputComponent";

const { Option } = Select;

const RequestQuotationForm = ({ isModal, recordId, data, handleClose }) => {
  const { id } = useParams();
  const [form] = Form.useForm();

  const [value, setValue] = useState({
    fullPhoneNumber: "",
    iso: "in",
    phone: "",
    isValid: true,
    dialCode: "91",
  });

  const [customMaterials, setCustomMaterials] = useState({});

  const [submitted, setSubmitted] = useState(false);

  const { authData } = useSelector((state) => state.auth);
  const { getAllProductsLoader, allProducts } = useSelector(
    (state) => state.product
  );

  const { addRequestQuotationLoader, updateRequestQuotationLoader } =
    useSelector((state) => state.quotation);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionGetAllProducts());
  }, []);

  useEffect(() => {
    if (recordId && data) {
      form.setFieldsValue({
        client_name: data.client_name,
        client_email: data.client_email,
        require_before: data.require_before ? dayjs(data.require_before) : null,
        status: data.status,
        items:
          data.request_for_quotation_items &&
          data.request_for_quotation_items.length > 0 &&
          data.request_for_quotation_items.map((item) => ({
            product_name: item.product_name,
            product_description: item.product_description,
            quantity: item.quantity,
          })),
      });

      // Set the phone input value
      setValue({
        fullPhoneNumber: `+${data?.country_code}${data.client_phone}`,
        iso: data?.phone_country_code.toLowerCase(),
        phone: data.client_phone,
        isValid: true,
        dialCode: data.country_code,
      });
    }
  }, [recordId, data, form]);

  const materialOptions = useMemo(() => {
    return (
      (allProducts &&
        allProducts.length > 0 &&
        allProducts.map((prod) => ({
          label: prod?.material_name,
          value: prod?.material_name,
        }))) ||
      []
    );
  }, [allProducts]);

  const handlePhoneChange = (
    fullPhoneNumber,
    phone,
    iso,
    isValid,
    dialCode
  ) => {
    setValue({
      phone: phone,
      fullPhoneNumber: fullPhoneNumber,
      iso: iso,
      isValid: isValid,
      dialCode,
    });
  };

  const onFinish = (values) => {
    console.log("Form values:", values);
    setSubmitted(true);

    if (value.isValid) {
      const req = {
        ...values,
        company_id: isModal ? parseInt(authData?.company?.id) : parseInt(id), // add logic here for modal
        require_before: values.require_before
          ? dayjs(values.require_before).format("YYYY-MM-DD")
          : null,
        phone_country_code: value.iso ? value.iso.toUpperCase() : null,
        client_phone: value.phone || null,
        country_code: value.dialCode || null,
        request_for_quotation_items:
          values.items.map((item) => ({
            product_name: item.product_name,
            product_description: item.product_description,
            quantity: item.quantity ? parseInt(item.quantity) : 1,
          })) || [],
      };
      recordId
        ? dispatch(
            actionEditRequestQuotation({ id: recordId, req, form, handleClose })
          )
        : dispatch(actionAddRequestQuotation({ req, form, handleClose }));
    }
  };

  const onFinishFailed = (values) => {
    console.log(values);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const columns = (fields, remove) => [
    {
      title: <span className="text-[14px]">Material</span>,
      dataIndex: "product_name",
      align: "middle",
      render: (_, record, index) => {
        const items = form.getFieldValue("items") || [];
        const selectedMaterials = items
          .map((item) => item?.product_name)
          .filter(Boolean);
        const currentMaterial = items[index]?.product_name;

        // Filter out options that are already selected in other rows
        const availableOptions = materialOptions.filter(
          (option) =>
            !selectedMaterials.includes(option.value) ||
            option.value === currentMaterial
        );

        return (
          <Form.Item
            wrapperCol={24}
            className="!mb-0"
            rules={[{ required: true, message: "Please enter material" }]}
            name={[index, "product_name"]}
          >
            <AutoComplete
              className="table-select"
              style={{ width: "100%", minHeight: 35 }}
              options={availableOptions}
              placeholder="Select or enter Material"
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              onSelect={(value) => {
                const updatedItems = items.map((item, i) =>
                  i === index ? { ...item, product_name: value } : item
                );
                form.setFieldsValue({ items: updatedItems });
              }}
              onChange={(value) => {
                const updatedItems = items.map((item, i) =>
                  i === index ? { ...item, product_name: value } : item
                );
                form.setFieldsValue({ items: updatedItems });
              }}
              onSearch={(value) => {
                // If the value is not in materialOptions, allow it as a custom entry
                if (!materialOptions.some((option) => option.value === value)) {
                  const updatedItems = items.map((item, i) =>
                    i === index ? { ...item, product_name: value } : item
                  );
                  form.setFieldsValue({ items: updatedItems });
                }
              }}
            />
          </Form.Item>
        );
      },
    },
    {
      title: <span className="text-[14px]">Qty</span>,
      dataIndex: "quantity",
      // width: "43%",
      render: (_, record, index) => (
        <Form.Item
          wrapperCol={24}
          className="!mb-0"
          rules={[{ required: true, message: "Please enter quantity" }]}
          name={[index, "quantity"]}
        >
          <Input
            className="py-[8px]"
            style={{ width: "100%", fontWeight: 400 }}
            allowClear
            placeholder="Quantity"
          />
        </Form.Item>
      ),
    },
    {
      title: <span className="text-[14px]">Description</span>,
      dataIndex: "product_description",
      // width: "43%",
      render: (_, record, index) => (
        <Form.Item
          wrapperCol={24}
          className="!mb-0"
          name={[index, "product_description"]}
        >
          <Input
            className="py-[8px]"
            style={{ width: "100%", fontWeight: 400 }}
            allowClear
            placeholder="Description"
          />
        </Form.Item>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      align: "middle",
      render: (_, record, index) => (
        <div
          onClick={() => remove(record.name)}
          className={`flex justify-center items-center gap-2 h-full cursor-pointer ${
            fields.length === 1 ? "opacity-50 pointer-events-none" : ""
          }`}
        >
          <img src={delteIcon} alt="Delete" className="cursor-pointer" />
        </div>
      ),
    },
  ];

  return (
    <div>
      <Form
        {...formItemLayout}
        scrollToFirstError={true}
        onFinishFailed={onFinishFailed}
        layout="horizontal"
        labelAlign="left"
        form={form}
        onFinish={onFinish}
        initialValues={{
          clientName: "Vytech",
          requiredBefore: dayjs(),
          status: "Pending",
          productService: "webDevelopment",
          quantity: 15000,
          items: [{}],
        }}
      >
        <Form.Item
          label="Name"
          name="client_name"
          rules={[{ required: true, message: "Please enter name!" }]}
        >
          <Input className="py-[8px]" placeholder="Enter Name" />
        </Form.Item>

        <Form.Item
          label="Email"
          name="client_email"
          rules={[
            { required: true, message: "Please enter email!" },
            { type: "email", message: "Please enter a valid email address" },
          ]}
        >
          <Input className="py-[8px]" placeholder="Enter Email" />
        </Form.Item>

        <Row className="mb-2">
          <Col span={8}>
            <p className="font-monaSansSemiBold text-[17px] relative">
              Contact No<span className="text-red-500 ">*</span>
            </p>
          </Col>
          <Col span={16}>
            <PhoneInputComponent
              label=""
              required={true}
              value={value.fullPhoneNumber}
              onChange={handlePhoneChange}
              isValid={value.isValid}
              submitted={submitted}
            />
          </Col>
        </Row>

        <Form.Item
          label="Required Before"
          name="require_before"
          rules={[{ required: true, message: "Please select date!" }]}
        >
          <DatePicker
            className="py-[8px] w-full"
            minDate={dayjs()}
            format="YYYY-MM-DD"
          />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: "Please select status",
            },
          ]}
          name="status"
          label="Status"
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="Pending">Pending</Radio>
              <Radio value="Send_to_client">Sent To Client</Radio>
              <Radio value="Rejected">Rejected</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <ConfigProvider
          theme={{
            token: {
              fontWeightStrong: 400,
            },
            components: {
              Select: { controlHeight: 32, algorithm: true },

              Table: {
                cellPaddingBlock: 10,
              },
            },
          }}
        >
          <Form.List name="items">
            {(fields, { add, remove }) => (
              <>
                <Table
                  columns={columns(fields, remove)}
                  dataSource={fields}
                  pagination={false}
                  bordered
                  className="mt-4 sales-orders-table"
                  scroll={{ x: 400, y: 400 }}
                />

                <div
                  onClick={() => add()}
                  className={`inline-flex mt-5 cursor-pointer  items-center gap-x-2 ${
                    false || false ? "opacity-50 pointer-events-none" : ""
                  }`}
                >
                  <Image
                    preview={false}
                    src={addButton}
                    height={20}
                    width={20}
                    alt="add-button-white"
                  />
                  <span className="text-primary text-base">Add New</span>
                </div>
              </>
            )}
          </Form.List>
        </ConfigProvider>

        {/* Submit Button */}
        <Form.Item className="mt-6">
          <Space>
            <Button
              loading={
                addRequestQuotationLoader || updateRequestQuotationLoader
              }
              className="root-btn min-w-[120px] min-h-[40px] !rounded-sm"
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RequestQuotationForm;
