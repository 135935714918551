import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import axios from "axios";
import { toast } from "sonner";
import { handleDeleteResponse, handleGetResponse } from "./commonService";
import { handleErrorMessage } from "../../components/helper/common-function";

//get all listing of the Unit master
export const actionGetUnitMaster = createAsyncThunk(
  "actionGetUnitMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `unit-master?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//add Unit master
export const actionAddUnitMaster = createAsyncThunk(
  "actionAddUnitMaster",
  async ({ values, handleCancel, form }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/unit-master`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            unit_master_id: data?.unit_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete Unit master
export const actionDeleteUnitMaster = createAsyncThunk(
  "actionDeleteUnitMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`unit-master/${id}`, id, setEditingKey);
  }
);

//get single Unit master
export const actionGetSingleUnitMaster = createAsyncThunk(
  "actionGetSingleUnitMaster",
  (id) => {
    return handleGetResponse(`unit-master/${id}`);
  }
);

//update Unit master
export const actionEditUnitMaster = createAsyncThunk(
  "actionEditUnitMaster",
  async ({ id, req, setEditingKey, setEdited }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BASE_URL}/unit-master/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
