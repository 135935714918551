import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import axios from "axios";
import { toast } from "sonner";
import { handleDeleteResponse, handleGetResponse } from "./commonService";
import { handleErrorMessage } from "../../components/helper/common-function";

//get all listing of the Tax master
export const actionGetTaxMaster = createAsyncThunk(
  "actionGetTaxMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `tax-master?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//add Tax master
export const actionAddTaxMaster = createAsyncThunk(
  "actionAddTaxMaster",
  async ({ values, handleCancel, form }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/tax-master`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            address_type_master_id: data?.address_type_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete Tax master
export const actionDeleteTaxMaster = createAsyncThunk(
  "actionDeleteTaxMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`tax-master/${id}`, id, setEditingKey);
  }
);

//get single addres master
export const actionGetSingleUnitMaster = createAsyncThunk(
  "actionGetSingleUnitMaster",
  (id) => {
    return handleGetResponse(`tax-master/${id}`);
  }
);

//update Tax master
export const actionEditTaxMaster = createAsyncThunk(
  "actionEditTaxMaster",
  async ({ id, req, setEditingKey, setEdited }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BASE_URL}/tax-master/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);


//update Tax master
export const actionUpdateTaxStatusMaster = createAsyncThunk(
  "actionUpdateTaxStatusMaster",
  async ({ id, status: taxStatus }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/tax-master/update-status/${id}?status=${taxStatus}`
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
