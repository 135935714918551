import {
  Card,
  Form,
  Input,
  Image,
  Spin,
  Row,
  Col,
  Upload,
  Select,
  Button,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "sonner";
import companyIcons from "../../assets/img/companyIcons.svg";
import ImgCrop from "antd-img-crop";
import editPrimaryWhite from "../../assets/img/editPrimaryWhite.svg";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  actionGetCompanyDetail,
  actionUpdateCompanyDetail,
} from "../../store/services/companyDetailService";
import { actionGetAllCountry } from "../../store/services/countryMasterService";
import { actionGetAllStates } from "../../store/services/stateMasterService";
import { useDispatch, useSelector } from "react-redux";
import PhoneInputComponent from "../common/PhoneInputComponent";
import { BASE_URL_UPLOAD } from "../../config/web-config";
import { gridSettings } from "../helper/constants";
import { actionGetCurrencyMaster } from "../../store/services/currencyMasterService";
import AddPopover from "../common/AddPopover";

const CompanyDetail = () => {
  const [form] = Form.useForm();
  const [type, setType] = useState("VIEW");
  const [userProfilePicUrl, setUserProfilePicUrl] = useState({});

  const [value, setValue] = useState({
    fullPhoneNumber: "",
    iso: "in",
    phone: "",
    isValid: true,
    dialCode: "91",
  });
  const [submitted, setSubmitted] = useState(false);
  const [isCurrencyPopoverOpen, setIsCurrencyPopoverOpen] = useState(false);

  const dispatch = useDispatch();

  const { getCompanyDetailLoader, companyDetail, updateCompanyDetailLoader } =
    useSelector((state) => state.companyDetail);
  const { allCountryMasterData } = useSelector((state) => state.countryMaster);
  const { getCurrencyMasterLoader, currencyMasterData } = useSelector(
    (state) => state.currencyMaster
  );
  const { getAllStatesLoader, allStatesData } = useSelector(
    (state) => state.stateMaster
  );

  const { addCurrencyMasterLoader } = useSelector(
    (state) => state.currencyMaster
  );

  const [loading, setLoading] = useState(false);
  const { authData } = useSelector((state) => state.auth);
  const { company } = authData || {};

  const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\/\w\-.?&=%]*)?$/i;

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleChangeUserProfilePic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setUserProfilePicUrl(info.file.response.data);
        toast.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  useEffect(() => {
    if (company) {
      dispatch(actionGetCompanyDetail(company.id));
    }
  }, [dispatch, company]);

  const { myCurrency } = useSelector((state) => state.auth);

  console.log(myCurrency);

  useEffect(() => {
    if (companyDetail && companyDetail.logo) {
      setUserProfilePicUrl(companyDetail.logo);
    }
  }, [companyDetail]);

  const handleEditClick = async () => {
    await Promise.all([dispatch(actionGetAllCountry())]);
    await dispatch(actionGetCurrencyMaster());

    if (companyDetail?.country?.id) {
      await dispatch(actionGetAllStates(companyDetail.country.id));
    }

    if (companyDetail) {
      await form.setFieldsValue({
        company_name: companyDetail.company_name
          ? companyDetail.company_name
          : "",
        phone: companyDetail.phone ? companyDetail.phone : null,
        email: companyDetail.email ? companyDetail.email : "",
        address: companyDetail.address ? companyDetail.address : "",
        city_district: companyDetail.city_district
          ? companyDetail.city_district
          : "",
        states_id: companyDetail.states?.id ? companyDetail.states.id : null,
        currency_id: companyDetail.currency
          ? companyDetail.currency.currency_master_id
          : null,
        country_id: companyDetail.country ? companyDetail.country.id : null,
        pin_code: companyDetail.pin_code ? companyDetail.pin_code : "",
        website: companyDetail.website ? companyDetail.website : "",
        terms_and_conditions: companyDetail.terms_and_conditions
          ? companyDetail.terms_and_conditions
          : "",
        bank_name: companyDetail.bank_name ? companyDetail.bank_name : "",
        bank_beneficiary: companyDetail.bank_beneficiary
          ? companyDetail.bank_beneficiary
          : "",
        bank_swift_code: companyDetail.bank_swift_code
          ? companyDetail.bank_swift_code
          : "",
        bank_account_number: companyDetail.bank_account_number
          ? companyDetail.bank_account_number
          : "",
        primary_bank_name: companyDetail.primary_bank_name
          ? companyDetail.primary_bank_name
          : "",
        primary_bank_beneficiary: companyDetail.primary_bank_beneficiary
          ? companyDetail.primary_bank_beneficiary
          : "",
        primary_bank_swift_code: companyDetail.primary_bank_swift_code
          ? companyDetail.primary_bank_swift_code
          : "",
        secondary_bank_name: companyDetail.secondary_bank_name
          ? companyDetail.secondary_bank_name
          : "",

        secondary_bank_beneficiary: companyDetail.secondary_bank_beneficiary
          ? companyDetail.secondary_bank_beneficiary
          : "",
        secondary_bank_swift_code: companyDetail.secondary_bank_swift_code
          ? companyDetail.secondary_bank_swift_code
          : "",
        secondary_bank_account_number:
          companyDetail.secondary_bank_account_number
            ? companyDetail.secondary_bank_account_number
            : "",
      });
      console.log(companyDetail, "company secondary");

      const fullPhoneNumber = `+${companyDetail.country_code}${companyDetail.phone}`;

      setValue({
        ...value,
        fullPhoneNumber,
        iso: companyDetail?.phone_country_code?.toLowerCase(),
        phone: companyDetail.phone,
        isValid: true,
        dialCode: companyDetail.country_code,
      });

      setUserProfilePicUrl(companyDetail.logo);
    }
    setType("EDIT");
  };

  const handlePhoneChange = (
    fullPhoneNumber,
    phone,
    iso,
    isValid,
    dialCode
  ) => {
    setValue({
      phone: phone,
      fullPhoneNumber: fullPhoneNumber,
      iso: iso,
      isValid: isValid,
      dialCode,
    });
  };

  const onFinish = (values) => {
    setSubmitted(true);

    if (value.isValid) {
      const data = {
        ...values,
        phone: value.phone ? value.phone : null,
        phone_country_code: value.iso ? value.iso.toUpperCase() : null,
        country_code: value.dialCode ? value.dialCode : null,
        logo_id: userProfilePicUrl ? userProfilePicUrl.upload_id : null,
        country_id: values.country_id ? Number(values.country_id) : null,
        states_id: values.states_id ? Number(values.states_id) : null,
        currency_id: values.currency_id ? Number(values.currency_id) : null,
      };

      dispatch(
        actionUpdateCompanyDetail({
          req: data,
          setType,
        })
      );
    }
  };

  const currencyOptions = useMemo(() => {
    return (
      (currencyMasterData &&
        currencyMasterData.length > 0 &&
        currencyMasterData.map((currency) => ({
          label: currency.currency?.currency,
          value: currency.currency_master_id,
        }))) ||
      []
    );
  }, [currencyMasterData]);

  const countryOptions = useMemo(() => {
    return (
      allCountryMasterData &&
      allCountryMasterData.length > 0 &&
      allCountryMasterData.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
  }, [allCountryMasterData]);

  const stateOptions = useMemo(() => {
    return (
      allStatesData &&
      allStatesData.length > 0 &&
      allStatesData.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
  }, [allStatesData]);

  const FormField = ({
    label,
    name,
    rules,
    value,
    normalize,
    component,
    loading,
    type,
    options = [],
    ...props
  }) => {
    const formattedValue = value || "-";

    return (
      <div className="mb-4 custom-antd-input">
        <Form.Item
          label={label}
          name={name}
          rules={rules}
          initialValue={value}
          normalize={normalize}
        >
          {type === "VIEW" ? (
            <p className="font-popinsMedium text-md text-black">
              {formattedValue}
            </p>
          ) : loading ? (
            <Spin spinning size="small" />
          ) : component === Select ? (
            <Select {...props} value={value}>
              {options.length > 0 &&
                options.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
            </Select>
          ) : (
            <Input {...props} value={value} />
          )}
        </Form.Item>
      </div>
    );
  };

  // const showAddCurrencyForm = () => {
  //   return (
  //     <Form
  //       requiredMark={type === "EDIT" ? true : false}
  //       form={form}
  //       onFinish={() => {}}
  //       layout="vertical"
  //       className="mt-[20px] mb-0"
  //     >
  //       <Form.Item
  //         name="currency_id"
  //         label="Currency Name"
  //         className="mb-[12px]"
  //         size="small"
  //         rules={[
  //           {
  //             required: true,
  //             message: "Please input currency name!",
  //           },
  //         ]}
  //       >
  //         <Select
  //           options={currencyOptions}
  //           allowClear
  //           showSearch
  //           optionFilterProp="children"
  //           filterOption={(input, option) =>
  //             (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  //           }
  //           style={{ background: "white" }}
  //           placeholder="Select Currency"
  //           className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
  //         />
  //       </Form.Item>
  //       <Form.Item
  //         name="exchange_rate"
  //         label="Exchange Rate"
  //         className="mb-[12px]"
  //         size="small"
  //         rules={[
  //           {
  //             required: false,
  //             message: "Please input exchange rate!",
  //           },
  //         ]}
  //       >
  //         <Input
  //           style={{ background: "white" }}
  //           placeholder="Enter exchange rate"
  //           className=" bg-white focus:bg-white active:bg-white rounded-[10px]"
  //         />
  //       </Form.Item>

  //       <div className="flex justify-center mt-[40px]">
  //         <Form.Item className="mb-0">
  //           <Button
  //             onClick={closeCurrencyPopover}
  //             type="button"
  //             className="cancel-btn  min-h-[40px] min-w-[100px]"
  //           >
  //             Cancel
  //           </Button>
  //         </Form.Item>

  //         <div className="ml-4">
  //           <Form.Item className="mb-0">
  //             <Button
  //               type="primary"
  //               loading={addCurrencyMasterLoader}
  //               className="root-btn min-h-[40px] min-w-[100px]"
  //               htmlType="submit"
  //             >
  //               Save
  //             </Button>
  //           </Form.Item>
  //         </div>
  //       </div>
  //     </Form>
  //   );
  // };

  return (
    <>
      <Spin spinning={getCompanyDetailLoader}>
        <Form
          // requiredMark="optional"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          className="space-y-3"
          name="basic"
          scrollToFirstError={true}
          autoComplete="off"
        >
          <Card>
            <div className="flex justify-between mb-4 custom-antd-input">
              <div className="flex items-center">
                <Image src={companyIcons}></Image>
                <h3 className=" text-lg ml-3 text-[#2B2526] font-monaSansMedium">
                  Company Details
                </h3>
              </div>

              {type === "VIEW" ? (
                <p
                  onClick={handleEditClick}
                  className="font-popinsRegular bg-transparent text-brand border border-black py-1.5 px-4 rounded-lg cursor-pointer"
                >
                  Edit
                </p>
              ) : (
                <p
                  onClick={async () => {
                    if (!updateCompanyDetailLoader) {
                      await form.submit();
                    }
                  }}
                  className="font-popinsRegular bg-transparent text-brand border border-black py-1.5 px-4 rounded-lg cursor-pointer"
                >
                  {updateCompanyDetailLoader ? (
                    <Spin spinning={updateCompanyDetailLoader} size="small" />
                  ) : (
                    "Save"
                  )}
                </p>
              )}
            </div>

            <Row gutter={24}>
              <Col span={24} align="middle">
                <Form.Item
                  className="mb-[12px]"
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please enter profile pic!",
                    },
                  ]}
                >
                  {userProfilePicUrl ? (
                    <div className="relative border-dashedcountry_code bg-gray-100 flex items-center justify-center border-secondary  h-[102px]  w-[102px] rounded-[6px] mb-2">
                      <Image
                        preview={false}
                        src={userProfilePicUrl.url}
                        alt="avatar"
                        className="h-full w-full rounded-[6px]"
                      />

                      <ImgCrop rotationSlider aspect={1 / 1} fillColor="white">
                        <Upload
                          id=""
                          accept=".png,.jpg,.jpeg"
                          name="file"
                          listType="picture-card"
                          className="border-0 absolute main-upload bottom-0 right-0 avatar-uploader"
                          showUploadList={false}
                          // action={BASE_URL_UPLOAD}
                          action={BASE_URL_UPLOAD}
                          openFileDialogOnClick={true}
                          headers={{
                            Authorization:
                              "Bearer " + localStorage.getItem("qmsToken"),
                          }}
                          onChange={handleChangeUserProfilePic}
                        >
                          {type === "EDIT" && (
                            <div className=" px-1.5 py-1 border-0 absolute right-0 bottom-0 bg-red-500 rounded-tl-[6px] rounded-br-[6px] cursor-pointer flex">
                              <Image
                                width={15}
                                height={15}
                                src={editPrimaryWhite}
                                className="rounded-lg"
                                preview={false}
                              />
                            </div>
                          )}
                        </Upload>
                      </ImgCrop>
                    </div>
                  ) : (
                    <ImgCrop aspect={1 / 1} rotationSlider fillColor="white">
                      <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader mb-2"
                        showUploadList={false}
                        action={BASE_URL_UPLOAD}
                        openFileDialogOnClick={true}
                        headers={{
                          Authorization:
                            "Bearer " + localStorage.getItem("qmsToken"),
                        }}
                        onChange={handleChangeUserProfilePic}
                      >
                        <button
                          style={{
                            border: 0,
                            background: "none",
                          }}
                          type="button"
                        >
                          {loading ? (
                            <LoadingOutlined />
                          ) : (
                            <PlusOutlined style={{ color: "#2B2526" }} />
                          )}
                          <div
                            style={{
                              marginTop: 8,
                            }}
                          >
                            Image
                          </div>
                        </button>
                      </Upload>
                    </ImgCrop>
                  )}
                </Form.Item>
              </Col>

              <Col {...gridSettings}>
                {" "}
                <FormField
                  placeholder="Enter Company Name"
                  label="Company Name"
                  name="company_name"
                  rules={[
                    { required: true, message: "Please input company name!" },
                  ]}
                  component={Input}
                  value={companyDetail?.company_name}
                  type={type}
                />
              </Col>
              <Col {...gridSettings}>
                {type === "VIEW" ? (
                  <>
                    <div className="block text-[#000000] text-[17px] font-monaSansMedium mb-2">
                      Mobile No
                    </div>
                    <p className="font-popinsMedium text-md text-black">
                      {companyDetail &&
                      companyDetail.country_code &&
                      companyDetail.phone
                        ? `+${companyDetail.country_code} ${companyDetail.phone}`
                        : "-"}
                    </p>
                  </>
                ) : (
                  <PhoneInputComponent
                    required={true}
                    value={value.fullPhoneNumber}
                    onChange={handlePhoneChange}
                    isValid={value.isValid}
                    label="Mobile No"
                    submitted={submitted}
                  />
                )}
              </Col>
              <Col {...gridSettings}>
                <FormField
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input email!",
                    },
                    {
                      type: "email",
                      message: "Please input valid email!",
                    },
                  ]}
                  value={companyDetail?.email}
                  component={Input}
                  type={type}
                  placeholder="Enter Email"
                />
              </Col>
              <Col {...gridSettings}>
                <FormField
                  label="Address"
                  name="address"
                  placeholder="Enter Address"
                  rules={[
                    { required: true, message: "Please Enter Your address!" },
                  ]}
                  value={companyDetail?.address}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>

              <Col {...gridSettings}>
                <FormField
                  label="Country"
                  name="country_id"
                  placeholder="Select Country"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your country!",
                    },
                  ]}
                  component={Select}
                  options={countryOptions}
                  value={companyDetail?.country?.name}
                  allowClear
                  onSelect={(val) => {
                    form.setFieldsValue({ states_id: null }); // Clear the state field
                    dispatch(actionGetAllStates(val)); // Fetch new states based on country selection
                  }}
                  showSearch
                  optionFilterProp="children"
                  {...gridSettings}
                  type={type}
                />
              </Col>

              <Col {...gridSettings}>
                <FormField
                  placeholder="Enter State / Province"
                  label="State / Province"
                  name="states_id"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your State / Province!",
                    },
                  ]}
                  value={companyDetail?.states?.name}
                  component={Select}
                  options={stateOptions}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  // loading={getAllStatesLoader}
                  type={type}
                />
              </Col>

              <Col {...gridSettings}>
                <FormField
                  label="City / District"
                  name="city_district"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your City / District!",
                    },
                  ]}
                  placeholder="Enter City"
                  value={companyDetail?.city_district}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>

              <Col {...gridSettings}>
                <FormField
                  placeholder="Enter Postal Code"
                  label="Postal Code"
                  name="pin_code"
                  rules={[
                    { required: false, message: "Please input postal code!" },
                  ]}
                  component={Input}
                  normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                  value={companyDetail?.pin_code}
                  type={type}
                />
              </Col>
              <Col {...gridSettings}>
                <FormField
                  placeholder="Enter Website"
                  label="Website"
                  name="website"
                  rules={[
                    { required: false, message: "Please Enter Your Website!" },
                    () => ({
                      validator(_, value) {
                        if (!value || urlRegex.test(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Please enter a valid URL");
                      },
                    }),
                  ]}
                  component={Input}
                  value={companyDetail?.website}
                  type={type}
                />
              </Col>

              <Col {...gridSettings}>
                {/*    {type === "EDIT" && (
                  <AddPopover
                    content={showAddCurrencyForm}
                    isOpen={isCurrencyPopoverOpen}
                    onOpenChange={(visible) => {
                      if (!visible) {
                        closeCurrencyPopover();
                      }
                    }}
                    text="Add Currency"
                    showModal={() => setIsCurrencyPopoverOpen(true)}
                  />
                )} */}

                <FormField
                  label="Currency"
                  name="currency_id"
                  placeholder="Enter Currency"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your currency!",
                    },
                  ]}
                  component={Select}
                  options={currencyOptions}
                  value={companyDetail?.currency?.currency?.currency}
                  {...gridSettings}
                  showSearch
                  optionFilterProp="children"
                  type={type}
                />
              </Col>
              <Col {...gridSettings}>
                <FormField
                  label="Terms & Conditions"
                  name="terms_and_conditions"
                  placeholder="Enter Terms & Conditions"
                  rules={[
                    {
                      required: false,
                      message: "Please input terms conditions!",
                    },
                  ]}
                  component={Input}
                  className="font-monaSansMedium"
                  value={companyDetail?.terms_and_conditions}
                  type={type}
                />
              </Col>
            </Row>
          </Card>
          {/* <Card className="mt-2">
            <div className="flex items-center mb-4">
              <Image preview={false} src={companyIcons} alt="company-icon" />
              <h3 className="font-monaSansMedium text-lg ml-3 text-[#2B2526] ">
                Bank Details
              </h3>
            </div>

            <Row gutter={24}>
              <Col {...gridSettings}>
                <FormField
                  label="Bank Name"
                  name="bank_name"
                  rules={[
                    { required: true, message: "Please Enter Your bank name!" },
                  ]}
                  value={companyDetail?.bank_name}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>
              <Col {...gridSettings}>
                <FormField
                  label="Beneficiary"
                  name="bank_beneficiary"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your beneficiary!",
                    },
                  ]}
                  value={companyDetail?.bank_beneficiary}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>
              <Col {...gridSettings}>
                <FormField
                  label="Swift Code"
                  name="bank_swift_code"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your swift code!",
                    },
                  ]}
                  value={companyDetail?.bank_swift_code}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>
              <Col {...gridSettings}>
                <FormField
                  label="A/C No"
                  name="bank_account_number"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your account number!",
                    },
                  ]}
                  value={companyDetail?.bank_account_number}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>
            </Row>
          </Card>
          <Card>
            <div className="flex items-center mb-4">
              <Image preview={false} src={companyIcons} alt="company-icon" />
              <h3 className="text-lg ml-3 text-[#2B2526] font-monaSansMedium">
                Secondary Bank Details
              </h3>
            </div>
            <Row gutter={24}>
              <Col {...gridSettings}>
                <FormField
                  label="Secondary Bank Name"
                  name="secondary_bank_name"
                  placeholder="Enter Secondary Account Name"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Your Secondary bank name!",
                    },
                  ]}
                  value={companyDetail?.secondary_bank_name}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>
              <Col {...gridSettings}>
                <FormField
                  label="Secondary Beneficiary"
                  name="secondary_bank_beneficiary"
                  placeholder="Enter Secondary Beneficiary"
                  rules={[
                    {
                      required: false,
                      message: "Please enter your secondary beneficiary!",
                    },
                  ]}
                  value={companyDetail?.secondary_bank_beneficiary}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>
              <Col {...gridSettings}>
                <FormField
                  label="Secondary Swift Code"
                  name="secondary_bank_swift_code"
                  placeholder="Enter Secondary Swift Code"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Your swift code!",
                    },
                  ]}
                  value={companyDetail?.secondary_bank_swift_code}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>
              <Col {...gridSettings}>
                <FormField
                  label="Secondary A/C No"
                  name="secondary_bank_account_number"
                  placeholder="Enter Secondary Bank Account Number"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Your account number!",
                    },
                  ]}
                  value={companyDetail?.secondary_bank_account_number}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>
            </Row>
          </Card> */}
          {/* <Row gutter={24}>
              <Col {...gridSettings}>
                <FormField
                  placeholder="Enter Primary Bank Name"
                  label="Primary Bank Name"
                  name="primary_bank_name"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your primary bank name!",
                    },
                  ]}
                  value={companyDetail?.primary_bank_name}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>
              <Col {...gridSettings}>
                <FormField
                  placeholder="Enter Primary Beneficiary"
                  label="Primary Beneficiary"
                  name="primary_bank_beneficiary"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your primary beneficiary!",
                    },
                  ]}
                  value={companyDetail?.primary_bank_beneficiary}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>
              <Col {...gridSettings}>
                <FormField
                  placeholder="Enter Primary Swift Code"
                  label="Primary Swift Code"
                  name="primary_bank_swift_code"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your primary swift code!",
                    },
                  ]}
                  value={companyDetail?.primary_bank_swift_code}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>
              <Col {...gridSettings}>
                <FormField
                  placeholder="Enter Primary Account Number"
                  label="Primary A/C No"
                  name="primary_bank_account_number"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Your primary account number!",
                    },
                  ]}
                  value={companyDetail?.primary_bank_account_number}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>
              <Col {...gridSettings}>
                <FormField
                  placeholder="Enter Secondary Bank Name"
                  label="Secondary Bank Name"
                  name="secondary_bank_name"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Your secondary bank name!",
                    },
                  ]}
                  value={companyDetail?.secondary_bank_name}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>
              <Col {...gridSettings}>
                <FormField
                  placeholder="Enter Secondary Beneficiary"
                  label="Secondary Beneficiary"
                  name="secondary_bank_beneficiary"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Your secondary beneficiary!",
                    },
                  ]}
                  value={companyDetail?.secondary_bank_beneficiary}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>
              <Col {...gridSettings}>
                <FormField
                  placeholder="Enter Secondary Swift Code"
                  label="Secondary Swift Code"
                  name="secondary_bank_swift_code"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Your secondary swift code!",
                    },
                  ]}
                  value={companyDetail?.secondary_bank_swift_code}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>
              <Col {...gridSettings}>
                <FormField
                  placeholder="Enter Secondary Account Number"
                  label="Secondary A/C No"
                  name="secondary_bank_account_number"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Your secondary account number!",
                    },
                  ]}
                  value={companyDetail?.secondary_bank_account_number}
                  component={Input}
                  {...gridSettings}
                  type={type}
                />
              </Col>
            </Row> */}
        </Form>
      </Spin>
    </>
  );
};

export default CompanyDetail;
