import React, { useMemo } from "react";
import { RouterProvider } from "react-router-dom";
import { ConfigProvider, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "react-international-phone/style.css";
import { Toaster } from "sonner";
import { router } from "./config/router";
import { authMe } from "./store/services/authService";
import setAuthToken from "./config/setAuthToken";

const App = () => {
  const token = localStorage.getItem("qmsToken");
  const dispatch = useDispatch();
  const { authMeLoader } = useSelector((state) => state.auth);

  useMemo(() => {
    if (token) {
      setAuthToken(token);
      dispatch(authMe());
    }
  }, [token, dispatch]);

  return (
    <>
      <Toaster
        visibleToasts={3}
        toastOptions={{
          classNames: {
            error: "my-toast-error",
            success: "my-toast-success",
            warning: "my-toast-warning",
            info: "my-toast-info",
            closeButton:
              "absolute -translate-x-1/2 -translate-y-1/2 left-[94%] right-0 top-1/2 cancel-button-style",
          },
        }}
        position="bottom-left"
        reverseOrder={false}
        expand={true}
        duration={4000}
        richColors={true}
        closeButton={true}
      />
      <ConfigProvider
        theme={{
          components: {
            Table: {
              cellFontSize: "15px",
              headerBg: "#FAF7F4",
              rowHoverBg: "#FAF7F4",
              headerColor: "#2B2526",
              headerBorderRadius: 0,
              headerSortHoverBg: "#FAF7F4",
              fontFamily: "Mona-Sans-Regular",
            },
            Form: {
              labelFontSize: "17px",
              labelColor: "#000",
            },
            Checkbox: {
              colorPrimary: "#2B2526",
              colorPrimaryHover: "#2B2526",
              colorPrimaryBorder: "#2B2526",
              fontFamily: "Mona-Sans-Regular",
            },
            Input: {
              colorBgContainer: "#FFFCFB",
              activeBg: "#FFFCFB",
              colorBorder: "#BCB4B2",
              activeBorderColor: "#484242",
              hoverBorderColor: "#BCB4B2",
              activeShadow: 0,
              inputFontSize: "15px",
              borderRadius: "10px",
              colorTextPlaceholder: "#7C7C7C",
            },
            InputNumber: {
              colorBgContainer: "#FFFCFB",
              activeBg: "#FFFCFB",
              colorBorder: "#BCB4B2",
              activeBorderColor: "#484242",
              hoverBorderColor: "#BCB4B2",
              activeShadow: 0,
              inputFontSize: "15px",
              borderRadius: "10px",
              colorTextPlaceholder: "#7C7C7C",
              fontFamily: "Mona-Sans-Regular",
            },
            DatePicker: {
              colorBgContainer: "#FFFCFB",
              colorBorder: "#BCB4B2",
              activeBorderColor: "#484242",
              fontSize: 15,
              hoverBorderColor: "#BCB4B2",
              activeShadow: 0,
              fontFamily: "Mona-Sans-Regular",
              borderRadius: "10px",
              colorTextPlaceholder: "#7C7C7C",
            },
            Card: {
              colorBorderSecondary: "#BCB4B2",
            },
            Pagination: {
              colorPrimary: "#2B2526",
              colorPrimaryHover: "#2B2526",
              colorText: "#2B2526",
              fontFamily: "Mona-Sans-Regular",
            },
            Select: {
              // colorPrimary: "#2B2526",
              colorPrimaryHover: "#BCB4B2",
              activeShadow: 0,
              colorBorder: "#BCB4B2",
              colorBgContainer: "#FFFCFB",
              colorBgBase: "#FFFCFB",
              controlOutline: 0,
              colorTextPlaceholder: "#7C7C7C",
              borderRadius: "10px",
              fontFamily: "Mona-Sans-Regular",
            },
            Dropdown: {
              fontFamily: "Mona-Sans-Regular",
            },
            Upload: {
              colorPrimary: "#484242",
              colorBorder: "#BCB4B2",
              colorFillAlter: "transparent",
              fontFamily: "Mona-Sans-Medium",
            },
            Switch: {
              colorPrimary: "#2B2526",
              colorPrimaryHover: "#2B2526",
            },
            Spin: {
              colorPrimary: "#2B2526",
            },
            Badge: {
              fontFamily: "Mona-Sans-Regular",
              colorBorderBg: "#2B2526",
              colorError: "#2B2526",
            },
            Collapse: {
              colorBorder: "#BCB4B2",
              headerBg:"white",
              fontFamily: "Mona-Sans-Regular",

            },
          },
        }}
      >
        {authMeLoader ? (
          <div className="flex items-center h-screen justify-center">
            <Spin />
          </div>
        ) : (
          <RouterProvider router={router} />
        )}
      </ConfigProvider>
    </>
  );
};

export default App;
