import { createSlice } from "@reduxjs/toolkit";
import {
  actionGetCountryTypeMaster,
  actionAddCountryTypeMaster,
  actionDeleteCountryTypeMaster,
  actionEditCountryTypeMaster,
  actionGetSingleCountryTypeMaster,
  actionGetAllCountry,
} from "../services/countryMasterService";

const countryMasterSlice = createSlice({
  name: "countryMaster",
  initialState: {
    getCountryMasterLoader: false,
    allCountriesCount: 0,
    addCountryMasterLoader: false,
    deleteCountryMasterLoader: {},
    updateCountryMasterLoader: false,
    getSingleCountryMasterData: {},
    getSingleCountryMasterLoader: {},
    countryMasterData: [],
    allCountryMasterData: [],
    getAllCountryMasterLoader: false,
    allCountriesIndexLoader: false,
    allCountriesIndexData: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //get all address type master
      .addCase(actionGetCountryTypeMaster.pending, (state) => {
        state.getCountryMasterLoader = true;
      })
      .addCase(actionGetCountryTypeMaster.fulfilled, (state, action) => {
        state.getCountryMasterLoader = false;
        state.countryMasterData = action.payload.data;
        state.allCountriesCount = action.payload.total_records;
      })
      .addCase(actionGetCountryTypeMaster.rejected, (state) => {
        state.getCountryMasterLoader = false;
      })

      //add address type
      .addCase(actionAddCountryTypeMaster.pending, (state) => {
        state.addCountryMasterLoader = true;
      })
      .addCase(actionAddCountryTypeMaster.fulfilled, (state, action) => {
        state.addCountryMasterLoader = false;
        if (action.payload) {
          if (
            !state.countryMasterData ||
            !Array.isArray(state.countryMasterData)
          ) {
            state.countryMasterData = [action.payload];
          } else {
            state.countryMasterData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddCountryTypeMaster.rejected, (state) => {
        state.addCountryMasterLoader = false;
      })

      //delete address master
      .addCase(actionDeleteCountryTypeMaster.pending, (state, action) => {
        state.deleteCountryMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteCountryTypeMaster.fulfilled, (state, action) => {
        state.countryMasterData = state.countryMasterData.filter(
          (type) => type.id !== action.payload
        );
        state.deleteCountryMasterLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteCountryTypeMaster.rejected, (state, action) => {
        state.deleteCountryMasterLoader[action.meta.arg.id] = false;
      })

      //get single address type
      .addCase(actionGetSingleCountryTypeMaster.pending, (state) => {
        state.getSingleCountryMasterLoader = true;
      })
      .addCase(actionGetSingleCountryTypeMaster.fulfilled, (state, action) => {
        state.getSingleCountryMasterData = action.payload.data;
        state.getSingleCountryMasterLoader = false;
      })
      .addCase(actionGetSingleCountryTypeMaster.rejected, (state) => {
        state.getSingleCountryMasterLoader = false;
      })
      .addCase(actionEditCountryTypeMaster.pending, (state) => {
        state.updateCountryMasterLoader = true;
      })
      .addCase(actionEditCountryTypeMaster.fulfilled, (state, action) => {
        state.updateCountryMasterLoader = false;
        state.countryMasterData = state.countryMasterData.map((data) =>
          data.id === action.payload.id ? { ...data, ...action.payload } : data
        );
      })

      .addCase(actionEditCountryTypeMaster.rejected, (state) => {
        state.updateCountryMasterLoader = false;
      })

      //get all country
      .addCase(actionGetAllCountry.pending, (state) => {
        state.getAllCountryMasterLoader = true;
      })
      .addCase(actionGetAllCountry.fulfilled, (state, action) => {
        state.allCountryMasterData = action.payload.data;
        state.getAllCountryMasterLoader = false;
      })
      .addCase(actionGetAllCountry.rejected, (state) => {
        state.getAllCountryMasterLoader = false;
      });
  },
});
export default countryMasterSlice.reducer;
