import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import axios from "axios";
import { toast } from "sonner";
import { handleGetResponse } from "./commonService";
import { handleErrorMessage } from "../../components/helper/common-function";
import { updateMyCurrency } from "../slices/authSlice";

export const actionGetCompanyDetail = createAsyncThunk(
  "actionGetCompanyDetail",
  (id) => {
    return handleGetResponse(`company/${id}`);
  }
);

export const actionUpdateCompanyDetail = createAsyncThunk(
  "actionUpdateCompanyDetail",
  async ({ req, setType }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.put(`${BASE_URL}/company/update-own`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        dispatch(updateMyCurrency(data));
        setType && setType("VIEW");
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
