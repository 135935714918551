import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "sonner";
import { handleErrorMessage } from "../../components/helper/common-function";
import { BASE_URL } from "../../config/web-config";
import { handleGetResponse } from "./commonService";

//get all listing of the users
export const actionGetQuotations = createAsyncThunk(
  "actionGetQuotations",
  async ({
    offset = "",
    search = "",
    orderByColumn = "",
    order = "",
    status = "",
  } = {}) => {
    return handleGetResponse(
      `quotation?limit=10&offset=${offset}${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}${
        status ? `&quotation_status=${status}` : ""
      }`
    );
  }
);

//add quotation
export const actionAddQuotation = createAsyncThunk(
  "actionAddQuotation",
  async ({ req, navigate }, { dispatch }) => {
    try {
      const response = await axios.post(`${BASE_URL}/quotation`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/quotations");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error);
      handleErrorMessage(error);
    }
  }
);

//delete user
export const actionDeleteQuotation = createAsyncThunk(
  "actionDeleteQuotation",
  async (
    { quotationId, handleCloseQuotationDeleteModal },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/quotation/${quotationId}`
      );
      const { status, message: customMsg } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCloseQuotationDeleteModal &&
          handleCloseQuotationDeleteModal(false);
        return quotationId;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get single user
export const actionGetSingleQuotation = createAsyncThunk(
  "actionGetSingleQuotation",
  (id) => {
    return handleGetResponse(`quotation/${id}`);
  }
);

//update user
export const actionEditQuotation = createAsyncThunk(
  "actionEditQuotation",
  async ({ id, req, navigate }, { dispatch }) => {
    try {
      const response = await axios.put(`${BASE_URL}/quotation/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/quotations");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get Quotation invoice data
// export const actionGetQuotationInvoiceData = createAsyncThunk(
//   "actionGetQuotationInvoiceData",
//   (id) => {
//     return handleGetResponse(`quotation/invoice/${id}`);
//   }
// );
export const actionGetQuotationInvoiceData = createAsyncThunk(
  "actionGetQuotationInvoiceData",
  async (id) => {
    try {
      const res = await axios.get(`${BASE_URL}/quotation/invoice/${id}`);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        const { url } = data;
        window.open(url, "_blank");
        return res.data;
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionGetQuotationDetails = createAsyncThunk(
  "actionGetQuotationDetails",
  (id) => {
    return handleGetResponse(`quotation/${id}`);
  }
);

export const actionQuotationSendEmail = createAsyncThunk(
  "actionQuotationSendEmail",
  async (id) => {
    try {
      const res = await axios.post(`${BASE_URL}/quotation/send-email/${id}`);
      const { status, message: customMsg } = res.data;
      console.log(res.data, "res.data");
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        // setDropdownVisible && setDropdownVisible(false);

        return res.data;
      } else {
        toast.error(customMsg, 5);
        // setDropdownVisible && setDropdownVisible(false);
      }
    } catch (error) {
      handleErrorMessage(error);
      // setDropdownVisible && setDropdownVisible(false);
    }
  }
);

export const actionDownloadQuotation = createAsyncThunk(
  "actionDownloadQuotation",
  async (id) => {
    try {
      const res = await axios.get(`${BASE_URL}/quotation/invoice/${id}`);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        const { url } = data;

        const blob = new Blob([url], { type: "application/pdf" });

        // Create a URL for the Blob
        const blobUrl = window.URL.createObjectURL(blob);

        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `quotation_${id}.pdf`;

        // Trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(blobUrl);

        toast.success("Quotation downloaded successfully", 5);
        return url;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//req for quotation
export const actionGetRequestForQuotation = createAsyncThunk(
  "actionGetRequestForQuotation",
  async ({ offset, search, orderByColumn, order, companyId }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/request-for-quotation/${companyId}?limit=10&offset=${offset}${
          search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
          order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionGetAllRequestForQuotation = createAsyncThunk(
  "actionGetAllRequestForQuotation",
  () => {
    return handleGetResponse(`request-for-quotation?getall=YES`);
  }
);

export const actionAddRequestQuotation = createAsyncThunk(
  "actionAddRequestQuotation",
  async ({ req, handleClose, form }, { dispatch }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/request-for-quotation`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form && form.resetFields();
        handleClose && handleClose();
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      // toast.error(error);
      handleErrorMessage(error);
    }
  }
);

export const actionEditRequestQuotation = createAsyncThunk(
  "actionEditRequestQuotation",
  async ({ id, req, handleClose }, { dispatch }) => {
    try {
      const response = await axios.put(`${BASE_URL}/request-for-quotation/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleClose && handleClose("");
        return data;
      } else {
        toast.error(customMsg, 5);
        return false;
      }
    } catch (error) {
      handleErrorMessage(error);
      return false;
    }
  }
);


//get single user
export const actionGetSingleRequestQuotation = createAsyncThunk(
  "actionGetSingleRequestQuotation",
  ({ companyId, id }) => {
    return handleGetResponse(`request-for-quotation/${id}/${companyId}`);
  }
);

export const actionDeleteRequestForQuotation = createAsyncThunk(
  "actionDeleteRequestForQuotation",
  async (
    { requstForQuotationId, handleCloseDeleteModal ,company_id},
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/request-for-quotation/${requstForQuotationId}/${company_id}`
      );
      const { status, message: customMsg } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCloseDeleteModal && handleCloseDeleteModal();
        return requstForQuotationId;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
