import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import plusIcon from "../../assets/img/add-button.svg";
import deletemaster from "../../assets/img/deleteMaster.svg";
import editedMaster from "../../assets/img/editedMaster.svg";
import editMaster from "../../assets/img/editMaster.svg";
import {
  actionAddRoleMaster,
  actionDeleteRoleMaster,
  actionEditRoleMaster,
  actionGetRoleMaster,
  actionGetSingleRoleMaster,
} from "../../store/services/roleService";
import ConfigProviderWrapper from "../common/ConfigProviderWrapper";
import CustomSearchInput from "../common/CustomSearchInput";
import SortIcon from "../common/SortIcon";
import { isValidSearchString } from "../helper/common-function";

const RoleMaster = () => {
  const [editableForm] = Form.useForm();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    getRoleMasterLoader,
    roleMasterData,
    getSingleRoleMasterLoader,
    getSingleRoleMasterData,
    addRoleMasterLoader,
    deleteRoleMasterLoader,
    updateRoleMasterLoader,
  } = useSelector((state) => state.role);

  const [search, setSearch] = useState("");
  const [edited, setEdited] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isEditing = (record) => record.id === editingKey;

  useEffect(() => {
    dispatch(actionGetRoleMaster({ search }));
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value;
    if (isValidSearchString(value)) {
      setSearch(value);
      if (value === "") {
        dispatch(
          actionGetRoleMaster({
            search: value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    const value = e.target.value;
    if (search && isValidSearchString(value)) {
      dispatch(
        actionGetRoleMaster({
          search: value,
        })
      );
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetRoleMaster({
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  useEffect(() => {
    if (getSingleRoleMasterData && edited) {
      editableForm.setFieldsValue({
        name: getSingleRoleMasterData.name,
        description: getSingleRoleMasterData.description,
      });
      setEditingKey(getSingleRoleMasterData.id);
    }
  }, [getSingleRoleMasterData]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleAddRole = (values) => {
    dispatch(actionAddRoleMaster({ values, handleCancel }));
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "select" ? <Select /> : <Input className="!text-[14px]" />;
    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: title === "Role" ? true : false,
                message: ``,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="table-cell-text"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(actionGetSingleRoleMaster(record.id));
    setEdited(!edited);
  };

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { name, description } = row;
      const req = { name, description };
      dispatch(actionEditRoleMaster({ id, req, setEditingKey, setEdited }));
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Role",
      dataIndex: "name",
      key: "1",
      width: 200,
      editable: true,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span
          className="cursor-pointer text-blue-600 hover:text-blue-600"
          onClick={() =>
            navigate("/assign-permission", {
              state: { roleId: data.id },
            })
          }
        >
          {text}
        </span>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "2",
      editable: true,
      ellipsis: true,
      render: (data) => (data ? data : "-"),
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Action",
      key: "3",
      width: 200,
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        const isDisabled =
          record.name === "BASIC" || record.name === "ADMINISTRATOR";
        return (
          <div
            className={`flex items-center`}
          >
            {editable ? (
              <div className="flex items-center">
                {updateRoleMasterLoader[record.id] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link onClick={() => save(record.id)}>
                    <img src={editMaster} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                >
                  <img src={editedMaster} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              <Popconfirm
                description="Are you sure you want to delete?"
                okButtonProps={{
                  loading: deleteRoleMasterLoader[record.id],
                }}
                onConfirm={() =>
                  dispatch(
                    actionDeleteRoleMaster({
                      id: record.id,
                      setEditingKey,
                    })
                  )
                }
              >
                <img src={deletemaster} alt="" className="cursor-pointer" />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "role" ? "input" : "input",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div className="master-input">
      <div className="flex justify-between mt-[15px]">
        <h1 className="text-black text-[20px] font-medium">Role</h1>
        <div>
          <Button
            onClick={showModal}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="PlusIcon Icon" preview={false} />}
          >
            Add Role
          </Button>
          <Modal
            className="input-custom"
            centered
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            footer={false}
            width={500}
            title=""
            open={isModalOpen}
          >
            <h1 className="text-black text-[20px]  mt-[5px] font-medium">
              Role
            </h1>
            <Form
              form={form}
              onFinish={handleAddRole}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Form.Item
                name="name"
                label="Role Name"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please input role name!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Role Name"
                  className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                className="mb-[12px]"
                size="small"
              >
                <Input.TextArea
                  rows={3}
                  style={{ background: "white" }}
                  placeholder="Enter Description"
                  className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <div className="flex justify-center mt-[40px]">
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="cancel-btn  min-h-[40px] min-w-[100px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      loading={addRoleMasterLoader}
                      type="primary"
                      className="root-btn min-h-[40px] min-w-[100px]"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
      <div className="form-table-design mt-[15px]">
        <div className="relative">
          <div className="search-bar">
            <CustomSearchInput
              handleSearch={handleSearch}
              handleBlurAndPressEnter={handleBlurAndPressEnter}
              searchValue={search}
            />
          </div>

          <div className="pt-3 pb-[75px]">
            <Form form={editableForm} component={false}>
              <ConfigProviderWrapper>
                <Table
                  bordered
                  onChange={handleSortChange}
                  className="ant-table-padding"
                  rowKey={(record) => record.id}
                  columns={mergedColumns}
                  dataSource={roleMasterData}
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  // virtual
                  scroll={{ y: 500 }}
                  pagination={false}
                  rowClassName="editable-row"
                  loading={getRoleMasterLoader}
                />
              </ConfigProviderWrapper>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleMaster;
