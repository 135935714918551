import { Table, Dropdown, Spin, Modal, Image, Button, Pagination } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import SortIcon from "../common/SortIcon";
import PageTitle from "../common/PageTitle";
import CustomSearchInput from "../common/CustomSearchInput";
import DeleteModal from "../common/DeleteModal";
import plusIcon from "../../assets/img/add-button.svg";
import RequestQuotationForm from "./RequestQuotationForm";
import dayjs from "dayjs";
import RequestQuotationIframe from "./RequestQuotationIframe";
import { useDispatch, useSelector } from "react-redux";
import {
  actionDeleteRequestForQuotation,
  actionGetRequestForQuotation,
  actionGetSingleRequestQuotation,
} from "../../store/services/quotationService";

const RequestQuotation = () => {
  const dispatch = useDispatch();
  const offsetRef = useRef(0);
  const { authData } = useSelector((state) => state.auth);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [requstForQuotationId, setRequestForQuotationId] = useState(null);
  const [search, setSearch] = useState("");
  const {
    requestForQuotationData,
    getRequestForQuotationLoader,
    getRequestQuotationDetailsLoader,
    requestForQuotationDataCount,
    getRequestQuotationDetailsData,
  } = useSelector((state) => state.quotation);

  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedClientData, setSelectedClientData] = useState(null);
  const [recordId, setRecordId] = useState(null);
  const [isAddRequestQuotationModalOpen, setIsAddRequestQuotationModalOpen] =
    useState(false);

  const companyId = authData?.company?.id;

  const getApiCall = (
    offset = 0,
    search = "",
    orderByColumn = "",
    order = ""
  ) => {
    dispatch(
      actionGetRequestForQuotation({
        offset,
        orderByColumn,
        order,
        search,
        companyId,
      })
    );
  };

  useEffect(() => {
    getApiCall(offsetRef.current);
  }, [dispatch]);

  useEffect(() => {
    if (requstForQuotationId && viewModalOpen) {
      dispatch(
        actionGetSingleRequestQuotation({
          id: requstForQuotationId,
          companyId,
        })
      );
    }
  }, [dispatch, requstForQuotationId]);

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleOpenRequestQuotationModal = () => {
    setIsAddRequestQuotationModalOpen(true);
  };

  const handleCloseRequestQuotationModal = () => {
    setIsAddRequestQuotationModalOpen(false);
    setRecordId(null);
  };

  const handleEditRequestQuotationModal = (id) => {
    setRecordId(id);
    setIsAddRequestQuotationModalOpen(true);
    dispatch(actionGetSingleRequestQuotation({ companyId, id }));
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Pending":
        return "text-[#FF9800] border border-[#FF9800]";
      case "Send_to_client":
        return "text-[#15a02b] border border-[#15a02b]";
      case "Rejected":
        return "text-[#F44336] border border-[#F44336]";
      default:
        return "text-gray-800 border-gray-800";
    }
  };

  console.log(requstForQuotationId);

  const invoiceStatuses = ["Pending", "Send to client", "Rejected"];

  const handleCellClick = (record) => {
    setRequestForQuotationId(record.request_for_quotation_id);
    setViewModalOpen(true);
  };

  const handleCancel = () => {
    setViewModalOpen(false);
    setSelectedClientData(null);
  };

  const columns = [
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      width: 200,
      sorter: true,
      sortIcon: () => <SortIcon />,
      onCell: (record) => ({
        onClick: () => handleCellClick(record),
        style: { cursor: "pointer", color: "#6883FD" },
      }),
      render: (text, record) => (
        <span>{record.client_name ? record.client_name : "-"}</span>
      ),
    },
    {
      title: "Email",
      dataIndex: "client_email",
      key: "client_email",
      width: 250,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Contact No.",
      dataIndex: "client_phone",
      key: "client_phone",
      width: 200,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Required Before",
      dataIndex: "require_before",
      key: "require_before",
      width: 200,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? dayjs(text).format("DD-MM-YYYY") : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => {
        const statusClass = getStatusClass(text);

        // Check if status is "Send_to_client" and replace with "Send to client"
        const displayText = text === "Send_to_client" ? "Send to client" : text;

        return displayText ? (
          <span
            className={`${statusClass} border py-1.5 rounded-[5px] px-2.5 text-sm`}
          >
            {displayText}
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      fixed: "right",
      render: (text, record) => (
        <Dropdown.Button
          menu={{
            items: [
              {
                label: <span>Edit</span>,
                key: "Edit",
                icon: <EditOutlined />,
                onClick: () =>
                  handleEditRequestQuotationModal(
                    record.request_for_quotation_id
                  ),
              },
              {
                label: "Delete",
                icon: <DeleteOutlined style={{ fontSize: 12 }} />,
                danger: true,
                onClick: () => {
                  setIsDeleteModalOpen((prev) => !prev);
                  setRequestForQuotationId(record?.request_for_quotation_id);
                  setIsDeleteModalOpen(true);
                },
                // onClick: handleOpenDeleteModal,
              },
            ],
          }}
        >
          Action
        </Dropdown.Button>
      ),
    },
  ];

  // const data = [];

  // for (let i = 0; i < 5; i++) {
  //   data.push({
  //     key: i,
  //     clientName: `Test Client ${i}`,
  //     email: `client${i}@example.com`,
  //     contactNo: `+1234567890${i}`,
  //     status: invoiceStatuses[i % invoiceStatuses.length],
  //     productServices: `Product/Service ${i}`,
  //     quantity: `${10 + i} pcs`,
  //   });
  // }

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      getApiCall(offsetRef.current, e.target.value);
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      getApiCall(offsetRef.current, e.target.value);
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    getApiCall(
      offsetRef.current,
      search,
      sorterParams.field,
      sorterParams.order
    );
  };

  return (
    <>
      <div className="flex justify-between items-center mt-[10px] mb-4">
        <h1 className="text-black text-[20px] font-monaSansMedium">
          Request For Quotation
        </h1>
        <div>
          <Button
            onClick={handleOpenRequestQuotationModal}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="PlusIcon Icon" preview={false} />}
          >
            Add Request For Quotation
          </Button>
        </div>
      </div>

      <CustomSearchInput
        handleSearch={handleSearch}
        handleBlurAndPressEnter={handleBlurAndPressEnter}
      />
      <div>
        <Table
          sticky
          rowKey="request_for_quotation_id"
          scrollToFirstRowOnChange={true}
          sortDirections={["ascend", "descend"]}
          onChange={handleSortChange}
          bordered
          scroll={{
            x: "max-content",
            y: window.innerWidth < 1500 ? "53vh" : "61vh",
          }}
          pagination={false}
          columns={columns}
          dataSource={requestForQuotationData}
          loading={getRequestForQuotationLoader}
        />
      </div>

      <div className="flex justify-end mt-3">
        <Pagination
          hideOnSinglePage
          responsive={true}
          current={offsetRef.current + 1}
          className="flex items-center"
          total={requestForQuotationDataCount}
          onChange={(e) => {
            offsetRef.current = e - 1;
            getApiCall(offsetRef.current, search);
          }}
        />
      </div>
      <Modal
        className="common-modal"
        centered
        width={800}
        loading={getRequestQuotationDetailsLoader}
        destroyOnClose={handleCloseRequestQuotationModal}
        footer={null}
        open={isAddRequestQuotationModalOpen}
        onCancel={handleCloseRequestQuotationModal}
        onOk={handleCloseRequestQuotationModal}
      >
        <h1 className="text-[18px] font-monaSansMedium mb-5">
          {recordId
            ? "Edit Request For Quotation"
            : "Add Request For Quotation"}
        </h1>
        <RequestQuotationForm
          handleClose={handleCloseRequestQuotationModal}
          data={getRequestQuotationDetailsData}
          isModal={true}
          recordId={recordId}
        />
      </Modal>

      <Modal
        className="common-modal"
        centered
        footer={false}
        open={viewModalOpen}
        onCancel={handleCancel}
        onOk={handleCancel}
      >
        <Spin spinning={getRequestForQuotationLoader}>
          <h1 className="text-[15px] font-monaSansMedium mb-2">
            View Request Quotation
          </h1>

          {getRequestQuotationDetailsData && (
            <div>
              {/* Client Details */}
              <div className="flex flex-col mt-3 gap-y-4">
                {/* Client Name */}
                <div className="flex gap-x-6 items-center">
                  <p className="text-xs text-[#818181] w-[25%] font-monaSansRegular">
                    Client Name:
                  </p>
                  <p className="text-sm text-black font-monaSansRegular w-[70%]">
                    {getRequestQuotationDetailsData.client_name ?? "-"}
                  </p>
                </div>

                {/* Email */}
                <div className="flex gap-x-6 items-center">
                  <p className="text-xs text-[#818181] font-monaSansRegular w-[25%]">
                    Email:
                  </p>
                  <p className="text-sm text-black font-monaSansRegular w-[70%]">
                    {getRequestQuotationDetailsData.client_email ?? "-"}
                  </p>
                </div>

                {/* Contact No. */}
                <div className="flex gap-x-6 items-center">
                  <p className="text-xs text-[#818181] w-[25%] font-monaSansRegular">
                    Contact No.:
                  </p>
                  <p className="text-sm text-black font-monaSansRegular w-[70%]">
                    {getRequestQuotationDetailsData.client_phone ?? "-"}
                  </p>
                </div>

                <div className="flex gap-x-6 items-center">
                  <p className="text-xs text-[#818181] w-[25%] font-monaSansRegular">
                    Required Before:
                  </p>
                  <p className="text-sm text-black font-monaSansRegular w-[70%]">
                    {getRequestQuotationDetailsData.require_before
                      ? dayjs(
                          getRequestQuotationDetailsData.require_before
                        ).format("DD-MM-YYYY")
                      : "-"}
                  </p>
                </div>

                {/* Status */}
                <div className="flex gap-x-6 items-center">
                  <p className="text-xs text-[#818181] w-[25%] font-monaSansRegular">
                    Status:
                  </p>
                  <p className="text-sm text-black font-monaSansRegular w-[70%]">
                    {getRequestQuotationDetailsData.status ?? "-"}
                  </p>
                </div>
              </div>
            </div>
          )}
        </Spin>
      </Modal>

      <DeleteModal
        title="Are you sure you want to remove this quotation?"
        description="This action is irreversible."
        onCancel={handleCloseDeleteModal} // Closes the modal
        onConfirm={() =>
          dispatch(
            actionDeleteRequestForQuotation({
              requstForQuotationId,
              company_id: companyId,
              handleCloseDeleteModal,
            })
          )
        }
        isVisible={isDeleteModalOpen} // Controls modal visibility
      />
    </>
  );
};

export default RequestQuotation;
