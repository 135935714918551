import { createSlice } from "@reduxjs/toolkit";
import {
  actionGetUnitMaster,
  actionAddUnitMaster,
  actionDeleteUnitMaster,
  actionEditUnitMaster,
  actionGetSingleUnitMaster,
} from "../services/unitMasterService";

const unitMasterSlice = createSlice({
  name: "unitMaster",
  initialState: {
    getUnitMasterLoader: false,
    addUnitMasterLoader: false,
    deleteUnitMasterLoader: {},
    updateUnitMasterLoader: {},
    getSingleUnitMasterData: {},
    getSingleUnitMasterLoader: {},
    unitMasterData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //get all address type master
      .addCase(actionGetUnitMaster.pending, (state) => {
        state.getUnitMasterLoader = true;
      })
      .addCase(actionGetUnitMaster.fulfilled, (state, action) => {
        state.getUnitMasterLoader = false;
        state.unitMasterData = action.payload.data;
      })
      .addCase(actionGetUnitMaster.rejected, (state) => {
        state.getUnitMasterLoader = false;
      })

      //add address type
      .addCase(actionAddUnitMaster.pending, (state) => {
        state.addUnitMasterLoader = true;
      })
      .addCase(actionAddUnitMaster.fulfilled, (state, action) => {
        state.addUnitMasterLoader = false;
        if (action.payload) {
          if (
            !state.unitMasterData ||
            !Array.isArray(state.unitMasterData)
          ) {
            state.unitMasterData = [action.payload];
          } else {
            state.unitMasterData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddUnitMaster.rejected, (state) => {
        state.addUnitMasterLoader = false;
      })

      //delete address master
      .addCase(actionDeleteUnitMaster.pending, (state, action) => {
        state.deleteUnitMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteUnitMaster.fulfilled, (state, action) => {
        state.unitMasterData = state.unitMasterData.filter(
          (type) => type.unit_master_id !== action.payload
        );
        state.deleteUnitMasterLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteUnitMaster.rejected, (state, action) => {
        state.deleteUnitMasterLoader[action.meta.arg.id] = false;
      })

      //get single address type
      .addCase(actionGetSingleUnitMaster.pending, (state) => {
        state.getSingleUnitMasterLoader = true;
      })
      .addCase(actionGetSingleUnitMaster.fulfilled, (state, action) => {
        state.getSingleUnitMasterData = action.payload.data;
        state.getSingleUnitMasterLoader = false;
      })
      .addCase(actionGetSingleUnitMaster.rejected, (state) => {
        state.getSingleUnitMasterLoader = false;
      })

      //update address type
      .addCase(actionEditUnitMaster.pending, (state, action) => {
        state.updateUnitMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditUnitMaster.fulfilled, (state, action) => {
        state.updateUnitMasterLoader[action.meta.arg.id] = false;
        state.unitMasterData = state.unitMasterData.map((type) =>
          type.unit_master_id === action.payload.unit_master_id
            ? {
                ...type,
                unit_code: action.payload.unit_code,
                description: action.payload.description,
              }
            : type
        );
      })
      .addCase(actionEditUnitMaster.rejected, (state, action) => {
        state.updateUnitMasterLoader[action.meta.arg.id] = false;
      });
  },
});
export default unitMasterSlice.reducer;
