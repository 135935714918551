import { createSlice } from "@reduxjs/toolkit";
import {
  actionGetQuotationStatusMaster,
  actionAddQuotationStatusMaster,
  actionDeleteQuotationStatusMaster,
  actionEditQuotationStatusMaster,
  actionGetSingleQuotationStatusMaster,
} from "../services/quotationStatusMasterService";

const quotationStatusMasterSlice = createSlice({
  name: "quotationStatusMaster",
  initialState: {
    getQuotationStatusMasterLoader: false,
    addQuotationStatusMasterLoader: false,
    deleteQuotationStatusMasterLoader: {},
    updateQuotationStatusMasterLoader: {},
    getSingleQuotationStatusMasterData: {},
    getSingleQuotationStatusMasterLoader: {},
    quotationStatusMasterData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //get all address type master
      .addCase(actionGetQuotationStatusMaster.pending, (state) => {
        state.getQuotationStatusMasterLoader = true;
      })
      .addCase(actionGetQuotationStatusMaster.fulfilled, (state, action) => {
        state.getQuotationStatusMasterLoader = false;
        state.quotationStatusMasterData = action.payload.data;
      })
      .addCase(actionGetQuotationStatusMaster.rejected, (state) => {
        state.getQuotationStatusMasterLoader = false;
      })

      //add address type
      .addCase(actionAddQuotationStatusMaster.pending, (state) => {
        state.addQuotationStatusMasterLoader = true;
      })
      .addCase(actionAddQuotationStatusMaster.fulfilled, (state, action) => {
        state.addQuotationStatusMasterLoader = false;
        if (action.payload) {
          if (
            !state.quotationStatusMasterData ||
            !Array.isArray(state.quotationStatusMasterData)
          ) {
            state.quotationStatusMasterData = [action.payload];
          } else {
            state.quotationStatusMasterData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddQuotationStatusMaster.rejected, (state) => {
        state.addQuotationStatusMasterLoader = false;
      })

      //delete address master
      .addCase(actionDeleteQuotationStatusMaster.pending, (state, action) => {
        state.deleteQuotationStatusMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteQuotationStatusMaster.fulfilled, (state, action) => {
        state.quotationStatusMasterData = state.quotationStatusMasterData.filter(
          (type) => type.quotation_status_master_id !== action.payload
        );
        state.deleteQuotationStatusMasterLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteQuotationStatusMaster.rejected, (state, action) => {
        state.deleteQuotationStatusMasterLoader[action.meta.arg.id] = false;
      })

      //get single address type
      .addCase(actionGetSingleQuotationStatusMaster.pending, (state) => {
        state.getSingleQuotationStatusMasterLoader = true;
      })
      .addCase(
        actionGetSingleQuotationStatusMaster.fulfilled,
        (state, action) => {
          state.getSingleQuotationStatusMasterData = action.payload.data;
          state.getSingleQuotationStatusMasterLoader = false;
        }
      )
      .addCase(actionGetSingleQuotationStatusMaster.rejected, (state) => {
        state.getSingleQuotationStatusMasterLoader = false;
      })

      //update address type
      .addCase(actionEditQuotationStatusMaster.pending, (state, action) => {
        state.updateQuotationStatusMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditQuotationStatusMaster.fulfilled, (state, action) => {
        state.updateQuotationStatusMasterLoader[action.meta.arg.id] = false;
        state.quotationStatusMasterData = state.quotationStatusMasterData.map((type) =>
          type.quotation_status_master_id === action.payload.quotation_status_master_id
            ? {
                ...type,
                quotation_status: action.payload.quotation_status,
                description: action.payload.description,
              }
            : type
        );
      })
      .addCase(actionEditQuotationStatusMaster.rejected, (state, action) => {
        state.updateQuotationStatusMasterLoader[action.meta.arg.id] = false;
      });
  },
});
export default quotationStatusMasterSlice.reducer;
