import { SearchOutlined } from "@ant-design/icons";
import { Input, Space } from "antd"; // Import necessary Ant Design components
import React from "react";

const CustomSearchInput = ({
  handleSearch,
  handleBlurAndPressEnter,
  searchValue,
}) => {

  const regex = /^[a-zA-Z0-9 @.-]*$/;

  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (regex.test(value)) {
      handleSearch(e);
    }
  };

  const handleBlurAndEnter = (e) => {
    const value = e.target.value.trim();
    if (regex.test(value)) {
      handleBlurAndPressEnter(e);
    }
  };

  return (
    <section className="">
      <Space.Compact className="w-full custom-input relative flex mb-5">
        <Input
          value={searchValue}
          onChange={handleSearchChange}
          onBlur={handleBlurAndEnter}
          onPressEnter={handleBlurAndEnter}
          size="large"
          className=" hover:border-[#BCB4B2] bg-transparent"
          id="none-border"
          allowClear
          prefix={<SearchOutlined className="text-[#7C7C7C]" />}
          placeholder="Search"
        />
      </Space.Compact>
    </section>
  );
};

export default CustomSearchInput;
