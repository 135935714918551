import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Row,
  Typography,
  Pagination,
  Col,
} from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import plusIcon from "../../assets/img/add-button.svg";
import deletemaster from "../../assets/img/deleteMaster.svg";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import editedMaster from "../../assets/img/editedMaster.svg";
import editMaster from "../../assets/img/editMaster.svg";
import CustomSearchInput from "../common/CustomSearchInput";
import ConfigProviderWrapper from "../common/ConfigProviderWrapper";
import SortIcon from "../common/SortIcon";
import {
  actionAddStateMaster,
  actionDeleteStateMaster,
  actionEditStateMaster,
  actionGetStates,
  actionGetSingleStateMaster,
} from "../../store/services/stateMasterService";
import { actionGetAllCountry } from "../../store/services/countryMasterService";
import { useDispatch, useSelector } from "react-redux";
import { isValidSearchString } from "../helper/common-function";
import { useFetcher } from "react-router-dom";

const AddressTypeMaster = () => {
  const offsetRef = useRef(0);
  const [editableForm] = Form.useForm();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    stateMasterData,
    getStateMasterLoader,
    addStateMasterLoader,
    deleteStateMasterLoader,
    updateStateMasterLoader,
    getSingleStateMasterLoader,
    getSingleStateMasterData,
    getStatesLoader,
    statesData,
    allStatesCount,
  } = useSelector((state) => state.stateMaster);
  const { countryMasterData } = useSelector((state) => state.countryMaster);

  const { allCountryMasterData } = useSelector((state) => state.countryMaster);
  // Generating 10 items of dummy data
  const [search, setSearch] = useState("");
  const [edited, setEdited] = useState(false);
  const [stateId, setStateId] = useState(null);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const isEditing = (record) => record.id === editingKey;

  useEffect(() => {
    dispatch(actionGetAllCountry());
  }, []);

  // Fetch all countries and set default to 'India'
  useEffect(() => {
    if (allCountryMasterData && allCountryMasterData.length > 0) {
      const defaultCountry = allCountryMasterData.find(
        (country) => country.name === "India"
      );
      if (defaultCountry) {
        setSelectedCountry(defaultCountry.id);
      }
    }
  }, [allCountryMasterData]);

  useEffect(() => {
    if (selectedCountry) {
      dispatch(
        actionGetStates({
          id: selectedCountry,
        })
      );
    }
  }, [selectedCountry, dispatch]);

  const onCountrySelect = (value) => {
    setSelectedCountry(value);
    dispatch(actionGetStates({ id: value, search }));
  };

  const onFinish = (values) => {
    const req = {
      ...values,
      country_id: values.country_id ? Number(values.country_id) : null,
      name: values.name ? values.name : "",
      state_code: values.state_code ? values.state_code : "",
    };

    stateId
      ? dispatch(
          actionEditStateMaster({
            id: getSingleStateMasterData.id,
            req,
            handleCancel,
            setSelectedCountry,
          })
        )
      : dispatch(
          actionAddStateMaster({
            req,
            handleCancel,
            setSelectedCountry,
          })
        );
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (isValidSearchString(value)) {
      setSearch(value);
      if (value === "") {
        dispatch(
          actionGetStates({
            search: value,
            id: selectedCountry,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    const value = e.target.value;
    if (search && isValidSearchString(value)) {
      dispatch(
        actionGetStates({
          search: value,
          id: selectedCountry,
        })
      );
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetStates({
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
        id: selectedCountry,
      })
    );
  };

  const countryOptions = useMemo(() => {
    return (
      allCountryMasterData &&
      allCountryMasterData.length > 0 &&
      allCountryMasterData.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
  }, [allCountryMasterData]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "select" ? <Select /> : <Input className="!text-[14px]" />;
    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: title === "State Name" ? true : false,
                message: ``,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="table-cell-text"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  /* useEffect(() => {
    if (stateId) {
      dispatch(
        actionGetSingleStateMaster({
          countryId: selectedCountry,
          id: stateId.id,
        })
      );
    }
  }, [dispatch, stateId]); */

  useEffect(() => {
    if (stateId && getSingleStateMasterData) {
      form.setFieldsValue({
        country_id: getSingleStateMasterData.country?.id,
        name: getSingleStateMasterData.name,
        state_code: getSingleStateMasterData.state_code,
      });
    }
  }, [dispatch, getSingleStateMasterData]);

  const columns = [
    {
      title: "Country Name",
      dataIndex: "country",
      key: "country",
      width: "30%",
      editable: true,
      // fixed: "left",
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (text ? text.name : "-"),
    },
    {
      title: "State Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
      editable: true,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "State Code",
      dataIndex: "state_code",
      key: "state_code",
      width: "30%",
      editable: true,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      // render: (text) => (text ? text.state_code : "-"),
    },
    {
      title: "Action",
      key: "4",
      width: 150,
      dataIndex: "operation",
      render: (_, record) => {
        const getSingleStateMasterData = isEditing(record);
        return (
          <div className={`flex items-center`}>
            {getSingleStateMasterData ? (
              <div className="flex items-center"></div>
            ) : (
              <div
                className={`
                } flex items-center gap-x-2 justify-center`}
              >
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => {
                    showModal();
                    setStateId(record);
                    dispatch(
                      actionGetSingleStateMaster({
                        countryId: selectedCountry,
                        id: record.id,
                      })
                    );
                  }}
                >
                  <img src={editedMaster} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              <Popconfirm
                okButtonProps={{
                  loading: deleteStateMasterLoader[record.id],
                }}
                description="Are you sure you want to delete?"
                onConfirm={() =>
                  dispatch(
                    actionDeleteStateMaster({
                      id: record.id,
                      // setEditingKey,
                      countryId: selectedCountry,
                      setEditingKey,
                    })
                  )
                }
              >
                <img src={deletemaster} alt="" className="cursor-pointer" />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "name" ? "input" : "input",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div className="master-input">
      <div className="flex justify-between mt-[15px]">
        <h1 className="text-black text-[20px] font-monaSansMedium">State</h1>
        <div>
          <Button
            onClick={showModal}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="PlusIcon Icon" preview={false} />}
          >
            Add State
          </Button>
          <Modal
            className="input-custom"
            centered
            onOk={handleCancel}
            onCancel={handleCancel}
            footer={false}
            width={500}
            destroyOnClose={handleCancel}
            title=""
            open={isModalOpen}
          >
            <h1 className="text-black text-[20px]  mt-[5px] font-monaSansMedium">
              State
            </h1>
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Form.Item
                name="country_id"
                label="Country"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please input country!",
                  },
                ]}
              >
                <Select
                  options={countryOptions}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  style={{ background: "white" }}
                  placeholder="Select Country"
                  className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <Form.Item
                name="name"
                label="State Name"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please input state name!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter State Name"
                  className=" bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <Form.Item
                name="state_code"
                label="State Code"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please input code!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter State Code"
                  className=" bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <div className="flex justify-center mt-[40px]">
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="cancel-btn  min-h-[40px] min-w-[100px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      type="primary"
                      loading={addStateMasterLoader || updateStateMasterLoader}
                      className="root-btn min-h-[40px] min-w-[100px]"
                      htmlType="submit"
                    >
                      {stateId ? "Update" : "Save"}
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
      <div className="form-table-design mt-[15px]">
        <div className="relative">
          <div className="search-bar flex">
            {/* <CustomSearchInput
              handleSearch={handleSearch}
              handleBlurAndPressEnter={handleBlurAndPressEnter}
              searchValue={search}
            /> */}
            <Input
              className="custom-search-in p-[8px 11px 8px 11px] w-[70%]"
              placeholder="Search"
              value={search}
              onChange={handleSearch}
              onBlur={handleBlurAndPressEnter}
              onPressEnter={handleBlurAndPressEnter}
              prefix={<SearchOutlined className="text-[#7C7C7C]" />}
            />
            <Select
              // allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ background: "white" }}
              placeholder="Select Country"
              value={selectedCountry} // Set the ID here
              options={countryOptions}
              onSelect={onCountrySelect} // Use onSelect to handle selection
              className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px] ml-2 w-[30%]"
            />
          </div>

          <div className="pt-3 pb-[75px]">
            <Form form={editableForm} component={false}>
              <ConfigProviderWrapper>
                <Table
                  bordered
                  onChange={handleSortChange}
                  className="ant-table-padding"
                  rowKey="id"
                  columns={mergedColumns}
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  scroll={{
                    y: 400,
                  }}
                  loading={getStatesLoader}
                  dataSource={statesData}
                  pagination={false}
                  rowClassName="editable-row"
                  // scroll={{ x: 1200 }} // Enable horizontal scroll for wider tables
                />
                <div className="flex justify-end mt-3">
                  <Pagination
                    hideOnSinglePage
                    responsive={true}
                    current={offsetRef.current + 1}
                    className="flex items-center"
                    showSizeChanger={false}
                    total={allStatesCount}
                    onChange={(e) => {
                      offsetRef.current = e - 1;
                      dispatch(
                        actionGetStates({
                          id: selectedCountry,
                          offset: offsetRef.current,
                          search,
                        })
                      );
                    }}
                  />
                </div>
              </ConfigProviderWrapper>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressTypeMaster;
