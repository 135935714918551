import React from "react";
import { Modal, Button, Form } from "antd";

const DeleteModal = ({
  isVisible,
  onCancel,
  onConfirm,
  loading = false,
  title = "Are you sure you want to remove this item?",
  description = "This action cannot be undone.",
  confirmText = "Delete",
  cancelText = "Cancel",
  width = 600,
}) => {
  return (
    <Modal
      centered
      onOk={onCancel}
      onCancel={onCancel}
      afterClose={onCancel}
      footer={null}
      width={width}
      open={isVisible}
      className="delete-modal"
    >
      <div>
        <h1 className="text-[#000000;] text-[20px] font-popinsMedium mt-2">
          {title}
        </h1>
        <p className="text-[15px] text-[#7A7A7A] pt-3">{description}</p>
        <div className="flex items-center pt-8 justify-end w-full gap-x-3">
          <Form.Item className="mb-0">
            <Button
              onClick={onCancel}
              type="button"
              className="gray-button min-h-[45px] min-w-[110px]"
            >
              {cancelText}
            </Button>
          </Form.Item>
          <Form.Item className="mb-0">
            <Button
              loading={loading}
              className="delete-btn min-h-[45px] min-w-[110px]"
              onClick={onConfirm}
            >
              {confirmText}
            </Button>
          </Form.Item>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
