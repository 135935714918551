import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import addressIcon from "../../assets/img/addressIcon.svg";
import customerIcon from "../../assets/img/customerIcon.svg";
import AddPopover from "../common/AddPopover";
import BackArrowButton from "../common/BackArrowButton";
import CustomIconText from "../common/CustomIconText";
// import { PhoneInput } from "react-international-phone";
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { toast } from "sonner";
import editPrimaryWhite from "../../assets/img/editPrimaryWhite.svg";
import { BASE_URL_UPLOAD } from "../../config/web-config";
import {
  actionAddAddressTypeMaster,
  actionGetAddressTypeMaster,
} from "../../store/services/addressTypeMasterService";
import { actionGetAllCountry } from "../../store/services/countryMasterService";
import { actionAllStatesIndex } from "../../store/services/stateMasterService";
import PhoneInputComponent from "../common/PhoneInputComponent";
import {
  actionAddCustomer,
  actionGetSingleCustomerDetail,
  actionUpdateCustomer,
} from "../../store/services/customerService";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CreateCustomer = ({ isModal = false, customerForm, setIsModalOpen }) => {
  const [addressTypePopovers, setAddressTypePopovers] = useState({});

  const [countrySelections, setCountrySelections] = useState({});
  const [contactProfilePicUrl, setContactProfilePicUrl] = useState();

  const [phoneData, setPhoneData] = useState({
    phone: "",
    fullPhoneNumber: "",
    iso: "",
    isValid: false,
    dialCode: "91",
  });

  const [secondaryPhoneData, setSecondaryPhoneData] = useState({
    phone: "",
    fullPhoneNumber: "",
    iso: "",
    isValid: false,
    dialCode: "91",
  });

  const [isPhoneSubmitted, setIsPhoneSubmitted] = useState(false);
  const [isSecondaryPhoneSubmitted, setIsSecondaryPhoneSubmitted] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [CreateCustomerForm] = Form.useForm();
  const [addressTypeForm] = Form.useForm();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedAddressTypes, setSelectedAddressTypes] = useState({});

  const { customerId } = location.state || {};
  const { allCountryMasterData, getAllCountryMasterLoader } = useSelector(
    (state) => state.countryMaster
  );

  const {
    addCustomerLoader,
    getCustomerDetailLoader,
    updateCustomerLoader,
    singleCustomerDetailData,
  } = useSelector((state) => state.customer);

  const { getStatesLoader, allStatesIndexData } = useSelector(
    (state) => state.stateMaster
  );
  const {
    getAddressTypeMasterLoader,
    addressTypeMasterData,
    addAddressTypeMasterLoader,
  } = useSelector((state) => state.addressTypeMaster);

  useEffect(() => {
    dispatch(actionGetAllCountry());
    dispatch(actionGetAddressTypeMaster());
  }, [dispatch]);

  useEffect(() => {
    if (customerId) {
      dispatch(actionGetSingleCustomerDetail(customerId));
    }
  }, [customerId]);

  useEffect(() => {
    if (customerId && singleCustomerDetailData) {
      // Create an object to store the country selections
      const newCountrySelections = {};

      const initialAddressTypes = {};
      if (
        singleCustomerDetailData.customers_address_master &&
        singleCustomerDetailData.customers_address_master.length > 0
      ) {
        singleCustomerDetailData.customers_address_master.forEach(
          async (address, index) => {
            if (address.address_type_master?.address_type_master_id) {
              initialAddressTypes[index] =
                address.address_type_master.address_type_master_id;
            }
            if (address.country?.id) {
              newCountrySelections[index] = true;
              await dispatch(
                actionAllStatesIndex({ countryId: address.country.id, index })
              );
            }
            setSelectedAddressTypes(initialAddressTypes);
          }
        );

        CreateCustomerForm.setFieldsValue({
          customer_name: singleCustomerDetailData.customer_name,
          email: singleCustomerDetailData.email,
          secondary_email: singleCustomerDetailData.secondary_email,
          tin_number: singleCustomerDetailData.tin_number,

          addresses: singleCustomerDetailData.customers_address_master.map(
            (address) => ({
              ...address,
              country_id: address.country?.id,
              states_id: address.states?.id,
              address_type_master_id:
                address.address_type_master?.address_type_master_id,
            })
          ),
        });
      }

      const fullPhoneNumber = `+${singleCustomerDetailData.country_code}${singleCustomerDetailData.phone}`;
      const fullSecondaryPhoneNumber = `+${singleCustomerDetailData.country_code}${singleCustomerDetailData.secondary_phone}`;

      setPhoneData({
        phone: singleCustomerDetailData.phone,
        fullPhoneNumber: fullPhoneNumber,
        iso: singleCustomerDetailData.phone_country_code,
        isValid: true,
        dialCode: singleCustomerDetailData.country_code,
      });
      const secondaryPhone = singleCustomerDetailData.secondary_phone;
      const secondaryPhoneCountryCode =
        singleCustomerDetailData.secondary_phone_country_code || "in";

      setSecondaryPhoneData({
        phone: secondaryPhone,
        fullPhoneNumber: fullSecondaryPhoneNumber,
        iso: secondaryPhoneCountryCode,
        isValid: true,
        dialCode: singleCustomerDetailData.secondary_country_code,
      });

      setCountrySelections((prev) => ({ ...prev, ...newCountrySelections }));

      setContactProfilePicUrl(singleCustomerDetailData.profile_pic);
    }
  }, [singleCustomerDetailData, dispatch]);

  const handleChangeContactProfilePic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setContactProfilePicUrl(info.file.response.data);
        toast.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error(`${info.file.name} file upload failed.`);
    }
  };
  const getFilteredAddressTypeOptions = (index) => {
    const selectedTypes = Object.values(selectedAddressTypes);
    return addressTypeData.filter(
      (option) =>
        !selectedTypes.includes(option.value) ||
        selectedAddressTypes[index] === option.value
    );
  };

  const handleAddressTypeChange = (value, index) => {
    setSelectedAddressTypes((prev) => {
      const newSelectedTypes = { ...prev };
      if (value) {
        newSelectedTypes[index] = value; // Update with the new value
      } else {
        delete newSelectedTypes[index]; // Remove if no value selected
      }
      return newSelectedTypes;
    });
  };

  // add address type
  const showAddAddressTypeForm = (index) => {
    return (
      <div className="min-w-[350px]">
        <Form
          form={addressTypeForm}
          onFinish={(values) =>
            dispatch(
              actionAddAddressTypeMaster({
                values,
                handleCancel: closeAddressTypePopover(index),
                // form: CreateCustomerForm,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="address_type"
            label="Address Type Name"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input address type name!",
              },
            ]}
          >
            <Input
              placeholder="Enter Address Type Name"
              className="py-[10px]"
            />
          </Form.Item>

          <div className="flex justify-end mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => closeAddressTypePopover(index)}
                type="button"
                className="cancel-btn  min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addAddressTypeMasterLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    );
  };

  // Toggle functions for popovers
  const toggleAddressTypePopover = (index) => {
    setAddressTypePopovers((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Close functions for popovers
  const closeAddressTypePopover = (index) => {
    setAddressTypePopovers((prev) => ({
      ...prev,
      [index]: false,
    }));
    addressTypeForm.resetFields();
  };

  // address type data
  const addressTypeData =
    addressTypeMasterData && addressTypeMasterData.length > 0
      ? addressTypeMasterData.map((address) => ({
          key: address.address_type_master_id,
          label: address.address_type,
          value: address.address_type_master_id,
        }))
      : [];

  const handleCountryChange = (value, option, index, field, form) => {
    if (value) {
      dispatch(actionAllStatesIndex({ countryId: value, index }));
      setCountrySelections((prev) => ({ ...prev, [index]: true }));
    } else {
      setCountrySelections((prev) => ({ ...prev, [index]: false }));
    }

    form.setFieldsValue({
      addresses: form
        .getFieldValue("addresses")
        .map((address, i) =>
          i === index ? { ...address, states_id: undefined } : address
        ),
    });
  };

  const handleClearState = (index, form) => {
    setCountrySelections((prev) => ({ ...prev, [index]: false }));

    form.setFieldsValue({
      addresses: form
        .getFieldValue("addresses")
        .map((address, i) =>
          i === index ? { ...address, states_id: undefined } : address
        ),
    });
  };

  const country =
    (allCountryMasterData &&
      allCountryMasterData.length &&
      allCountryMasterData?.map(({ name, iso2, id, ...data }) => ({
        ...data,
        label: name,
        value: id,
        id: id,
      }))) ||
    [];

  const handlePhoneChange = (
    fullPhoneNumber,
    phone,
    iso,
    isValid,
    dialCode
  ) => {
    setPhoneData({
      phone: phone,
      fullPhoneNumber: fullPhoneNumber,
      iso: iso,
      isValid: isValid,
      dialCode,
    });
  };

  const handleSecondaryPhoneChange = (
    fullPhoneNumber,
    phone,
    iso,
    isValid,
    dialCode
  ) => {
    setSecondaryPhoneData({
      phone: phone,
      fullPhoneNumber: fullPhoneNumber,
      iso: iso,
      isValid: isValid,
      dialCode,
    });
  };

  const handleSubmit = (values) => {
    setIsPhoneSubmitted(true);
    setIsSecondaryPhoneSubmitted(true);

    if (phoneData.isValid && secondaryPhoneData.isValid) {
      const req = {
        ...values,
        phone: phoneData.phone,
        secondary_phone: secondaryPhoneData.phone
          ? secondaryPhoneData.phone
          : null,
        phone_country_code: phoneData.iso ? phoneData.iso.toUpperCase() : null,
        country_code: phoneData.dialCode ? phoneData.dialCode : null,
        secondary_phone_country_code: secondaryPhoneData.phone
          ? secondaryPhoneData.iso
            ? secondaryPhoneData.iso.toUpperCase()
            : null
          : null,
        secondary_country_code: secondaryPhoneData.dialCode
          ? secondaryPhoneData.dialCode
          : null,
        profile_pic_id: contactProfilePicUrl
          ? contactProfilePicUrl.upload_id
          : null,
      };

      customerId
        ? dispatch(
            actionUpdateCustomer({
              id: customerId,
              req,
              CreateCustomerForm,

              navigate,
            })
          )
        : dispatch(
            actionAddCustomer({
              req,
              CreateCustomerForm,
              customerForm,
              navigate: isModal ? null : navigate,
              setIsModalOpen,
            })
          );
    }
  };

  return (
    <Spin spinning={getCustomerDetailLoader}>
      <div className="flex items-center gap-x-3 justify-between">
        <BackArrowButton />
        <p className="text-lg font-monaSansSemiBold">
          {customerId ? "Update Customer" : "Create Customer"}
        </p>
      </div>
      <Form
        preserve={true}
        scrollToFirstError={true}
        form={CreateCustomerForm}
        initialValues={{
          country_code: "in",
          addresses: [{}],
        }}
        layout="vertical"
        name="basic"
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Card className=" mt-3 w-full">
          <CustomIconText
            image={customerIcon}
            title={"Customer Details"}
            backArrow={true}
          />
          <Row gutter={24}>
            <Col span={24} align="middle">
              <Form.Item
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: false,
                    message: "Please enter profile pic!",
                  },
                ]}
              >
                {contactProfilePicUrl ? (
                  <div className="relative border-secondary  h-[102px]  w-[102px] rounded-[6px] mb-2">
                    <img
                      src={contactProfilePicUrl?.url}
                      alt="avatar"
                      className="h-full w-full rounded-[6px]"
                    />

                    <ImgCrop rotationSlider aspect={1 / 1} fillColor="white">
                      <Upload
                        id=""
                        accept=".png,.jpg,.jpeg,.pdf,.doc"
                        name="file"
                        listType="picture-card"
                        className="border-0 absolute main-upload bottom-0 right-0 avatar-uploader"
                        showUploadList={false}
                        action={BASE_URL_UPLOAD}
                        openFileDialogOnClick={true}
                        headers={{
                          Authorization:
                            "Bearer " + localStorage.getItem("qmsToken"),
                        }}
                        onChange={handleChangeContactProfilePic}
                      >
                        <div className=" px-1.5 py-1 border-0 absolute right-0 bottom-0 bg-red-500 rounded-tl-[6px] rounded-br-[6px] cursor-pointer flex">
                          <Image
                            width={15}
                            height={15}
                            src={editPrimaryWhite}
                            className="rounded-lg"
                            preview={false}
                          />
                        </div>
                      </Upload>
                    </ImgCrop>
                  </div>
                ) : (
                  <ImgCrop aspect={1 / 1} rotationSlider fillColor="white">
                    <Upload
                      name="file"
                      listType="picture-card"
                      className="avatar-uploader mb-2"
                      showUploadList={false}
                      action={BASE_URL_UPLOAD}
                      openFileDialogOnClick={true}
                      headers={{
                        Authorization:
                          "Bearer " + localStorage.getItem("qmsToken"),
                      }}
                      onChange={handleChangeContactProfilePic}
                    >
                      <button
                        style={{
                          border: 0,
                          background: "none",
                        }}
                        type="button"
                      >
                        {loading ? (
                          <LoadingOutlined />
                        ) : (
                          <PlusOutlined style={{ color: "#2B2526" }} />
                        )}
                        <div
                          style={{
                            marginTop: 8,
                          }}
                        >
                          Image
                        </div>
                      </button>
                    </Upload>
                  </ImgCrop>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Customer / Company Name"
                name="customer_name"
                rules={[
                  {
                    required: true,
                    message: "Please input customer / company name!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Customer / Company Name"
                  className="py-[10px]"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    message: "Please input valid email!",
                  },
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input placeholder="Email" className="py-[10px]" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                preserve={true}
                name="secondary_email"
                label="Secondary Email"
                rules={[
                  {
                    type: "email",
                    message: "Please input valid email!",
                  },
                  {
                    required: false,
                    message: "Please input secondary email!",
                  },
                ]}
              >
                <Input placeholder="Email" className="py-[10px]" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <PhoneInputComponent
                required={true}
                value={phoneData.fullPhoneNumber}
                onChange={handlePhoneChange}
                isValid={phoneData.isValid}
                submitted={isPhoneSubmitted}
              />
            </Col>
            <Col xs={24} md={12}>
              <PhoneInputComponent
                required={false}
                label="Secondary Mobile Number"
                value={secondaryPhoneData.fullPhoneNumber}
                onChange={handleSecondaryPhoneChange}
                isValid={secondaryPhoneData.isValid}
                submitted={isSecondaryPhoneSubmitted}
              />
            </Col>
            <Col span={12}>
              <Form.Item
                label="TIN Number"
                name="tin_number"
                rules={[
                  {
                    required: true,
                    message: "Please input TIN number!",
                  },
                  {
                    min:9,
                    message: "TIN number must be at least 9 digits!",
                  },
                ]}
                normalize={(value) => value.replace(/[^\d]/g, "")}
              >
                <Input placeholder="Enter TIN Number" className="py-[10px]" />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Form.List name="addresses">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => {
                const stateOptions = allStatesIndexData[index] || [];

                const stateData = stateOptions.map(({ name, id, ...data }) => ({
                  ...data,
                  label: name,
                  value: id,
                }));
                return (
                  <Card
                    className="mt-3 w-full"
                    key={field.key}
                    title={
                      <div className="flex items-center gap-x-3">
                        <Image
                          src={addressIcon}
                          alt="image"
                          height={20}
                          width={20}
                          preview={false}
                        />
                        <h1 className="lg:text-lg text-base font-monaSansSemiBold">
                          {`Address ${index + 1}`}
                        </h1>
                      </div>
                    }
                    extra={
                      <CloseOutlined
                        disabled={fields.length === 1}
                        onClick={() => {
                          remove(index);
                          setSelectedAddressTypes((prev) => {
                            const updated = { ...prev };
                            delete updated[index];
                            return updated;
                          });
                        }}
                        className={`text-secondary ${
                          fields.length === 1
                            ? "opacity-0 pointer-events-none"
                            : ""
                        }`}
                      />
                    }
                  >
                    <Row gutter={24}>
                      <Col span={12}>
                        <AddPopover
                          content={() => showAddAddressTypeForm(index)}
                          // isOpen={addAddressTypePopoverOpen}
                          isOpen={addressTypePopovers[index] || false}
                          onOpenChange={(visible) => {
                            if (!visible) {
                              closeAddressTypePopover(index);
                            }
                          }}
                          text="Add Address Type"
                          showModal={() => toggleAddressTypePopover(index)}
                        />
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please select address type!",
                            },
                          ]}
                          label="Address Type"
                          name={[index, "address_type_master_id"]}
                        >
                          <Select
                            allowClear
                            showSearch
                            loading={getAddressTypeMasterLoader}
                            disabled={getAddressTypeMasterLoader}
                            placeholder="Select Address Type"
                            optionFilterProp="children"
                            className=""
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onChange={(value) => {
                              setSelectedAddressTypes((prev) => ({
                                ...prev,
                                [index]: value,
                              }));
                            }}
                            options={getFilteredAddressTypeOptions(index)}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          label="Address Line 1"
                          name={[index, "address_line_1"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input address!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Address Line 1"
                            className="py-[10px]"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          label="Address Line 2"
                          name={[index, "address_line_2"]}
                        >
                          <Input
                            placeholder="Enter Address Line 2"
                            className="py-[10px]"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          label="Country"
                          name={[index, "country_id"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input country!",
                            },
                          ]}
                        >
                          <Select
                            allowClear
                            showSearch
                            loading={getAllCountryMasterLoader}
                            onClear={() =>
                              handleClearState(index, CreateCustomerForm)
                            }
                            onChange={(value, option) =>
                              handleCountryChange(
                                value,
                                option,
                                index,
                                field,
                                CreateCustomerForm
                              )
                            }
                            placeholder="Select Country"
                            optionFilterProp="children"
                            className=""
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={country}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="State"
                          name={[index, "states_id"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input country!",
                            },
                          ]}
                        >
                          <Select
                            allowClear
                            showSearch
                            loading={getStatesLoader}
                            disabled={!countrySelections[index]}
                            placeholder="Select State"
                            optionFilterProp="children"
                            className=""
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={stateData}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          label="City"
                          rules={[
                            { required: true, message: "Please input city!" },
                          ]}
                          name={[index, "city_district"]}
                        >
                          <Input
                            placeholder="Enter City"
                            className="py-[10px]"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item
                          label="Postal Code"
                          name={[index, "pin_code"]}
                          rules={[
                            {
                              required: false,
                              message: "Please input postal code!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Postal Code"
                            className="py-[10px]"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                );
              })}
              <div
                onClick={() => add()}
                block
                className="text-end mt-2 lg:mt-4"
              >
                <p className="text-secondary text-[17px] lg:text-[20px] font-monaSansMedium cursor-pointer">
                  + Add Address
                </p>
              </div>
            </>
          )}
        </Form.List>
        <Row gutter={24}>
          <Col span={24} className="flex gap-x-3 justify-center mt-2 lg:mt-4">
            <Form.Item className="mb-0">
              <Button
                onClick={async () => {
                  navigate(-1);
                }}
                type="button"
                className="cancel-btn min-h-[45px] min-w-[120px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <Form.Item className="mb-0">
              <Button
                loading={addCustomerLoader || updateCustomerLoader}
                type="primary"
                className="root-btn min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                {customerId ? "Update" : "Save"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default CreateCustomer;
