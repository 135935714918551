import { createSlice } from "@reduxjs/toolkit";
import {
  actionAddCustomer,
  actionGetSingleCustomerDetail,
  actionGetCustomers,
  actionGetAllCustomers,
  actionDeleteCustomer,
  actionUpdateCustomer,
} from "../services/customerService";

const customerSlice = createSlice({
  name: "customer",
  initialState: {
    getCustomersLoader: false,
    getAllCustomersLoader: false,
    customers: [],
    customersCount: 0,
    allCustomers: [],
    addCustomerLoader: false,
    getCustomerDetailLoader: false,
    singleCustomerDetailData: "",
    deleteCustomerLoader: false,
    updateCustomerLoader: false,
  },
  extraReducers: (builder) => {
    builder
      // actionGetCustomers State
      .addCase(actionGetCustomers.pending, (state) => {
        state.getCustomersLoader = true;
      })
      .addCase(actionGetCustomers.fulfilled, (state, action) => {
        state.getCustomersLoader = false;
        state.customers = action.payload.data;
        state.customersCount = action.payload.total_records;
      })
      .addCase(actionGetCustomers.rejected, (state) => {
        state.getCustomersLoader = false;
      })

      // actionGetAllCustomers State
      .addCase(actionGetAllCustomers.pending, (state) => {
        state.getAllCustomersLoader = true;
      })
      .addCase(actionGetAllCustomers.fulfilled, (state, action) => {
        state.getAllCustomersLoader = false;
        state.allCustomers = action.payload.data;
      })
      .addCase(actionGetAllCustomers.rejected, (state) => {
        state.getAllCustomersLoader = false;
      })

      // actionAddCustomer State
      .addCase(actionAddCustomer.pending, (state) => {
        state.addCustomerLoader = true;
      })
      .addCase(actionAddCustomer.fulfilled, (state, action) => {
        state.addCustomerLoader = false;
        if (!state.customers || !Array.isArray(state.customers) || !state.allCustomers || !Array.isArray(state.allCustomers)) {
          state.customers = [action.payload];
          state.allCustomers = [action.payload];
        } else {
          state.customers.unshift(action.payload);
          state.allCustomers.unshift(action.payload);
        }
      })
      .addCase(actionAddCustomer.rejected, (state) => {
        state.addCustomerLoader = false;
      })

      // actionGetCustomerDetail State
      .addCase(actionGetSingleCustomerDetail.pending, (state) => {
        state.getCustomerDetailLoader = true;
      })
      .addCase(actionGetSingleCustomerDetail.fulfilled, (state, action) => {
        state.getCustomerDetailLoader = false;
        state.singleCustomerDetailData = action.payload.data;
      })
      .addCase(actionGetSingleCustomerDetail.rejected, (state) => {
        state.getCustomerDetailLoader = false;
      })

      // actionDeleteCustomer state
      .addCase(actionDeleteCustomer.pending, (state) => {
        state.deleteCustomerLoader = true;
      })
      .addCase(actionDeleteCustomer.fulfilled, (state, action) => {
        state.deleteCustomerLoader = false;

        state.customers = state.customers.filter(
          (customer) => customer.customers_id !== action.payload
        );
      })
      .addCase(actionDeleteCustomer.rejected, (state) => {
        state.deleteCustomerLoader = false;
      })

      //actionUpdateCustomer state
      .addCase(actionUpdateCustomer.pending, (state) => {
        state.updateCustomerLoader = true;
      })
      .addCase(actionUpdateCustomer.fulfilled, (state, action) => {
        state.updateCustomerLoader = false;
      })
      .addCase(actionUpdateCustomer.rejected, (state) => {
        state.updateCustomerLoader = false;
      });
  },
});

export default customerSlice.reducer;
