import React, { useEffect, useState } from "react";
import CustomIconText from "../common/CustomIconText";
import customerIcon from "../../assets/img/customerIcon.svg";
import { Card, Col, Collapse, Row, Spin, Table } from "antd";
import { FiMail, FiPhone, FiUser, FiCreditCard } from "react-icons/fi";
import BackArrowButton from "../common/BackArrowButton";
import { CaretRightOutlined } from "@ant-design/icons";
import { actionGetSingleCustomerDetail } from "../../store/services/customerService";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { getFullName } from "../helper/common-function";
// import customerIcon from "../../assets/img/customerIcon.svg";

const ViewCustomer = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const { id } = location.state || {};
  const { singleCustomerDetailData, getCustomerDetailLoader } = useSelector(
    (state) => state.customer
  );
  const [activeKey, setActiveKey] = useState(["1"]);
  const [activeQuotationKey, setActiveQuoatationKey] = useState("1");

  useEffect(() => {
    if (id) {
      dispatch(actionGetSingleCustomerDetail(id));
    }
  }, [id, dispatch]);

  const getStatusClass = (status) => {
    const baseClasses = "px-3 py-1.5 rounded-[5px] text-sm font-monaSansMedium";

    const statusConfig = {
      expired: "text-[#DD1B1B] border border-[#DD1B1B]",
      active: "text-[#0587FF] border border-[#0587FF]",
      completed: "text-[#15A02B] border border-[#15A02B]",
      pending: "text-[#C69619] border border-[#C69619]",
      "on hold": "text-[#FF9800] border border-[#FF9800]",
      canceled: "text-[#E53935] border border-[#E53935]",
      "under negotiation": "text-[#673AB7] border border-[#673AB7]",
      rejected: "text-[#F44336] border border-[#F44336]",
      sent: "text-[#00BCD4] border border-[#00BCD4]",
      "awaiting customer response": "text-[#3F51B5] border border-[#3F51B5]",
      accepted: "text-[#4CAF50] border border-[#4CAF50]",
      draft: "text-[#607D8B] border border-[#607D8B]",
      approved: "text-[#009688] border border-[#009688]",
    };

    return `${baseClasses} ${statusConfig[status.toLowerCase()] || ""}`;
  };

  const invoiceStatuses = ["Expired", "Active", "Completed", "Pending"];

  const columns = [
    {
      title: "Quotation Number",
      dataIndex: "quotation_invoice",
      key: "quotation_invoice",
      width: 200,
      render: (text, data) => (
        <span className="ml-2">
          {data.quotation_invoice ? data.quotation_invoice : "-"}
        </span>
      ),
    },
    {
      title: "TIN Number",
      dataIndex: "tin_number",
      key: "tin_number",
      width: 150,
      render: (text, data) => (
        <span className="ml-2">{data.tin_number ? data.tin_number : "-"}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "quotation_status",
      key: "quotation_status",
      width: 250,
      render: (text) => {
        const statusClass = getStatusClass(
          text?.quotation_status.toLowerCase()
        ); // Ensure you have this function defined
        return text ? (
          <span
            className={`${statusClass} border py-1.5 rounded-[5px] px-2.5 text-sm`}
          >
            {text?.quotation_status}
          </span>
        ) : (
          "-"
        );
      },
    },

    {
      title: "Expiry Date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      width: 150,
      render: (text, data) => (
        <span>{text ? dayjs(text).format("DD-MM-YYYY") : "-"}</span>
      ),
    },
    {
      title: "Prepared by",
      key: "created_by",
      width: 200,
      dataIndex: "created_by",
      render: (text, data) => (
        <div className="flex items-center">
          <p className="ml-2">
            {getFullName(text?.first_name, text?.last_name)}
          </p>
        </div>
      ),
    },
  ];

  console.log({ getCustomerDetailLoader });

  return (
    <>
      <Spin spinning={getCustomerDetailLoader}>
        <div className="flex items-center gap-x-3 justify-between">
          <BackArrowButton />
        </div>

        <Card className=" mt-3 w-full custom-card-view border border-[#BCB4B2]">
          <CustomIconText
            image={customerIcon}
            title={"Customer Details"}
            backArrow={true}
          />
          <Row>
            {singleCustomerDetailData && (
              <>
                <Col span={12} className="pr-4">
                  <div className="flex items-center gap-x-5">
                    <div className="flex flex-col">
                      <p className="text-[15px] font-monaSansMedium mt-2">
                        <img
                          src={customerIcon}
                          className="inline-block mr-2 text-[#7C7C7C] -[15px] w-[15px]"
                          alt="Customer Icon"
                        />
                        Customer / Company Name:
                        <span className="text-[#7C7C7C] ml-2 font-monaSansRegular">
                          {singleCustomerDetailData.customer_name ?? "-"}
                        </span>
                      </p>
                      <p className="text-[15px] mt-2 font-monaSansMedium">
                        <FiMail className="inline-block mr-2 text-[#7C7C7C]" />
                        Secondary Email:
                        <span className="text-[#7C7C7C] ml-2 font-monaSansRegular">
                          {singleCustomerDetailData.secondary_email ?? "-"}
                        </span>
                      </p>
                      <p className="text-[15px] mt-2 font-monaSansMedium">
                        <FiPhone className="inline-block mr-2 text-[#7C7C7C]" />
                        Secondary Mobile Number:
                        <span className="text-[#7C7C7C] font-monaSansRegular ml-2">
                          {singleCustomerDetailData.secondary_phone
                            ? `+${singleCustomerDetailData.secondary_country_code} ${singleCustomerDetailData.secondary_phone}`
                            : "-"}
                        </span>
                      </p>
                    </div>
                  </div>
                </Col>

                <Col span={12} className="pl-4">
                  <div className="flex flex-col">
                    <p className="text-[15px] font-monaSansMedium mt-2">
                      <FiMail className="inline-block mr-2 text-[#7C7C7C]" />
                      Email:
                      <span className="text-[#7C7C7C] font-monaSansRegular ml-2">
                        {singleCustomerDetailData.email ?? "-"}
                      </span>
                    </p>
                    <p className="text-[15px] font-monaSansMedium mt-2">
                      <FiPhone className="inline-block mr-2 text-[#7C7C7C]" />
                      Mobile Number:
                      <span className="text-[#7C7C7C] ml-2 font-monaSansRegular">
                        {singleCustomerDetailData.phone
                          ? `+${singleCustomerDetailData.country_code} ${singleCustomerDetailData.phone}`
                          : "-"}
                      </span>
                    </p>
                    <p className="text-[15px] font-monaSansMedium mt-2">
                      <FiCreditCard className="inline-block mr-2 text-[#7C7C7C]" />
                      TIN Number:
                      <span className="text-[#7C7C7C] ml-2 font-monaSansRegular">
                        {singleCustomerDetailData.tin_number ?? "-"}
                      </span>
                    </p>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Card>

        <div className="mt-5 w-full collaps-bg">
          {/* <Card className="border-[#DDDAE9] p-2"> */}
          <Collapse
            onChange={(key) => setActiveKey(key)}
            // className="company-detail-collapse custom-collapse"
            className={`company-detail-collapse custom-collapse ${
              activeKey.length === 0 ? "collapsed" : "expanded"
            }`}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined
                style={{ color: "#000000" }}
                rotate={isActive ? 90 : 0}
              />
            )}
            defaultActiveKey={activeKey}
          >
            <Collapse.Panel
              key="1"
              header={
                <p
                  style={{
                    color: "#000000",
                    fontWeight: 600,
                    letterSpacing: 0.5,
                  }}
                >
                  Customer Addresses
                </p>
              }
            >
              {/* Map through customer addresses */}
              {singleCustomerDetailData?.customers_address_master?.map(
                (address, index) => (
                  <Card
                    key={address.address_master_id}
                    className={`border-[#DDDAE9] collapsed-card p-2.5  ${
                      index ===
                      singleCustomerDetailData.customers_address_master.length -
                        1
                        ? ""
                        : "mb-[1.5em]"
                    }  `}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <div className="mb-3">
                          <p className="font-monaSansMedium text-[15px]">
                            Address Type:
                          </p>
                          <span className="text-[#7C7C7C] font-monaSansRegular">
                            {address.address_type_master?.address_type ?? "-"}
                          </span>
                        </div>
                        <div className="mb-3">
                          <p className="font-monaSansMedium text-[15px]">
                            Address Line 2:
                          </p>
                          <span className="text-[#7C7C7C] font-monaSansRegular">
                            {address.address_line_2 ?? "-"}
                          </span>
                        </div>
                        <div className="mb-3">
                          <p className="font-monaSansMedium text-[15px]">
                            State:
                          </p>
                          <span className="text-[#7C7C7C] font-monaSansRegular">
                            {address.states?.name ?? "-"}
                          </span>
                        </div>
                        <div className="mb-3">
                          <p className="font-monaSansMedium text-[15px]">
                            Country:
                          </p>
                          <span className="text-[#7C7C7C] font-monaSansRegular">
                            {address.country?.name ?? "-"}
                          </span>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="mb-3">
                          <p className="font-monaSansMedium text-[15px]">
                            Address Line 1:
                          </p>
                          <span className="text-[#7C7C7C] font-monaSansRegular">
                            {address.address_line_1 ?? "-"}
                          </span>
                        </div>
                        <div className="mb-3">
                          <p className="font-monaSansMedium text-[15px]">
                            City / District:
                          </p>
                          <span className="text-[#7C7C7C] font-monaSansRegular">
                            {address.city_district ?? "-"}
                          </span>
                        </div>
                        <div className="mb-3">
                          <p className="font-monaSansMedium text-[15px]">
                            Postal Code / Zip Code:
                          </p>
                          <span className="text-[#7C7C7C] font-monaSansRegular">
                            {address.pin_code ?? "-"}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                )
              )}
            </Collapse.Panel>
          </Collapse>
          {/* </Card>- */}
        </div>

        <div className="mt-5 w-full collaps-bg">
          {/* <Card className="border-[#DDDAE9] p-2"> */}
          <Collapse
            onChange={(key) => setActiveQuoatationKey(key)}
            className={`company-detail-collapse custom-collapse ${
              activeKey.length === 0 ? "collapsed" : "expanded"
            }`}
            // className="company-detail-collapse"
            expandIcon={({ isActive }) => (
              <CaretRightOutlined
                style={{ color: "#000000" }}
                rotate={isActive ? 90 : 0}
              />
            )}
            defaultActiveKey={activeQuotationKey}
            // expandIconPosition="right "
          >
            <Collapse.Panel
              key="1"
              header={
                <p
                  style={{
                    color: "#000000",
                    fontWeight: 600,
                    letterSpacing: 0.5,
                  }}
                >
                  Quotation
                </p>
              }
            >
              <div>
                <Table
                  className="rounded-[5px] border"
                  columns={columns}
                  pagination={false}
                  bordered
                  dataSource={singleCustomerDetailData.quotation}
                  // virtual
                  scroll={{
                    x: "max-content",
                    y: window.innerWidth < 1500 ? "49vh" : "60vh",
                  }}
                />
              </div>
            </Collapse.Panel>
          </Collapse>
          {/* </Card>- */}
        </div>
      </Spin>
    </>
  );
};

export default ViewCustomer;
