import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import { toast } from "sonner";
import axios from "axios";
import { handleGetResponse } from "./commonService";
import { handleDeleteResponse } from "./commonService";
import { handleErrorMessage } from "../../components/helper/common-function";
import { authMe } from "./authService";

//get all role
export const actionGetRoleMaster = createAsyncThunk(
  "actionGetRoleMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `role?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//get single role
export const actionGetSingleRoleMaster = createAsyncThunk(
  "actionGetSingleRoleMaster",
  (id) => {
    return handleGetResponse(`role/${id}`);
  }
);

//add role
export const actionAddRoleMaster = createAsyncThunk(
  "actionAddRoleMaster",
  async ({ values, handleCancel, form }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/role`, values);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            address_type_master_id: data?.address_type_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// delete role
export const actionDeleteRoleMaster = createAsyncThunk(
  "actionDeleteRoleMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`role/${id}`, id, setEditingKey);
  }
);

// update role
export const actionEditRoleMaster = createAsyncThunk(
  "actionEditRoleMaster",
  async ({ id, req, setEditingKey, setEdited }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/role/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionAssignRoleProfilePermission = createAsyncThunk(
  "actionAssignRoleProfilePermission",
  async ({ req, setUnsavedChanges }, { rejectWithValue,dispatch }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/role/assign-role-profile-permissions`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
       dispatch(authMe());
        setUnsavedChanges && setUnsavedChanges(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
