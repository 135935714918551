import { createSlice } from "@reduxjs/toolkit";
import {
  actionAddStateMaster,
  actionDeleteStateMaster,
  actionGetSingleStateMaster,
  actionEditStateMaster,
  actionGetStates,
  actionAllStatesIndex,
  actionGetAllStates,
} from "../services/stateMasterService";

const stateMasterSlice = createSlice({
  name: "stateMaster",
  initialState: {
    getStateMasterLoader: false,
    allStatesCount: 0,
    addStateMasterLoader: false,
    deleteStateMasterLoader: {},
    updateStateMasterLoader: false,
    getSingleStateMasterData: {},
    getSingleStateMasterLoader: {},
    getStatesLoader: false,
    statesData: [],
    getAllStatesIndexLoader: false,
    allStatesIndexData: {},
    getAllStatesLoader: false,
    allStatesData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //add address type
      .addCase(actionAddStateMaster.pending, (state) => {
        state.addStateMasterLoader = true;
      })
      .addCase(actionAddStateMaster.fulfilled, (state, action) => {
        state.addStateMasterLoader = false;
        if (action.payload) {
          if (!state.statesData || !Array.isArray(state.statesData)) {
            state.statesData = [action.payload];
          } else {
            state.statesData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddStateMaster.rejected, (state) => {
        state.addStateMasterLoader = false;
      })

      //delete address master
      .addCase(actionDeleteStateMaster.pending, (state, action) => {
        state.deleteStateMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteStateMaster.fulfilled, (state, action) => {
        state.statesData = state.statesData.filter(
          (type) => type.id !== action.payload
        );
        state.deleteStateMasterLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteStateMaster.rejected, (state, action) => {
        state.deleteStateMasterLoader[action.meta.arg.id] = false;
      })

      //get single address type
      .addCase(actionGetSingleStateMaster.pending, (state) => {
        state.getSingleStateMasterLoader = true;
      })
      .addCase(actionGetSingleStateMaster.fulfilled, (state, action) => {
        state.getSingleStateMasterData = action.payload.data;
        state.getSingleStateMasterLoader = false;
      })
      .addCase(actionGetSingleStateMaster.rejected, (state) => {
        state.getSingleStateMasterLoader = false;
      })

      .addCase(actionEditStateMaster.pending, (state) => {
        state.updateStateMasterLoader = true;
      })
      .addCase(actionEditStateMaster.fulfilled, (state, action) => {
        state.updateStateMasterLoader = false;
        state.statesData = state.statesData.map((data) =>
          data.id === action.payload.id ? { ...data, ...action.payload } : data
        );
      })

      .addCase(actionEditStateMaster.rejected, (state) => {
        state.updateStateMasterLoader = false;
      })

      //actionGetStates
      .addCase(actionGetStates.pending, (state) => {
        state.getStatesLoader = true;
      })
      .addCase(actionGetStates.fulfilled, (state, action) => {
        state.getStatesLoader = false;
        state.statesData = action.payload.data;
        state.allStatesCount = action.payload.total_records;
      })
      .addCase(actionGetStates.rejected, (state) => {
        state.getStatesLoader = false;
      })

      //actionGetStates all
      .addCase(actionGetAllStates.pending, (state) => {
        state.getAllStatesLoader = true;
      })
      .addCase(actionGetAllStates.fulfilled, (state, action) => {
        state.getAllStatesLoader = false;
        state.allStatesData = action.payload.data;
      })
      .addCase(actionGetAllStates.rejected, (state) => {
        state.getAllStatesLoader = false;
      })

      .addCase(actionAllStatesIndex.pending, (state) => {
        state.getAllStatesIndexLoader = true;
      })
      .addCase(actionAllStatesIndex.fulfilled, (state, action) => {
        state.allStatesIndexData = {
          ...state.allStatesIndexData,
          [action.meta.arg.index]: action.payload.data,
        };
        state.getAllStatesIndexLoader = false;
      })
      .addCase(actionAllStatesIndex.rejected, (state) => {
        state.getAllStatesIndexLoader = false;
      });
  },
});
export default stateMasterSlice.reducer;
