import { Button, Card, Form, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actionChangeOwnPassword,
  actionChangePassword,
} from "../../store/services/authService";
import { useLocation, useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [changesPasswordForm] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const changeStatus = useSelector((state) => state.auth);
  const { userId } = location.state || {};

  const onFinish = (values) => {
    userId
      ? dispatch(actionChangePassword({ values, id: userId, navigate }))
      : dispatch(actionChangeOwnPassword({ request: values, navigate }));
  };

  return (
    <div className="flex justify-center items-center">
      <Card className="w-[50%] md:w-[45%] lg:w-[40%]">
        <div className="flex items-center flex-col mb-2">
          <p className="text-black text-[22px] md:text-[25px] lg:text-[28px] mb-5 font-monaSansMedium">
            Change Password
          </p>
        </div>
        <Form
          form={changesPasswordForm}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            name={userId ? "password" : "old_password"}
            label="Current Password"
            rules={[
              {
                required: true,
                message: "Please input your current password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Current Password"
              className="py-[10px]"
            />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="new_password"
            style={{ marginBottom: 15 }}
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
              {
                min: 8,
                message:
                  "passwords must be longer than or equal to 8 characters !",
              },
              {
                max: 20,
                message: "Password cannot exceed 20 characters!",
              },
            ]}
          >
            <Input.Password placeholder="New Password" className="py-[10px]" />
          </Form.Item>
          <Form.Item>
            <Button
              loading={changeStatus?.changeOwnPasswordLoader}
              type="primary"
              className="root-btn text-[20px] mt-3 lg:mt-5 md:text-base text-sm border-none font-popinsMedium tracking-wider min-h-[45px]"
              block
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ChangePassword;
