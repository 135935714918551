import { Avatar, Card, Col, List, Row, Spin, Empty } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import productDashboard from "../../assets/img/productDashboard.svg";
import quotationDashboard from "../../assets/img/quotationDashboard.svg";
import userDashboard from "../../assets/img/userDashboard.svg";
import { actionGetDashboardData } from "../../store/services/authService";
import { selectHasPermission } from "../../store/slices/authSlice";

// Define the color map
const colorMap = {
  Draft: "#BCBCBC",
  Approved: "#28A745",
  Sent: "#17A2B8",
  "Awaiting Customer Response": "#FFD700",
  Accepted: "#218838",
  Rejected: "#DC3545",
  "Under Negotiation": "#FF6F07",
  Expired: "#6C757D",
  Canceled: "#FF6347",
  "On Hold": "#6F42C1",
  Pending: "#FFA500", // Added a color for Pending status
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const { dashboardData, getDashboardDataLoader } = useSelector(
    (state) => state.auth
  );
  const usersCount = dashboardData?.total_user || 0;
  const productsCount = dashboardData?.total_materials || 0;
  const quotationsCount = dashboardData?.total_quotation || 0;
  const customersCount = dashboardData?.total_customers || 0;
  const hasPermission = useSelector(selectHasPermission);

  useEffect(() => {
    dispatch(actionGetDashboardData());
  }, [dispatch]);

  const approvedData =
    dashboardData?.quotation_trend_chart?.map((item) => item.data) || [];

  const materialData = dashboardData?.material || [];

  const monthAbbreviations = {
    January: "Jan",
    February: "Feb",
    March: "Mar",
    April: "Apr",
    May: "May",
    June: "Jun",
    July: "Jul",
    August: "Aug",
    September: "Sep",
    October: "Oct",
    November: "Nov",
    December: "Dec",
  };

  const chartOptions = {
    chart: {
      type: "pie",
    },
    title: {
      text: "Quotation Status",
      align: "center",
      style: {
        fontFamily: "Mona-Sans-SemiBold",
        fontSize: "20px",
      },
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
    },
    legend: {
      width: "100%",
      layout: "horizontal",
      align: "right",
      verticalAlign: "bottom",
      symbolRadius: "50%",
      itemMarginTop: 8,
      itemDistance: 50,
      itemStyle: {
        fontSize: "11px",
        fontWeight: "600",
      },
      labelFormatter: function () {
        return `${this.name} &nbsp;&nbsp;&nbsp;${this.y}`;
      },
    },
    plotOptions: {
      pie: {
        innerSize: "75%",
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
          distance: -30,
          format: "{point.y}",
          style: {
            fontSize: "14px",
          },
        },
        showInLegend: true,
        states: {
          hover: {
            brightness: 0,
          },
        },
      },
    },
    series: [
      {
        name: "Status",
        colorByPoint: true,
        data:
          dashboardData &&
          dashboardData.quotation_status &&
          dashboardData.quotation_status.length > 0
            ? dashboardData?.quotation_status
                .map((item) => ({
                  name: item.quotation_status,
                  y: item.total_quotation_status,
                  color: colorMap[item.quotation_status] || "#000000",
                }))
                .filter((item) => item.y > 0)
            : [],
      },
    ],
  };

  // Your chart configuration remains the same
  const chartOptionsBar = {
    chart: {
      type: "line",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
    title: {
      text: "Quotation Trends Over Time",
      align: "left",
      style: {
        fontFamily: "Mona-Sans-SemiBold",
        fontSize: "20px",
      },
      margin: 20,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories:
        dashboardData?.quotation_trend_chart?.map(
          (item) => monthAbbreviations[item.month] || item.month
        ) || [],
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      symbolRadius: 6,
    },
    plotOptions: {
      area: {
        stacking: "normal",
        marker: {
          enabled: true,
          radius: 5,
        },
        fillOpacity: 0.1,
      },
    },
    tooltip: {
      enabled: true,
    },
    series: [
      {
        name: "Accepted",
        data: approvedData,
        color: "#28A745",
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, Highcharts.color("#28A745").setOpacity(0.2).get("rgba")],
            [1, Highcharts.color("#28A745").setOpacity(0).get("rgba")],
          ],
        },
        type: "area",
        marker: {
          symbol: "circle",
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "left",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  <Card
    bordered={false}
    className="border border-[#2B252633] px-6 py-3 rounded-[10px] bg-white mt-4 shadow-sm custom-card"
    style={{ borderRadius: "10px" }}
  >
    {approvedData && approvedData.length > 0 ? ( // Check if data exists
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptionsBar}
        containerProps={{ style: { height: "550px", width: "100%" } }}
      />
    ) : (
      <Empty description="No data found" /> 
    )}
  </Card>;

  return (
    <Spin spinning={getDashboardDataLoader}>
      <div className="text-[30px] font-monaSansSemiBold">
        <Row gutter={[16, 24]} justify="space-between">
          <Col xs={24} sm={12} md={12} lg={12} xl={6}>
            {hasPermission("quotation", "read") ? (
              <Link to="/quotations">
                <div className="border border-[#2B252633] px-6 py-3 rounded-[10px] bg-white flex justify-between items-center shadow-sm">
                  <div>
                    <h1 className="font-monaSansMedium text-[14px] text-[#8E8A8B]">
                      Total Quotation
                    </h1>
                    <p className="pt-2 text-[30px] font-monaSansSemiBold text-[#2B2526]">
                      {quotationsCount}
                    </p>
                  </div>
                  <img src={quotationDashboard} alt="Quotation Dashboard" />
                </div>
              </Link>
            ) : (
              <div className="border border-[#2B252633] px-6 py-3 rounded-[10px] bg-white flex justify-between items-center shadow-sm cursor-not-allowed">
                <div>
                  <h1 className="font-monaSansMedium text-[14px] text-[#8E8A8B]">
                    Total Quotation
                  </h1>
                  <p className="pt-2 text-[30px] font-monaSansSemiBold text-[#2B2526]">
                    {quotationsCount}
                  </p>
                </div>
                <img src={quotationDashboard} alt="Quotation Dashboard" />
              </div>
            )}
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} xl={6}>
            {hasPermission("users", "read") ? (
              <Link to="/users">
                <div className="border px-6 py-3 transition-all border-[#2B252633] rounded-[10px] bg-white flex justify-between items-center shadow-sm">
                  <div>
                    <h1 className="font-monaSansMedium text-[14px] text-[#8E8A8B]">
                      Total User
                    </h1>
                    <p className="pt-2 text-[30px] font-monaSansSemiBold text-[#2B2526]">
                      {usersCount}
                    </p>
                  </div>
                  <img src={userDashboard} alt="User Dashboard" />
                </div>
              </Link>
            ) : (
              <div className="border px-6 py-3 border-[#2B252633] rounded-[10px] bg-white flex justify-between items-center shadow-sm cursor-not-allowed">
                <div>
                  <h1 className="font-monaSansMedium text-[14px] text-[#8E8A8B]">
                    Total User
                  </h1>
                  <p className="pt-2 text-[30px] font-monaSansSemiBold text-[#2B2526]">
                    {usersCount}
                  </p>
                </div>
                <img src={userDashboard} alt="User Dashboard" />
              </div>
            )}
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} xl={6}>
            {hasPermission("material", "read") ? (
              <Link to="/products">
                <div className="border px-6 py-3 border-[#2B252633] rounded-[10px] bg-white flex justify-between items-center shadow-sm">
                  <div>
                    <h1 className="font-monaSansMedium text-[14px] text-[#8E8A8B]">
                      Total Products
                    </h1>
                    <p className="pt-2 text-[30px] font-monaSansSemiBold text-[#2B2526]">
                      {productsCount}
                    </p>
                  </div>
                  <img src={productDashboard} alt="Product Dashboard" />
                </div>
              </Link>
            ) : (
              <div className="border px-6 py-3 border-[#2B252633] rounded-[10px] bg-white flex justify-between items-center shadow-sm cursor-not-allowed">
                <div>
                  <h1 className="font-monaSansMedium text-[14px] text-[#8E8A8B]">
                    Total Products
                  </h1>
                  <p className="pt-2 text-[30px] font-monaSansSemiBold text-[#2B2526]">
                    {productsCount}
                  </p>
                </div>
                <img src={productDashboard} alt="Product Dashboard" />
              </div>
            )}
          </Col>

          <Col xs={24} sm={12} md={12} lg={12} xl={6}>
            {hasPermission("customers", "read") ? (
              <Link to="/customers">
                <div className="border px-6 py-3 rounded-[10px] border-[#2B252633] bg-white flex justify-between items-center shadow-sm">
                  <div>
                    <h1 className="font-monaSansMedium text-[14px] text-[#8E8A8B]">
                      Total Customer
                    </h1>
                    <p className="pt-2 text-[30px] font-monaSansSemiBold text-[#2B2526]">
                      {customersCount}
                    </p>
                  </div>
                  <img src={userDashboard} alt="Customer Dashboard" />
                </div>
              </Link>
            ) : (
              <div className="border px-6 py-3 rounded-[10px] border-[#2B252633] bg-white flex justify-between items-center shadow-sm cursor-not-allowed">
                <div>
                  <h1 className="font-monaSansMedium text-[14px] text-[#8E8A8B]">
                    Total Customer
                  </h1>
                  <p className="pt-2 text-[30px] font-monaSansSemiBold text-[#2B2526]">
                    {customersCount}
                  </p>
                </div>
                <img src={userDashboard} alt="Customer Dashboard" />
              </div>
            )}
          </Col>
        </Row>
      </div>
      <div>
        <Row gutter={[16, 16]}>
          <Col xxl={6} xl={8} lg={10} md={12} sm={24} xs={24}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="border border-[#2B252633] px-6 py-3 rounded-[10px] bg-white mt-4 shadow-sm"
            >
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                containerProps={{ style: { height: "550px" } }}
              />
            </div>
          </Col>

          <Col xxl={18} xl={16} lg={14} md={12} sm={24} xs={24}>
            <Card
              bordered={false}
              className="border border-[#2B252633] px-6 py-3 rounded-[10px] bg-white mt-4 shadow-sm custom-card"
              style={{ borderRadius: "10px" }}
            >
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptionsBar}
                containerProps={{ style: { height: "550px", width: "100%" } }}
              />
            </Card>
          </Col>

          {/* thired sec */}
          <Col span={9}>
            <div className="border border-[#2B252633] px-6 py-3 rounded-[10px] bg-white shadow-sm">
              {/* Static title */}
              <h1 className="text-[#2B2526] font-monaSansSemiBold text-[20px] mb-4">
                High Selling Products
              </h1>

              {/* Scrollable list container */}
              <div
                className="custom-scrollbar"
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  paddingRight: "8px",
                }}
              >
                <List
                  dataSource={materialData}
                  renderItem={(item, index) => (
                    <List.Item
                      key={item.material_name}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "14px 0",
                        borderBottom:
                          index < materialData.length - 1
                            ? "1px solid rgba(140, 140, 140, 0.35)"
                            : "none",
                      }}
                    >
                      {/* Meta section: Avatar (image) and Title (name) */}
                      <List.Item.Meta
                        className="flex !items-center product-list-meta"
                        // avatar={<Avatar src={item.material_image}/>}
                        avatar={
                          <Avatar
                            src={item.material_image}
                            className="border border-[#C9C9C9] rounded-[5px]"
                            size="large"
                          />
                        }
                        title={
                          <span className="text-[16px] !mb-0 font-monaSansMedium">
                            {item.material_name}
                          </span>
                        }
                      />
                      {/* Display material total */}
                      <div className="text-[16px] font-monaSansMedium">
                        {item.material_total}
                      </div>
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default Dashboard;
