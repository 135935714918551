import { createSelector, createSlice } from "@reduxjs/toolkit";
import { getLocalAccessToken } from "../../utils/TokenService";
import {
  actionChangeOwnPassword,
  actionChangePassword,
  actionForgotPassword,
  actionGetDashboardData,
  actionLogin,
  actionResetPassword,
  authMe,
} from "../services/authService";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loginLoader: false,
    forgotPasswordLoader: false,
    resetPasswordLoader: false,
    changeOwnPasswordLoader: false,
    authMeLoader: false,
    changePasswordLoader: false,
    authData: "",
    getDashboardDataLoader: false,
    dashboardData: {},
  },
  reducers: {
    updateMyCurrency: (state, action) => {
      console.log(action.payload)
      state.authData = { ...state.authData, company: action.payload };
      state.myCurrency =
        action.payload?.currency?.currency?.currency || "RFW";
    },
  },
  extraReducers: (builder) => {
    builder
      // Login State
      .addCase(actionLogin.pending, (state) => {
        state.loginLoader = true;
      })
      .addCase(actionLogin.fulfilled, (state, action) => {
        state.loginLoader = false;
      })
      .addCase(actionLogin.rejected, (state) => {
        state.loginLoader = false;
      })

      //forgot password
      .addCase(actionForgotPassword.pending, (state) => {
        state.forgotPasswordLoader = true;
      })
      .addCase(actionForgotPassword.fulfilled, (state, action) => {
        state.forgotPasswordLoader = false;
      })
      .addCase(actionForgotPassword.rejected, (state) => {
        state.forgotPasswordLoader = false;
      })

      //resetpassword
      .addCase(actionResetPassword.pending, (state) => {
        state.resetPasswordLoader = true;
      })
      .addCase(actionResetPassword.fulfilled, (state, action) => {
        state.resetPasswordLoader = false;
      })
      .addCase(actionResetPassword.rejected, (state) => {
        state.resetPasswordLoader = false;
      })

      //change own password
      .addCase(actionChangeOwnPassword.pending, (state) => {
        state.changeOwnPasswordLoader = true;
      })
      .addCase(actionChangeOwnPassword.fulfilled, (state, action) => {
        state.changeOwnPasswordLoader = false;
      })
      .addCase(actionChangeOwnPassword.rejected, (state) => {
        state.changeOwnPasswordLoader = false;
      })

      // Auth me State
      .addCase(authMe.pending, (state) => {
        state.authMeLoader = true;
      })
      .addCase(authMe.fulfilled, (state, action) => {
        const { payload } = action;
        state.authData = payload;
        state.authMeLoader = false;
        state.myCurrency = payload.company?.currency?.currency?.currency
          ? payload.company.currency.currency.currency
          : "RWF";
      })

      .addCase(authMe.rejected, (state) => {
        state.authMeLoader = false;
      })

      //change own password
      .addCase(actionChangePassword.pending, (state) => {
        state.changePasswordLoader = true;
      })
      .addCase(actionChangePassword.fulfilled, (state, action) => {
        state.changePasswordLoader = false;
      })
      .addCase(actionChangePassword.rejected, (state) => {
        state.changePasswordLoader = false;
      })

      //  get dashboard data
      .addCase(actionGetDashboardData.pending, (state) => {
        state.getDashboardDataLoader = true;
      })
      .addCase(actionGetDashboardData.fulfilled, (state, action) => {
        state.getDashboardDataLoader = false;
        state.dashboardData = action.payload;
      })
      .addCase(actionGetDashboardData.rejected, (state) => {
        state.getDashboardDataLoader = false;
      });
  },
});

export const selectHasPermission = createSelector(
  (state) => state?.auth?.authData,
  (authData) => (resource, action) => {
    return authData?.role?.permissions?.some(
      (permission) =>
        permission?.permissions?.resources === resource &&
        permission?.permissions?.actions === action
    );
  }
);

export const { updateMyCurrency } = authSlice.actions;

export default authSlice.reducer;
