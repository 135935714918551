import { createSlice } from "@reduxjs/toolkit";
import {
  actionGetQuotations,
  actionAddQuotation,
  actionDeleteQuotation,
  actionEditQuotation,
  actionGetSingleQuotation,
  actionGetQuotationInvoiceData,
  actionGetQuotationDetails,
  actionQuotationSendEmail,
  actionAddRequestQuotation,
  actionGetRequestForQuotation,
  actionGetSingleRequestQuotation,
  actionEditRequestQuotation,
  actionDeleteRequestForQuotation,
} from "../services/quotationService";

const quotationSlice = createSlice({
  name: "quotations",
  initialState: {
    getQuotationsLoader: false,
    addQuotationLoader: false,
    deleteQuotationLoader: false,
    updateQuotationLoader: false,
    getSingleQuotationData: {},
    getSingleQuotationLoader: false,
    allQuotationsCount: 0,
    quotations: [],
    getQuotationInvoiceDataLoader: false,
    quotationInvoiceDataData: "",
    getQuotationDetailsData: {},
    getQuotationDetailsLoader: false,
    quotationSendEmailLoader: false,
    addRequestQuotationLoader: false,
    //req for quotation
    getRequestForQuotationLoader: false,
    requestForQuotationData: [],
    requestForQuotationDataCount: 0,
    getRequestQuotationDetailsData: {},
    getRequestQuotationDetailsLoader: false,
    requestForQuotationData: false,
    deleteRequestForQuotationLoader: false,
    updateRequestQuotationLoader: false,

  },
  reducers: {},
  reducers: {},
  extraReducers: (builder) => {
    builder
      //get all quotations
      .addCase(actionGetQuotations.pending, (state) => {
        state.getQuotationsLoader = true;
      })
      .addCase(actionGetQuotations.fulfilled, (state, action) => {
        state.getQuotationsLoader = false;
        state.quotations = action.payload.data;
        state.allQuotationsCount = action.payload.total_records;
      })
      .addCase(actionGetQuotations.rejected, (state) => {
        state.getQuotationsLoader = false;
      })

      //add quotation
      .addCase(actionAddQuotation.pending, (state) => {
        state.addQuotationLoader = true;
      })
      .addCase(actionAddQuotation.fulfilled, (state, action) => {
        state.addQuotationLoader = false;
        if (!state.quotations || !Array.isArray(state.quotations)) {
          state.quotations = [action.payload];
          state.allQuotationsCount = 1;
        } else {
          state.quotations.unshift(action.payload);
          state.allQuotationsCount = state.allQuotationsCount + 1;
        }
      })
      .addCase(actionAddQuotation.rejected, (state) => {
        state.addQuotationLoader = false;
      })

      //delete quotation
      .addCase(actionDeleteQuotation.pending, (state, action) => {
        state.deleteQuotationLoader = true;
      })
      .addCase(actionDeleteQuotation.fulfilled, (state, action) => {
        state.quotations = state.quotations.filter(
          (quotation) => quotation.id !== action.payload
        );
        state.allQuotationsCount = state.allQuotationsCount - 1;
        state.deleteQuotationLoader = false;
      })
      .addCase(actionDeleteQuotation.rejected, (state, action) => {
        state.deleteQuotationLoader = false;
      })

      //get single quotation
      .addCase(actionGetSingleQuotation.pending, (state) => {
        state.getSingleQuotationLoader = true;
      })
      .addCase(actionGetSingleQuotation.fulfilled, (state, action) => {
        state.getSingleQuotationData = action.payload.data;
        state.getSingleQuotationLoader = false;
      })
      .addCase(actionGetSingleQuotation.rejected, (state) => {
        state.getSingleQuotationLoader = false;
      })

      //update user
      .addCase(actionEditQuotation.pending, (state) => {
        state.updateQuotationLoader = true;
      })
      .addCase(actionEditQuotation.fulfilled, (state, action) => {
        state.updateQuotationLoader = false;
      })
      .addCase(actionEditQuotation.rejected, (state) => {
        state.updateQuotationLoader = false;
      })

      //get Quotation invoice data
      .addCase(actionGetQuotationInvoiceData.pending, (state) => {
        state.getQuotationInvoiceDataLoader = true;
      })
      .addCase(actionGetQuotationInvoiceData.fulfilled, (state, action) => {
        state.quotationInvoiceDataData = action.payload;
        state.getQuotationInvoiceDataLoader = false;
      })
      .addCase(actionGetQuotationInvoiceData.rejected, (state) => {
        state.getQuotationInvoiceDataLoader = false;
      })

      //get quotation details
      .addCase(actionGetQuotationDetails.pending, (state) => {
        state.getQuotationDetailsLoader = true;
      })
      .addCase(actionGetQuotationDetails.fulfilled, (state, action) => {
        state.getQuotationDetailsData = action.payload.data;
        state.getQuotationDetailsLoader = false;
      })
      .addCase(actionGetQuotationDetails.rejected, (state) => {
        state.getQuotationDetailsLoader = false;
      })

      .addCase(actionQuotationSendEmail.pending, (state) => {
        state.quotationSendEmailLoader = true;
      })
      .addCase(actionQuotationSendEmail.fulfilled, (state) => {
        state.quotationSendEmailLoader = false;
      })
      .addCase(actionQuotationSendEmail.rejected, (state) => {
        state.quotationSendEmailLoader = false;
      })

      .addCase(actionAddRequestQuotation.pending, (state) => {
        state.addQuotationLoader = true;
      })
      .addCase(actionAddRequestQuotation.fulfilled, (state, action) => {
        state.addQuotationLoader = false;
        if (!state.requestForQuotationData || !Array.isArray(state.requestForQuotationData)) {
          state.requestForQuotationData = [action.payload];
          state.requestForQuotationDataCount = 1;
        } else {
          state.requestForQuotationData.unshift(action.payload);
          state.requestForQuotationDataCount = state.requestForQuotationDataCount + 1;
        }
      })
      .addCase(actionAddRequestQuotation.rejected, (state) => {
        state.addQuotationLoader = false;
      })

      //get all req for quotation
      .addCase(actionGetRequestForQuotation.pending, (state) => {
        state.getRequestForQuotationLoader = true;
      })
      .addCase(actionGetRequestForQuotation.fulfilled, (state, action) => {
        state.getRequestForQuotationLoader = false;
        state.requestForQuotationData = action.payload.data;
        state.requestForQuotationDataCount = action.payload.total_records;
      })

      .addCase(actionGetRequestForQuotation.rejected, (state) => {
        state.getRequestForQuotationLoader = false;
      })
      .addCase(actionGetSingleRequestQuotation.pending, (state) => {
        state.getRequestQuotationDetailsLoader = true;
      })
      .addCase(actionGetSingleRequestQuotation.fulfilled, (state, action) => {
        state.getRequestQuotationDetailsData = action.payload.data;
        state.getRequestQuotationDetailsLoader = false;
      })
      .addCase(actionGetSingleRequestQuotation.rejected, (state) => {
        state.getRequestQuotationDetailsLoader = false;
      })
      .addCase(actionEditRequestQuotation.pending, (state) => {
        state.updateRequestQuotationLoader = true;
      })
      .addCase(actionEditRequestQuotation.fulfilled, (state, action) => {
        state.updateRequestQuotationLoader = false;
        state.requestForQuotationData = state.requestForQuotationData.map((data) =>
          data.request_for_quotation_id === action.payload.request_for_quotation_id ? { ...data, ...action.payload } : data
        );
      })
      .addCase(actionEditRequestQuotation.rejected, (state) => {
        state.updateRequestQuotationLoader = false;
      })
      //delete requestQuotationDetails
      .addCase(actionDeleteRequestForQuotation.pending, (state) => {
        state.deleteRequestForQuotationLoader = true;
      })

      .addCase(actionDeleteRequestForQuotation.fulfilled, (state, action) => {
        state.deleteRequestForQuotationLoader = false;
        state.requestForQuotationData = state.requestForQuotationData.filter(
          (data) => data.request_for_quotation_id !== action.payload
        );
        state.requestForQuotationDataCount =
          state.requestForQuotationDataCount - 1;
      })

      .addCase(actionDeleteRequestForQuotation.rejected, (state) => {
        state.deleteRequestForQuotationLoader = false;
      });
  },
});

export default quotationSlice.reducer;
