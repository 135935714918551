import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import plusIcon from "../../assets/img/add-button.svg";
import deletemaster from "../../assets/img/deleteMaster.svg";
import editedMaster from "../../assets/img/editedMaster.svg";
import editMaster from "../../assets/img/editMaster.svg";
import CustomSearchInput from "../common/CustomSearchInput";
import ConfigProviderWrapper from "../common/ConfigProviderWrapper";
import SortIcon from "../common/SortIcon";
import {
  actionAddAddressTypeMaster,
  actionDeleteAddressTypeMaster,
  actionEditAddressTypeMaster,
  actionGetAddressTypeMaster,
  actionGetSingleAddressTypeMaster,
} from "../../store/services/addressTypeMasterService";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { isValidSearchString } from "../helper/common-function";

const AddressTypeMaster = () => {
  const [editableForm] = Form.useForm();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    addressTypeMasterData,
    getAddressTypeMasterLoader,
    addAddressTypeMasterLoader,
    deleteAddressTypeMasterLoader,
    updateAddressTypeMasterLoader,
    getSingleAddressTypeMasterData,
  } = useSelector((state) => state.addressTypeMaster);

  // Generating 10 items of dummy data
  const [search, setSearch] = useState("");
  const [edited, setEdited] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isEditing = (record) => record.address_type_master_id === editingKey;

  useEffect(() => {
    dispatch(actionGetAddressTypeMaster({ search }));
  }, []);

  //add
  const handleAddAddressTypeMethod = (values) => {
    const req = {
      ...values,
      description: values.description ? values.description : "",
    };
    dispatch(actionAddAddressTypeMaster({ values: req, handleCancel }));
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (isValidSearchString(value)) {
      setSearch(value);
      if (value === "") {
        dispatch(
          actionGetAddressTypeMaster({
            search: value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    const value = e.target.value;
    if (search && isValidSearchString(value)) {
      dispatch(
        actionGetAddressTypeMaster({
          search: value,
        })
      );
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetAddressTypeMaster({
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "select" ? <Select /> : <Input className="!text-[14px]" />;
    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: title === "Address Type" ? true : false,
                message: ``,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="table-cell-text"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(actionGetSingleAddressTypeMaster(record.address_type_master_id));
    setEdited(!edited);
  };

  useEffect(() => {
    if (getSingleAddressTypeMasterData && edited) {
      editableForm.setFieldsValue({
        address_type: getSingleAddressTypeMasterData.address_type,
        description: getSingleAddressTypeMasterData.description,
      });
      setEditingKey(getSingleAddressTypeMasterData.address_type_master_id);
    }
  }, [getSingleAddressTypeMasterData]);

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { address_type, description } = row;
      const req = { address_type, description };
      dispatch(
        actionEditAddressTypeMaster({ id, req, setEditingKey, setEdited })
      );
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Address Type",
      dataIndex: "address_type",
      key: "2",
      width: "22%",
      editable: true,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "3",
      width: "60%",
      editable: true,
      ellipsis: true,
      render: (data) => (data ? data : "-"),
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Action",
      key: "4",
      width: 100,
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);

        return (
          <div className="flex items-center">
            {editable ? (
              <div className="flex items-center">
                {updateAddressTypeMasterLoader[
                  record.address_type_master_id
                ] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link
                    onClick={() => save(record.address_type_master_id)}
                  >
                    <img src={editMaster} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                >
                  <img src={editedMaster} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              <Popconfirm
                okButtonProps={{
                  loading:
                    deleteAddressTypeMasterLoader[
                      record.address_type_master_id
                    ],
                }}
                description="Are you sure you want to delete?"
                onConfirm={() =>
                  dispatch(
                    actionDeleteAddressTypeMaster({
                      id: record.address_type_master_id,
                      setEditingKey,
                    })
                  )
                }
              >
                <img src={deletemaster} alt="" className="cursor-pointer" />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "address_type" ? "input" : "input",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div className="master-input">
      <div className="flex justify-between mt-[15px]">
        <h1 className="text-black text-[20px] font-monaSansMedium">
          Address Type
        </h1>
        <div>
          <Button
            onClick={showModal}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="PlusIcon Icon" preview={false} />}
          >
            Add Address Type
          </Button>
          <Modal
            className="input-custom"
            centered
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            footer={false}
            width={500}
            title=""
            open={isModalOpen}
          >
            <h1 className="text-black text-[20px]  mt-[5px] font-monaSansMedium">
              Address Type
            </h1>
            <Form
              form={form}
              onFinish={handleAddAddressTypeMethod}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Form.Item
                name="address_type"
                label="Address Type"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please input address type!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Address Type"
                  className=" bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: false,
                    message: "Please input description!",
                  },
                ]}
              >
                <Input.TextArea
                  rows={3}
                  style={{ background: "white" }}
                  placeholder="Enter Description"
                  className=" bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <div className="flex justify-center mt-[40px]">
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="cancel-btn  min-h-[40px] min-w-[100px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      type="primary"
                      loading={addAddressTypeMasterLoader}
                      className="root-btn min-h-[40px] min-w-[100px]"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
      <div className="form-table-design mt-[15px]">
        <div className="relative">
          <div className="search-bar">
            <CustomSearchInput
              handleSearch={handleSearch}
              handleBlurAndPressEnter={handleBlurAndPressEnter}
              searchValue={search}
            />
          </div>

          <div className="pt-3 pb-[75px]">
            <Form form={editableForm} component={false}>
              <ConfigProviderWrapper>
                <Table
                  bordered
                  onChange={handleSortChange}
                  className="ant-table-padding"
                  rowKey="address_type_master_id"
                  columns={mergedColumns}
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  scroll={{ y: 400 }}
                  loading={getAddressTypeMasterLoader}
                  dataSource={addressTypeMasterData}
                  pagination={false}
                  rowClassName="editable-row"
                />
              </ConfigProviderWrapper>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressTypeMaster;
