import { createSlice } from "@reduxjs/toolkit";
import {
  actionGetTaxMaster,
  actionAddTaxMaster,
  actionDeleteTaxMaster,
  actionEditTaxMaster,
  actionGetSingleUnitMaster,
  actionUpdateTaxStatusMaster,
} from "../services/taxMasterService";

const taxMasterSlice = createSlice({
  name: "taxMaster",
  initialState: {
    getTaxMasterLoader: false,
    addTaxtMasterLoader: false,
    deleteTaxMasterLoader: {},
    updateTaxMasterLoader: {},
    getSingleTaxMasterData: {},
    getSingleTaxMasterLoader: {},
    updateTaxStatusMasterLoader: {},
    taxMasterData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //get all address type master
      .addCase(actionGetTaxMaster.pending, (state) => {
        state.getTaxMasterLoader = true;
      })
      .addCase(actionGetTaxMaster.fulfilled, (state, action) => {
        state.getTaxMasterLoader = false;
        state.taxMasterData = action.payload.data;
      })
      .addCase(actionGetTaxMaster.rejected, (state) => {
        state.getTaxMasterLoader = false;
      })

      //add address type
      .addCase(actionAddTaxMaster.pending, (state) => {
        state.addTaxtMasterLoader = true;
      })
      .addCase(actionAddTaxMaster.fulfilled, (state, action) => {
        state.addTaxtMasterLoader = false;
        if (action.payload) {
          if (
            !state.taxMasterData ||
            !Array.isArray(state.taxMasterData)
          ) {
            state.taxMasterData = [action.payload];
          } else {
            state.taxMasterData.unshift(action.payload);
          }
        }
      })
      .addCase(actionAddTaxMaster.rejected, (state) => {
        state.addTaxtMasterLoader = false;
      })

      //delete address master
      .addCase(actionDeleteTaxMaster.pending, (state, action) => {
        state.deleteTaxMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteTaxMaster.fulfilled, (state, action) => {
        state.taxMasterData = state.taxMasterData.filter(
          (type) => type.tax_master_id !== action.payload
        );
        state.deleteTaxMasterLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteTaxMaster.rejected, (state, action) => {
        state.deleteTaxMasterLoader[action.meta.arg.id] = false;
      })

      //get single address type
      .addCase(actionGetSingleUnitMaster.pending, (state) => {
        state.getSingleTaxMasterLoader = true;
      })
      .addCase(actionGetSingleUnitMaster.fulfilled, (state, action) => {
        state.getSingleTaxMasterData = action.payload.data;
        state.getSingleTaxMasterLoader = false;
      })
      .addCase(actionGetSingleUnitMaster.rejected, (state) => {
        state.getSingleTaxMasterLoader = false;
      })

      //update address type
      .addCase(actionEditTaxMaster.pending, (state, action) => {
        state.updateTaxMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditTaxMaster.fulfilled, (state, action) => {
        state.updateTaxMasterLoader[action.meta.arg.id] = false;
        state.taxMasterData = state.taxMasterData.map((type) =>
          type.tax_master_id === action.payload.tax_master_id
            ? {
                ...type,
                name: action.payload.name,
                rate: action.payload.rate,
              }
            : type
        );
      })

      .addCase(actionUpdateTaxStatusMaster.rejected, (state, action) => {
        state.updateTaxStatusMasterLoader[action.meta.arg.id] = false;
      })
      .addCase(actionUpdateTaxStatusMaster.pending, (state, action) => {
        state.updateTaxStatusMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionUpdateTaxStatusMaster.fulfilled, (state, action) => {
        state.updateTaxStatusMasterLoader[action.meta.arg.id] = false;
        state.taxMasterData = state.taxMasterData.map((type) =>
          type.tax_master_id === action.payload.tax_master_id
            ? {
                ...type,
                status: action.payload.status,
              }
            : type
        );
      })
      .addCase(actionEditTaxMaster.rejected, (state, action) => {
        state.updateTaxMasterLoader[action.meta.arg.id] = false;
      });
  },
});
export default taxMasterSlice.reducer;
