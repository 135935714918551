import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  Modal,
  Pagination,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomSearchInput from "../common/CustomSearchInput";
import PageTitle from "../common/PageTitle";
import SortIcon from "../common/SortIcon";
import { getFirstLetterCapital } from "../helper/common-function";
import {
  actionDeleteCustomer,
  actionGetCustomers,
} from "../../store/services/customerService";
import { selectHasPermission } from "../../store/slices/authSlice";

const Customers = () => {
  const [search, setSearch] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [deleteCustomerModalOpen, setDeleteCustomerModalOpen] = useState(false);
  const offsetRef = useRef(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasPermission = useSelector(selectHasPermission);

  const {
    getCustomersLoader,
    deleteCustomerLoader,
    customers,
    customersCount,
  } = useSelector((state) => state.customer);

  const getApiCall = (
    offset = 0,
    search = "",
    orderByColumn = "",
    order = ""
  ) => {
    dispatch(
      actionGetCustomers({
        offset,
        orderByColumn,
        order,
        search,
      })
    );
  };

  useEffect(() => {
    getApiCall(offsetRef.current);
  }, []);

  const columns = [
    {
      title: "Name",
      key: "customer_name",
      width: 200,
      ellipsis: true,
      dataIndex: "customer_name",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      fixed: "left",
      render: (text, data) => (
        <div
          className="flex cursor-pointer text-blue-600 hover:text-blue-600 items-center "
          onClick={() => {
            setCustomerId(data.customers_id);
            navigate("/view-customer", {
              state: { id: data.customers_id },
            });
          }}
        >
          <Avatar
            style={{
              verticalAlign: "middle",
              borderRadius: "5px",
            }}
            src={data?.profile_pic?.url}
            size="large"
          />
          <p className="ml-2 text-brand">{text}</p>
        </div>
      ),
    },
    {
      title: "Email",
      key: "email",
      width: 200,
      dataIndex: "email",
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Secondary Email",
      key: "secondary_email",
      width: 200,
      dataIndex: "secondary_email",
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Mobile Number",
      key: "phone",
      width: 200,
      dataIndex: "phone",
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">
          {`+${data.country_code} ${data.phone}` || "-"}
        </span>
      ),
    },
    {
      title: "Secondary Mobile Number",
      key: "secondary_phone",
      width: 200,
      dataIndex: "secondary_phone",
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text, data) =>
        data &&
        data.secondary_country_code &&
        data.secondary_country_code !== null &&
        data.secondary_phone &&
        data.secondary_phone !== null ? (
          <span className="ml-2">
            {`+${data.secondary_country_code} ${data.secondary_phone}` || "-"}
          </span>
        ) : (
          "-"
        ),
    },
    {
      title: "TIN Number",
      key: "tin_number",
      width: 200,
      ellipsis: true,
      dataIndex: "tin_number",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "150px",
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown.Button
            id="custom-drop"
            disabled={
              !hasPermission("customers", "delete") &&
              !hasPermission("customers", "update")
            }
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () =>
                    navigate("/update-customer", {
                      state: { customerId: data.customers_id },
                    }),
                  disabled: hasPermission("customers", "update") ? false : true,
                },
                {
                  label: <span style={{ color: "#ff4d4f" }}>Delete</span>,
                  key: "Delete",
                  icon: (
                    <DeleteOutlined
                      style={{ fontSize: 12, color: "#ff4d4f" }}
                    />
                  ),
                  onClick: () => {
                    setDeleteCustomerModalOpen(true);
                    setCustomerId(data.customers_id);
                  },
                  disabled: hasPermission("customers", "delete") ? false : true,
                },
              ].filter(Boolean),
            }}
          >
            Action
          </Dropdown.Button>
        </>
      ),
    },
  ];

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      getApiCall(offsetRef.current, e.target.value);
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      getApiCall(offsetRef.current, e.target.value);
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    getApiCall(
      offsetRef.current,
      search,
      sorterParams.field,
      sorterParams.order
    );
  };

  const handleCloseCustomerDeleteModal = () => {
    setCustomerId("");
    setDeleteCustomerModalOpen(false);
  };

  return (
    <>
      <PageTitle
        title="Customers"
        buttonText="Add Customer"
        buttonLink="/create-customer"
        isShowButton={hasPermission("customers", "create")}
      />
      <CustomSearchInput
        handleSearch={handleSearch}
        handleBlurAndPressEnter={handleBlurAndPressEnter}
        searchValue={search}
      />
      <Table
        sticky
        rowKey="customers_id"
        scrollToFirstRowOnChange={true}
        sortDirections={["ascend", "descend"]}
        onChange={handleSortChange}
        bordered
        scroll={{
          x: "max-content",
          y: window.innerWidth < 1500 ? "49vh" : "61vh",
        }}
        pagination={false}
        columns={columns}
        dataSource={customers}
        loading={getCustomersLoader}
      />
      <div className="flex justify-end mt-3">
        <Pagination
          hideOnSinglePage
          responsive={true}
          current={offsetRef.current + 1}
          className="flex items-center"
          // showSizeChanger={true}
          total={customersCount}
          onChange={(e) => {
            offsetRef.current = e - 1;
            getApiCall(offsetRef.current, search);
          }}
        />
      </div>
      <Modal
        className="max-h-[100vh]"
        centered={true}
        footer={false}
        handleReset={handleCloseCustomerDeleteModal}
        afterClose={handleCloseCustomerDeleteModal}
        open={deleteCustomerModalOpen}
        title=""
        onCancel={handleCloseCustomerDeleteModal}
        onOk={handleCloseCustomerDeleteModal}
      >
        <p className="text-[19px] text-black font-monaSansMedium mt-2">
          Are you sure you want to remove this Customer ?
        </p>
        <div className=" flex justify-end mt-[25px] lg:mt-[30px]">
          <Button
            onClick={handleCloseCustomerDeleteModal}
            type="button"
            className="gray-button min-h-[45px] min-w-[110px]"
          >
            Cancel
          </Button>

          <div className="ml-4">
            <Button
              loading={deleteCustomerLoader}
              onClick={() => {
                dispatch(
                  actionDeleteCustomer({
                    id: customerId,
                    handleCloseCustomerDeleteModal,
                  })
                );
              }}
              className="delete-btn min-h-[45px] min-w-[110px]"
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Customers;
