import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import { toast } from "sonner";
import axios from "axios";
import { handleGetResponse } from "./commonService";
import { handleDeleteResponse } from "./commonService";
import { handleErrorMessage } from "../../components/helper/common-function";

// Action to get customer
export const actionGetCustomers = createAsyncThunk(
  "actionGetCustomers",
  async ({ offset, search, orderByColumn, order }, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}/customers?limit=10&offset=${offset}${
          search ? `&search=${search}` : ""
        }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
          order ? `&order=${order}` : ""
        }`
      );
      const { status, message: customMsg, data, total_records } = res.data;
      if (parseInt(status) === 200) {
        return { data, total_records };
      } else if (parseInt(status) === 404) {
        return "";
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to add Customer
export const actionAddCustomer = createAsyncThunk(
  "actionAddCustomer",
  async (
    { req, navigate, setIsModalOpen, customerForm, CreateCustomerForm },
    { rejectWithValue }
  ) => {
    try {
      const res = await axios.post(`${BASE_URL}/customers`, req);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        customerForm &&
          customerForm.setFieldsValue({
            customer_name: data.customers_id,
          });
        CreateCustomerForm && CreateCustomerForm.resetFields();
        setIsModalOpen && setIsModalOpen(false);
        navigate && navigate("/customers");
        return data;
      } else {
        toast.error(customMsg, 5);
        rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

// Action to get Single customer data
export const actionGetSingleCustomerDetail = createAsyncThunk(
  "actionGetSingleCustomerDetail",
  async (id) => {
    return handleGetResponse(`customers/${id}`);
  }
);

// Action to delete Customer
export const actionDeleteCustomer = createAsyncThunk(
  "actionDeleteCustomer",
  async ({ id, handleCloseCustomerDeleteModal }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${BASE_URL}/customers/${id}`);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCloseCustomerDeleteModal && handleCloseCustomerDeleteModal();
        return id;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionGetAllCustomers = createAsyncThunk(
  "actionGetAllCustomers",
  async () => {
    return handleGetResponse(`customers?getall=YES`);
  }
);

// Action to Update Customer
export const actionUpdateCustomer = createAsyncThunk(
  "actionUpdateCustomer",
  async ({ id, req, navigate }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BASE_URL}/customers/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/customers");
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
      return false;
    }
  }
);
