import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import axios from "axios";
import { toast } from "sonner";
import { handleDeleteResponse, handleGetResponse } from "./commonService";
import { handleErrorMessage } from "../../components/helper/common-function";

//get all listing of the Quotation status
export const actionGetQuotationStatusMaster = createAsyncThunk(
  "actionGetQuotationStatusMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `quotation-status-master?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//add Quotation status
export const actionAddQuotationStatusMaster = createAsyncThunk(
  "actionAddQuotationStatusMaster",
  async ({ values, handleCancel }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/quotation-status-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete Quotation status
export const actionDeleteQuotationStatusMaster = createAsyncThunk(
  "actionDeleteQuotationStatusMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(
      `quotation-status-master/${id}`,
      id,
      setEditingKey
    );
  }
);

//get single Quotation status
export const actionGetSingleQuotationStatusMaster = createAsyncThunk(
  "actionGetSingleQuotationStatusMaster",
  (id) => {
    return handleGetResponse(`quotation-status-master/${id}`);
  }
);

//update Quotation status
export const actionEditQuotationStatusMaster = createAsyncThunk(
  "actionEditQuotationStatusMaster",
  async ({ id, req, setEditingKey, setEdited }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/quotation-status-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
