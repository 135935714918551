import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { toast } from "sonner";
import { handleErrorMessage } from "../../components/helper/common-function";

// For Get Api
export const handleGetResponse = async (url) => {
  try {
    const res = await axios.get(`${BASE_URL}/${url}`);
    const { status, message: customMsg } = res.data;
    if (parseInt(status) === 200) {
      return res.data;
    } else if (parseInt(status) === 404) {
      return "";
    } else {
      toast.error(customMsg, 5);
      throw new Error(`API Error: ${customMsg}`);
    }
  } catch (error) {
    handleErrorMessage(error);
  }
};

// For Delete Api
export const handleDeleteResponse = async (url, req, setEditingKey) => {
  try {
    const response = await axios.delete(`${BASE_URL}/${url}`);
    const { status, message: customMsg } = response.data;
    if (parseInt(status) === 200) {
      toast.success(customMsg, 5);
      setEditingKey && setEditingKey("");
      return req;
    } else {
      toast.error(customMsg, 5);
      return false;
    }
  } catch (error) {
    handleErrorMessage(error);
  }
};