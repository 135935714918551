import { createSlice } from "@reduxjs/toolkit";
import {
  actionGetCurrencyMaster,
  actionAddCurrencyMaster,
  actionDeleteCurrencyMaster,
  actionEditCurrencyMaster,
  actionGetSingleCurrencyMaster,
} from "../services/currencyMasterService";

const currencyMasterSlice = createSlice({
  name: "currencyMaster",
  initialState: {
    getCurrencyMasterLoader: false,
    addCurrencyMasterLoader: false,
    deleteCurrencyMasterLoader: {},
    updateCurrencyMasterLoader: {},
    getSinglecurrencyMasterData: {},
    getSingleCurrencyMasterLoader: {},
    currencyMasterData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //get all address type master
      .addCase(actionGetCurrencyMaster.pending, (state) => {
        state.getCurrencyMasterLoader = true;
      })
      .addCase(actionGetCurrencyMaster.fulfilled, (state, action) => {
        state.getCurrencyMasterLoader = false;
        state.currencyMasterData = action.payload.data;
      })
      .addCase(actionGetCurrencyMaster.rejected, (state) => {
        state.getCurrencyMasterLoader = false;
      })

      //add address type
      .addCase(actionAddCurrencyMaster.pending, (state) => {
        state.addCurrencyMasterLoader = true;
      })
      .addCase(actionAddCurrencyMaster.fulfilled, (state, action) => {
        state.addCurrencyMasterLoader = false;
        if (action.payload) {
          if (
            !state.currencyMasterData ||
            !Array.isArray(state.currencyMasterData)
          ) {
            state.currencyMasterData = [action.payload];
          } else {
            state.currencyMasterData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddCurrencyMaster.rejected, (state) => {
        state.addCurrencyMasterLoader = false;
      })

      //delete address master
      .addCase(actionDeleteCurrencyMaster.pending, (state, action) => {
        state.deleteCurrencyMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteCurrencyMaster.fulfilled, (state, action) => {
        state.currencyMasterData = state.currencyMasterData.filter(
          (type) => type.currency_master_id !== action.payload
        );
        state.deleteCurrencyMasterLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteCurrencyMaster.rejected, (state, action) => {
        state.deleteCurrencyMasterLoader[action.meta.arg.id] = false;
      })

      //get single address type
      .addCase(actionGetSingleCurrencyMaster.pending, (state) => {
        state.getSingleCurrencyMasterLoader = true;
      })
      .addCase(actionGetSingleCurrencyMaster.fulfilled, (state, action) => {
        state.getSinglecurrencyMasterData = action.payload.data;
        state.getSingleCurrencyMasterLoader = false;
      })
      .addCase(actionGetSingleCurrencyMaster.rejected, (state) => {
        state.getSingleCurrencyMasterLoader = false;
      })

      //update address type
      // .addCase(actionEditCurrencyMaster.pending, (state, action) => {
      //   state.updateCurrencyMasterLoader[action.meta.arg.id] = true;
      // })
      // .addCase(actionEditCurrencyMaster.fulfilled, (state, action) => {
      //   state.updateCurrencyMasterLoader[action.meta.arg.id] = false;
      //   state.currencyMasterData = state.currencyMasterData.map((type) =>
      //     type.currency_master_id === action.payload.currency_master_id
      //       ? {
      //           ...type,
      //           unit_code: action.payload.unit_code,
      //           description: action.payload.description,
      //         }
      //       : type
      //   );
      // })
      // .addCase(actionEditCurrencyMaster.rejected, (state, action) => {
      //   state.updateCurrencyMasterLoader[action.meta.arg.id] = false;
      // });
      .addCase(actionEditCurrencyMaster.pending, (state) => {
        state.updateCurrencyMasterLoader = true;
      })
      .addCase(actionEditCurrencyMaster.fulfilled, (state, action) => {
        state.updateCurrencyMasterLoader = false;
        state.currencyMasterData = state.currencyMasterData.map((data) =>
          data.currency_master_id === action.payload.currency_master_id
            ? { ...data, ...action.payload }
            : data
        );
      })

      .addCase(actionEditCurrencyMaster.rejected, (state) => {
        state.updateCurrencyMasterLoader = false;
      });
  },
});
export default currencyMasterSlice.reducer;
