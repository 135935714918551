import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Row,
  Typography,
  Col,
  Pagination,
  ConfigProvider,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import plusIcon from "../../assets/img/add-button.svg";
import deletemaster from "../../assets/img/deleteMaster.svg";
import editedMaster from "../../assets/img/editedMaster.svg";
import editMaster from "../../assets/img/editMaster.svg";
import CustomSearchInput from "../common/CustomSearchInput";
import ConfigProviderWrapper from "../common/ConfigProviderWrapper";
import SortIcon from "../common/SortIcon";
import {
  actionAddCountryTypeMaster,
  actionDeleteCountryTypeMaster,
  actionEditCountryTypeMaster,
  actionGetCountryTypeMaster,
  actionGetSingleCountryTypeMaster,
} from "../../store/services/countryMasterService";
import { useDispatch, useSelector } from "react-redux";
import { isValidSearchString } from "../helper/common-function";

const AddressTypeMaster = () => {
  const offsetRef = useRef(0);
  const [editableForm] = Form.useForm();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    countryMasterData,
    getCountryMasterLoader,
    addCountryMasterLoader,
    deleteCountryMasterLoader,
    updateCountryMasterLoader,
    getSingleCountryMasterLoader,
    getSingleCountryMasterData,
    allCountriesCount,
  } = useSelector((state) => state.countryMaster);

  // Generating 10 items of dummy data
  const [search, setSearch] = useState("");
  const [edited, setEdited] = useState(false);
  const [countryData, setCountryData] = useState(null);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isEditing = (record) => record.id === editingKey;

  useEffect(() => {
    dispatch(actionGetCountryTypeMaster({ search }));
  }, []);

  const onFinish = (values) => {
    // Ensure phone_code is a valid number
    const parsedPhoneCode = Number(values.phone_code);
    const isValidPhoneCode =
      !isNaN(parsedPhoneCode) && Number.isInteger(parsedPhoneCode);

    if (!isValidPhoneCode) {
      //invalid code display msg
      console.error("enter the number");
      return;
    }

    const req = {
      ...values,
      iso3: values.iso3 ? values.iso3 : "",
      iso2: values.iso2 ? values.iso2 : "",
      phone_code: values.phone_code ? parseInt(values.phone_code, 10) : null,
      currency: values.currency ? values.currency : "",
      currency_name: values.currency_name ? values.currency_name : "",
      currency_symbol: values.currency_symbol ? values.currency_symbol : "",
    };

    countryData
      ? dispatch(
          actionEditCountryTypeMaster({
            id: getSingleCountryMasterData.id,
            req,
            handleCancel,
          })
        )
      : dispatch(
          actionAddCountryTypeMaster({
            req,
            handleCancel,
          })
        );
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (isValidSearchString(value)) {
      setSearch(value);
      if (value === "") {
        dispatch(
          actionGetCountryTypeMaster({
            search: value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    const value = e.target.value;
    if (search && isValidSearchString(value)) {
      dispatch(
        actionGetCountryTypeMaster({
          search: value,
        })
      );
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetCountryTypeMaster({
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = async () => {
    await setIsModalOpen(false);
    setCountryData(null);
    form.resetFields();
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "select" ? <Select /> : <Input className="!text-[14px]" />;
    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: title === "Country Name" ? true : false,
                message: ``,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="table-cell-text"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  useEffect(() => {
    if (countryData) {
      dispatch(actionGetSingleCountryTypeMaster(countryData.id));
    }
  }, [dispatch, countryData]);

  useEffect(() => {
    if (countryData && getSingleCountryMasterData) {
      form.setFieldsValue({
        name: getSingleCountryMasterData.name,
        iso3: getSingleCountryMasterData.iso3,
        iso2: getSingleCountryMasterData.iso2,
        phone_code: getSingleCountryMasterData.phone_code,
        currency: getSingleCountryMasterData.currency,
        currency_name: getSingleCountryMasterData.currency_name,
        currency_symbol: getSingleCountryMasterData.currency_symbol,
      });
    }
  }, [dispatch, getSingleCountryMasterData]);

  const columns = [
    {
      title: "Country Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      editable: true,
      fixed: "left",
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <span
          onClick={() => {
            showModal();
            setCountryData(record);
          }}
          className="text-blue-500  cursor-pointer"
        >
          {text}
        </span>
      ),
    },
    {
      title: "Iso3",
      dataIndex: "iso3",
      key: "iso3",
      width: 150,
      editable: true,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Iso2",
      dataIndex: "iso2",
      key: "iso2",
      width: 150,
      editable: true,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Phone Code",
      dataIndex: "phone_code",
      key: "phone_code",
      width: 150,
      editable: true,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      width: 150,
      editable: true,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Currency Name",
      dataIndex: "currency_name",
      key: "currency_name",
      width: 150,
      editable: true,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Currency Symbol",
      dataIndex: "currency_symbol",
      key: "currency_symbol",
      width: 150,
      editable: true,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Action",
      key: "4",
      width: 100,
      fixed: "right",
      dataIndex: "operation",
      render: (_, record) => {
        const getSingleCountryMasterData = isEditing(record);
        return (
          <div className={`flex items-center`}>
            {getSingleCountryMasterData ? (
              <div className="flex items-center"></div>
            ) : (
              <div
                className={`
              } flex items-center gap-x-2 justify-center`}
              >
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => {
                    showModal();
                    setCountryData(record);
                  }}
                >
                  <img src={editedMaster} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              <Popconfirm
                okButtonProps={{
                  loading: deleteCountryMasterLoader[record.id],
                }}
                description="Are you sure you want to delete?"
                onConfirm={() =>
                  dispatch(
                    actionDeleteCountryTypeMaster({
                      id: record.id,
                    })
                  )
                }
              >
                <img src={deletemaster} alt="" className="cursor-pointer" />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "name" ? "input" : "input",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div className="master-input">
      <div className="flex justify-between mt-[15px]">
        <h1 className="text-black text-[20px] font-monaSansMedium">Country</h1>
        <div>
          <Button
            onClick={showModal}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="PlusIcon Icon" preview={false} />}
          >
            Add Country
          </Button>
          <Modal
            className="input-custom"
            centered
            onOk={handleCancel}
            onCancel={handleCancel}
            footer={false}
            width={700}
            title=""
            destroyOnClose={handleCancel}
            open={isModalOpen}
          >
            <h1 className="text-black text-[20px]  mt-[5px] font-monaSansMedium">
              Country
            </h1>
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name="name"
                    label="Counytry Name"
                    className="mb-[12px]"
                    size="small"
                    rules={[
                      {
                        required: true,
                        message: "Please input country!",
                      },
                    ]}
                  >
                    <Input
                      style={{ background: "white" }}
                      placeholder="Enter Country"
                      className=" bg-white focus:bg-white active:bg-white rounded-[10px]"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="iso3"
                    label="Iso3"
                    className="mb-[12px]"
                    size="small"
                    rules={[
                      {
                        required: true,
                        message: "Please input iso3!",
                      },
                    ]}
                  >
                    <Input
                      style={{ background: "white" }}
                      placeholder="Enter iso3"
                      className=" bg-white focus:bg-white active:bg-white rounded-[10px]"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="iso2"
                    label="Iso2"
                    className="mb-[12px]"
                    size="small"
                    rules={[
                      {
                        required: true,
                        message: "Please input iso2!",
                      },
                    ]}
                  >
                    <Input
                      style={{ background: "white" }}
                      placeholder="Enter Iso2"
                      className=" bg-white focus:bg-white active:bg-white rounded-[10px]"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phone_code"
                    label="Phone Code"
                    className="mb-[12px]"
                    normalize={(value) => value && value.replace(/[^+\d]/g, "")}
                    size="small"
                    rules={[
                      {
                        required: true,
                        message: "Please input phone code!",
                      },
                    ]}
                  >
                    <Input
                      style={{ background: "white" }}
                      placeholder="Enter Phone Code"
                      className=" bg-white focus:bg-white active:bg-white rounded-[10px]"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="currency"
                    label="Currency"
                    className="mb-[12px]"
                    size="small"
                    rules={[
                      {
                        required: true,
                        message: "Please input currency!",
                      },
                    ]}
                  >
                    <Input
                      style={{ background: "white" }}
                      placeholder="Enter Currency"
                      className=" bg-white focus:bg-white active:bg-white rounded-[10px]"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="currency_name"
                    label="Currency Name"
                    className="mb-[12px]"
                    size="small"
                    rules={[
                      {
                        required: true,
                        message: "Please input currency name!",
                      },
                    ]}
                  >
                    <Input
                      style={{ background: "white" }}
                      placeholder="Enter Currency Name"
                      className=" bg-white focus:bg-white active:bg-white rounded-[10px]"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="currency_symbol"
                    label="Currency Symbol"
                    className="mb-[12px]"
                    size="small"
                    rules={[
                      {
                        required: true,
                        message: "Please input currency symbol!",
                      },
                    ]}
                  >
                    <Input
                      style={{ background: "white" }}
                      placeholder="Enter Currency Symbol"
                      className=" bg-white focus:bg-white active:bg-white rounded-[10px]"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="flex justify-center mt-[40px]">
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="cancel-btn  min-h-[40px] min-w-[100px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      type="primary"
                      loading={
                        addCountryMasterLoader || updateCountryMasterLoader
                      }
                      className="root-btn min-h-[40px] min-w-[100px]"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
      <div className="form-table-design mt-[15px]">
        <div className="relative">
          <div className="search-bar">
            <CustomSearchInput
              handleSearch={handleSearch}
              handleBlurAndPressEnter={handleBlurAndPressEnter}
              searchValue={search}
            />
          </div>

          <div className="pt-3 pb-[75px]">
            <Form form={editableForm} component={false}>
              {/* <ConfigProviderWrapper> */}
              <ConfigProvider
                theme={{
                  components: {
                    Input: {
                      controlHeight: 40,
                    },
                    Table: {
                      cellPaddingBlock: 10,
                      // zIndexBase: 4,
                    },
                  },
                }}
              >
                <Table
                  /*  rowClassName={(record, index) => {
                    if (index % 2 === 0) {
                      return "row-even";
                    } else {
                      return "row-odd";
                    }
                  }} */
                  bordered
                  onChange={handleSortChange}
                  className="ant-table-padding"
                  rowKey="id"
                  columns={mergedColumns}
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  loading={getCountryMasterLoader}
                  dataSource={countryMasterData}
                  pagination={false}
                  rowClassName="editable-row country-master-table"
                  scroll={{ x: 1200, y: 400 }}
                />
                <div className="flex justify-end mt-3">
                  <Pagination
                    hideOnSinglePage
                    responsive={true}
                    current={offsetRef.current + 1}
                    className="flex items-center"
                    showSizeChanger={false}
                    total={allCountriesCount}
                    onChange={(e) => {
                      offsetRef.current = e - 1;
                      dispatch(
                        actionGetCountryTypeMaster({
                          offset: offsetRef.current,
                          search,
                        })
                      );
                    }}
                  />
                </div>
              </ConfigProvider>
              {/* </ConfigProviderWrapper> */}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressTypeMaster;
