import { DeleteOutlined, EditOutlined, KeyOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  Modal,
  Pagination,
  Table,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomSearchInput from "../common/CustomSearchInput";
import PageTitle from "../common/PageTitle";
import SortIcon from "../common/SortIcon";
import { getFirstLetterCapital } from "../helper/common-function";
import {
  actionDeleteUser,
  actionGetUsers,
} from "../../store/services/userService";
import { selectHasPermission } from "../../store/slices/authSlice";

const Users = () => {
  const [search, setSearch] = useState("");
  const [userId, setUserId] = useState("");
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const offsetRef = useRef(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasPermission = useSelector(selectHasPermission);

  const { getUsersLoader, deleteUserLoader, users, allUsersCount } =
    useSelector((state) => state.user);

  const getApiCall = (
    offset = 0,
    search = "",
    orderByColumn = "",
    order = ""
  ) => {
    dispatch(
      actionGetUsers({
        offset,
        orderByColumn,
        order,
        search,
      })
    );
  };

  useEffect(() => {
    getApiCall(offsetRef.current);
  }, []);

  const columns = [
    {
      title: "Name",
      key: "first_name",
      width: 250,
      ellipsis: true,
      dataIndex: "first_name",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      fixed: "left",
      render: (text, data) => (
        <div
          className="flex cursor-pointer text-blue-600 hover:text-blue-600 items-center "
          onClick={() => {
            // setUserId(data.id);
            navigate("/update-user", {
              state: { userId: data.id },
            });
          }}
        >
          <Avatar
            style={{
              verticalAlign: "middle",
              borderRadius: "5px",
            }}
            src={data?.profile_pic?.url}
            size="large"
          />
          <p className="ml-2 text-brand">
            {`
            ${getFirstLetterCapital(data?.first_name)} 
            ${getFirstLetterCapital(data?.last_name)}
          `}
          </p>
        </div>
      ),
    },
    {
      title: "Email",
      key: "email",
      width: 200,
      dataIndex: "email",
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Contact Number",
      key: "phone",
      width: 200,
      dataIndex: "phone",
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text, record) => {
        return record.country_code && text ? (
          <span className="ml-2">{`+${record.country_code} ${text}`}</span>
        ) : (
          <span className="ml-2">-</span>
        );
      },
    },
    {
      title: "Role",
      key: "role",
      width: 200,
      ellipsis: true,
      dataIndex: "role",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text?.name || "-"}</span>,
    },
    {
      title: "Department",
      key: "department",
      ellipsis: true,
      width: 200,
      dataIndex: "department",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 150,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown.Button
            id="custom-drop"
            disabled={
              !hasPermission("users", "delete") &&
              !hasPermission("users", "update")
            }
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () =>
                    navigate("/update-user", {
                      state: { userId: data.id },
                    }),
                  disabled: !hasPermission("users", "update"),
                },
                {
                  label: "Change Password",
                  key: "change-password",
                  icon: <KeyOutlined style={{ fontSize: 12 }} />,
                  onClick: () => {
                    navigate("/change-password", {
                      state: {
                        userId: data.id,
                      },
                    });
                  },
                },
                {
                  label: "Delete",
                  key: "Delete",
                  disabled: !hasPermission("users", "delete"),
                  icon: <DeleteOutlined style={{ fontSize: 12 }} />,
                  danger: true,
                  onClick: () => {
                    setDeleteUserModalOpen(true);
                    setUserId(data.id);
                  },
                },
              ].filter(Boolean),
            }}
          >
            Action
          </Dropdown.Button>
        </>
      ),
    },
  ];

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      getApiCall(offsetRef.current, e.target.value);
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      getApiCall(offsetRef.current, e.target.value);
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    getApiCall(
      offsetRef.current,
      search,
      sorterParams.field,
      sorterParams.order
    );
  };

  const handleCloseUserDeleteModal = () => {
    setUserId("");
    setDeleteUserModalOpen(false);
  };

  return (
    <>
      <PageTitle
        title="Users"
        buttonText="Add User"
        buttonLink="/create-user"
        isShowButton={hasPermission("users", "create")}
      />
      <CustomSearchInput
        handleSearch={handleSearch}
        handleBlurAndPressEnter={handleBlurAndPressEnter}
        searchValue={search}
      />
      <Table
        sticky
        rowKey="id"
        scrollToFirstRowOnChange={true}
        sortDirections={["ascend", "descend"]}
        onChange={handleSortChange}
        bordered
        // virtual
        scroll={{
          x: "max-content",
          y: window.innerWidth < 1500 ? "53vh" : "61vh",
        }}
        pagination={false}
        columns={columns}
        dataSource={users}
        loading={getUsersLoader}
      />
      <div className="flex justify-end mt-3">
        <Pagination
          hideOnSinglePage
          responsive={true}
          current={offsetRef.current + 1}
          className="flex items-center"
          total={allUsersCount}
          onChange={(e) => {
            offsetRef.current = e - 1;
            getApiCall(offsetRef.current, search);
          }}
        />
      </div>
      <Modal
        className="max-h-[100vh]"
        centered={true}
        footer={false}
        handleReset={handleCloseUserDeleteModal}
        afterClose={handleCloseUserDeleteModal}
        open={deleteUserModalOpen}
        title=""
        onCancel={handleCloseUserDeleteModal}
        onOk={handleCloseUserDeleteModal}
      >
        <p className="text-[19px] text-black font-monaSansMedium mt-2">
          Are you sure you want to delete this user ?
        </p>
        <div className=" flex justify-end mt-[25px] lg:mt-[30px]">
          <Button
            onClick={handleCloseUserDeleteModal}
            type="button"
            className="gray-button min-h-[45px] min-w-[110px]"
          >
            Cancel
          </Button>

          <div className="ml-4">
            <Button
              loading={deleteUserLoader}
              onClick={() => {
                dispatch(
                  actionDeleteUser({
                    userId,
                    handleCloseUserDeleteModal,
                  })
                );
              }}
              className="delete-btn min-h-[45px] min-w-[110px]"
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Users;
//
