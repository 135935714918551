import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import axios from "axios";
import { toast } from "sonner";
import { handleDeleteResponse, handleGetResponse } from "./commonService";
import { handleErrorMessage } from "../../components/helper/common-function";

//get all listing of the Currency master
export const actionGetCurrencyMaster = createAsyncThunk(
  "actionGetCurrencyMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `currency-master?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//add Currency master
export const actionAddCurrencyMaster = createAsyncThunk(
  "actionAddCurrencyMaster",
  async ({ req, handleCancel, form }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/currency-master`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            currency_master_id: data?.currency_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete Currency master
export const actionDeleteCurrencyMaster = createAsyncThunk(
  "actionDeleteCurrencyMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`currency-master/${id}`, id, setEditingKey);
  }
);

//get single Currency master
export const actionGetSingleCurrencyMaster = createAsyncThunk(
  "actionGetSingleCurrencyMaster",
  (id) => {
    return handleGetResponse(`currency-master/${id}`);
  }
);

//update Currency master
export const actionEditCurrencyMaster = createAsyncThunk(
  "actionEditCurrencyMaster",
  async ({ id, req, handleCancel }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/currency-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
