import { createSlice } from "@reduxjs/toolkit";
import {
    actionGetBankDetailsMaster,
    actionAddBankDetailMaster,
    actionDeleteBankDetailMaster,
    actionGetSingleBankDetailsMaster,
    actionEditBankDetailsMaster,
} from "../services/bankDetailsMasterService";

const initialState = {
    bankDetailsMasterData: [],
    getBankDetailsMasterLoader: false,
    addBankDetailsMasterLoader: false,
    deleteBankDetailsMasterLoader: {},
    updateBankDetailsMasterLoader: {},
    getSingleBankDetailsMasterData: null,
    getSingleBankDetailsMasterLoader: false,
};

const bankDetailsMasterSlice = createSlice({
    name: "bankDetailsMaster",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Get all bank details
            .addCase(actionGetBankDetailsMaster.pending, (state) => {
                state.getBankDetailsMasterLoader = true;
            })
            .addCase(actionGetBankDetailsMaster.fulfilled, (state, action) => {
                state.getBankDetailsMasterLoader = false;
                state.bankDetailsMasterData = action.payload.data;
            })
            .addCase(actionGetBankDetailsMaster.rejected, (state) => {
                state.getBankDetailsMasterLoader = false;
            })

            // Add bank detail
            .addCase(actionAddBankDetailMaster.pending, (state) => {
                state.addBankDetailsMasterLoader = true;
            })
            .addCase(actionAddBankDetailMaster.fulfilled, (state, action) => {
                state.addBankDetailsMasterLoader = false;
                // state.bankDetailsMasterData.unshift(action.payload);
                if (
                    !state.bankDetailsMasterData ||
                    !Array.isArray(state.bankDetailsMasterData)
                ) {
                    state.bankDetailsMasterData = [action.payload];
                } else {
                    state.bankDetailsMasterData.unshift(action.payload);
                }
            })
            .addCase(actionAddBankDetailMaster.rejected, (state) => {
                state.addBankDetailsMasterLoader = false;
            })

            // Delete bank detail
            .addCase(actionDeleteBankDetailMaster.pending, (state, action) => {
                state.deleteBankDetailsMasterLoader[action.meta.arg.id] = true;
            })
            .addCase(actionDeleteBankDetailMaster.fulfilled, (state, action) => {
                state.deleteBankDetailsMasterLoader[action.meta.arg.id] = false;
                state.bankDetailsMasterData = state.bankDetailsMasterData.filter(
                    (item) => item.company_bank_master_id !== action.payload
                );
            })
            .addCase(actionDeleteBankDetailMaster.rejected, (state, action) => {
                state.deleteBankDetailsMasterLoader[action.meta.arg.id] = false;
            })

            // Get single bank detail
            .addCase(actionGetSingleBankDetailsMaster.pending, (state) => {
                state.getSingleBankDetailsMasterLoader = true;
            })
            .addCase(actionGetSingleBankDetailsMaster.fulfilled, (state, action) => {
                state.getSingleBankDetailsMasterLoader = false;
                state.getSingleBankDetailsMasterData = action.payload.data;
            })
            .addCase(actionGetSingleBankDetailsMaster.rejected, (state) => {
                state.getSingleBankDetailsMasterLoader = false;
            })

            // Update bank detail
            .addCase(actionEditBankDetailsMaster.pending, (state, action) => {
                state.updateBankDetailsMasterLoader[action.meta.arg.id] = true;
            })
            .addCase(actionEditBankDetailsMaster.fulfilled, (state, action) => {
                state.updateBankDetailsMasterLoader[action.meta.arg.id] = false;
                // const index = state.bankDetailsMasterData.findIndex(
                //     (item) => item.company_bank_master_id === action.payload.company_bank_master_id
                // );
                // if (index !== -1) {
                //     state.bankDetailsMasterData[index] = action.payload;
                // }
                state.bankDetailsMasterData = state.bankDetailsMasterData.map((data) =>
                    data.company_bank_master_id === action.payload.company_bank_master_id ? { ...data, ...action.payload } : data
                );
            })
            .addCase(actionEditBankDetailsMaster.rejected, (state, action) => {
                state.updateBankDetailsMasterLoader[action.meta.arg.id] = false;
            });
    },
});

export default bankDetailsMasterSlice.reducer;