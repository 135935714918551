import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  actionGetUsers,
  actionAddUser,
  actionDeleteUser,
  actionEditUser,
  actionGetSingleUser,
} from "../services/userService";
import {
  actionAddProduct,
  actionDeleteProduct,
  actionEditProduct,
  actionGetAllProducts,
  actionGetProducts,
  actionGetSingleProduct,
  actionGetSingleProductByIndex,
  actionResetSingleProductsIndexData,
} from "../services/productService";

const productSlice = createSlice({
  name: "product",
  initialState: {
    getProductsLoader: false,
    addProductLoader: false,
    deleteProductLoader: false,
    updateProductLoader: false,
    getSingleProductData: {},
    getSingleProductLoader: false,
    getAllProductsLoader: false,
    allProducts: [],
    products: [],
    allProductsCount: 0,
    getSingleProductsIndexLoader: false,
    allSingleProductsIndexData: {},
  },
  reducers: {
    clearSingleProductsIndexData: (state) => {
      state.allSingleProductsIndexData = {};
    },
    removeSingleProductIndex: (state, action) => {
      const indexToRemove = action.payload;
      const newData = { ...state.allSingleProductsIndexData };
      delete newData[indexToRemove];
      state.allSingleProductsIndexData = newData;
    },

    adjustProductIndexes: (state, action) => {
      const removedIndex = action.payload;
      const newData = {};
      Object.entries(state.allSingleProductsIndexData).forEach(
        ([key, value]) => {
          const numKey = Number(key);
          if (numKey > removedIndex) {
            newData[numKey - 1] = value;
          } else if (numKey < removedIndex) {
            newData[numKey] = value;
          }
        }
      );
      state.allSingleProductsIndexData = newData;
    },

    setProductData: (state, action) => {
      state.allSingleProductsIndexData[action.payload.index] =
        action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder
      //get all products
      .addCase(actionGetProducts.pending, (state) => {
        state.getProductsLoader = true;
      })
      .addCase(actionGetProducts.fulfilled, (state, action) => {
        state.getProductsLoader = false;
        state.products = action.payload.data;
        state.allProductsCount = action.payload.total_records;
      })
      .addCase(actionGetProducts.rejected, (state) => {
        state.getProductsLoader = false;
      })

      .addCase(actionGetAllProducts.pending, (state) => {
        state.getAllProductsLoader = true;
      })
      .addCase(actionGetAllProducts.fulfilled, (state, action) => {
        state.getAllProductsLoader = false;
        state.allProducts = action.payload.data;
      })
      .addCase(actionGetAllProducts.rejected, (state) => {
        state.getAllProductsLoader = false;
      })

      //add product
      .addCase(actionAddProduct.pending, (state) => {
        state.addProductLoader = true;
      })
      .addCase(actionAddProduct.fulfilled, (state, action) => {
        state.addProductLoader = false;

        if (!state.products || Array.isArray(state.products)) {
          state.products = [action.payload];
          state.allProducts.unshift(action.payload);
        } else {
          state.products.unshift(action.payload);
          state.allProducts.unshift(action.payload);
        }
      })
      .addCase(actionAddProduct.rejected, (state) => {
        state.addProductLoader = false;
      })

      //delete product
      .addCase(actionDeleteProduct.pending, (state, action) => {
        state.deleteProductLoader = true;
      })
      .addCase(actionDeleteProduct.fulfilled, (state, action) => {
        state.products = state.products.filter(
          (product) => product.id !== action.payload
        );
        state.deleteProductLoader = false;
      })
      .addCase(actionDeleteProduct.rejected, (state, action) => {
        state.deleteProductLoader = false;
      })

      //get single product
      .addCase(actionGetSingleProduct.pending, (state) => {
        state.getSingleProductLoader = true;
      })
      .addCase(actionGetSingleProduct.fulfilled, (state, action) => {
        state.getSingleProductData = action.payload.data;
        state.getSingleProductLoader = false;
      })
      .addCase(actionGetSingleProduct.rejected, (state) => {
        state.getSingleProductLoader = false;
      })

      //update product
      .addCase(actionEditProduct.pending, (state, action) => {
        state.updateProductLoader = true;
      })
      .addCase(actionEditProduct.fulfilled, (state, action) => {
        state.updateProductLoader = false;
      })
      .addCase(actionEditProduct.rejected, (state, action) => {
        state.updateProductLoader = false;
      })

      .addCase(actionGetSingleProductByIndex.pending, (state) => {
        state.getSingleProductsIndexLoader = true;
      })
      .addCase(actionGetSingleProductByIndex.fulfilled, (state, action) => {
        const { index, data } = action.payload;

        state.allSingleProductsIndexData = {
          ...state.allSingleProductsIndexData,
          [index]: data,
        };

        state.getSingleProductsIndexLoader = false;
      })
      .addCase(actionGetSingleProductByIndex.rejected, (state) => {
        state.getSingleProductsIndexLoader = false;
      })

      .addCase(
        actionResetSingleProductsIndexData.fulfilled,
        (state, action) => {
          state.allSingleProductsIndexData = {};
        }
      )
      .addCase(actionResetSingleProductsIndexData.rejected, (state) => {
        state.allSingleProductsIndexData = {};
      });
  },
});

export default productSlice.reducer;

export const {
  clearSingleProductsIndexData,
  setProductData,
  removeSingleProductIndex,
  adjustProductIndexes,
} = productSlice.actions;
