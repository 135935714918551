import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import axios from "axios";
import { toast } from "sonner";
import { handleDeleteResponse, handleGetResponse } from "./commonService";

//get all listing of the country type
export const actionGetCountryTypeMaster = createAsyncThunk(
  "actionGetCountryTypeMaster",
  async ({ search = "", orderByColumn = "", order = "", offset = 0 } = {}) => {
    return handleGetResponse(
      `countries?limit=10&offset=${offset}${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//add country type
export const actionAddCountryTypeMaster = createAsyncThunk(
  "actionAddCountryTypeMaster",
  async ({ req, handleCancel, form }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/countries`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        // form &&
        //   form.setFieldsValue({
        //     id: data?.id,
        //   });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error);
    }
  }
);

//delete country type master
export const actionDeleteCountryTypeMaster = createAsyncThunk(
  "actionDeleteCountryTypeMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`countries/${id}`, id, setEditingKey);
  }
);

//get single country master
export const actionGetSingleCountryTypeMaster = createAsyncThunk(
  "actionGetSingleCountryTypeMaster",
  (id) => {
    return handleGetResponse(`countries/${id}`);
  }
);

//update country type matster
export const actionEditCountryTypeMaster = createAsyncThunk(
  "actionEditCountryTypeMaster",
  async ({ id, req, handleCancel }, {rejectWithValue}) => {
    try {
      const response = await axios.patch(`${BASE_URL}/countries/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      toast.error(error);
    }
  }
);

// Action to get All countries
export const actionGetAllCountry = createAsyncThunk(
  "actionGetAllCountry",
  async () => {
    return handleGetResponse(`countries?getall=YES`);
  }
);
