import { Image } from "antd";
import React from "react";
import backArrow from "../../assets/img/backArrow.svg";
import { useNavigate } from "react-router-dom";

const BackArrowButton = () => {
  const navigate = useNavigate();

  return (
    <div className="text-brand flex items-center gap-x-3 justify-between">
      
        <div
          className="flex items-center gap-x-3 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <Image
            src={backArrow}
            alt="image"
            height={20}
            width={20}
            preview={false}
          />
          <h1 className="lg:text-lg text-base font-monaSansMedium text-secondary">Back</h1>
        </div>
  
    </div>
  );
};

export default BackArrowButton;
