import { createSlice } from "@reduxjs/toolkit";
import {
  actionGetAddressTypeMaster,
  actionAddAddressTypeMaster,
  actionDeleteAddressTypeMaster,
  actionEditAddressTypeMaster,
  actionGetSingleAddressTypeMaster,
} from "../services/addressTypeMasterService";

const addressTypeMasterSlice = createSlice({
  name: "addressTypeMaster",
  initialState: {
    getAddressTypeMasterLoader: false,
    addAddressTypeMasterLoader: false,
    deleteAddressTypeMasterLoader: {},
    updateAddressTypeMasterLoader: {},
    getSingleAddressTypeMasterData: {},
    getSingleAddressTypeMasterLoader: {},
    addressTypeMasterData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      //get all address type master
      .addCase(actionGetAddressTypeMaster.pending, (state) => {
        state.getAddressTypeMasterLoader = true;
      })
      .addCase(actionGetAddressTypeMaster.fulfilled, (state, action) => {
        state.getAddressTypeMasterLoader = false;
        state.addressTypeMasterData = action.payload.data;
      })
      .addCase(actionGetAddressTypeMaster.rejected, (state) => {
        state.getAddressTypeMasterLoader = false;
      })

      //add address type
      .addCase(actionAddAddressTypeMaster.pending, (state) => {
        state.addAddressTypeMasterLoader = true;
      })
      .addCase(actionAddAddressTypeMaster.fulfilled, (state, action) => {
        state.addAddressTypeMasterLoader = false;
        if (action.payload) {
          if (
            !state.addressTypeMasterData ||
            !Array.isArray(state.addressTypeMasterData)
          ) {
            state.addressTypeMasterData = [action.payload];
          } else {
            state.addressTypeMasterData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddAddressTypeMaster.rejected, (state) => {
        state.addAddressTypeMasterLoader = false;
      })

      //delete address master
      .addCase(actionDeleteAddressTypeMaster.pending, (state, action) => {
        state.deleteAddressTypeMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteAddressTypeMaster.fulfilled, (state, action) => {
        state.addressTypeMasterData = state.addressTypeMasterData.filter(
          (type) => type.address_type_master_id !== action.payload
        );
        state.deleteAddressTypeMasterLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteAddressTypeMaster.rejected, (state, action) => {
        state.deleteAddressTypeMasterLoader[action.meta.arg.id] = false;
      })

      //get single address type
      .addCase(actionGetSingleAddressTypeMaster.pending, (state) => {
        state.getSingleAddressTypeMasterLoader = true;
      })
      .addCase(actionGetSingleAddressTypeMaster.fulfilled, (state, action) => {
        state.getSingleAddressTypeMasterData = action.payload.data;
        state.getSingleAddressTypeMasterLoader = false;
      })
      .addCase(actionGetSingleAddressTypeMaster.rejected, (state) => {
        state.getSingleAddressTypeMasterLoader = false;
      })

      //update address type
      .addCase(actionEditAddressTypeMaster.pending, (state, action) => {
        state.updateAddressTypeMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditAddressTypeMaster.fulfilled, (state, action) => {
        state.updateAddressTypeMasterLoader[action.meta.arg.id] = false;
        state.addressTypeMasterData = state.addressTypeMasterData.map((type) =>
          type.address_type_master_id === action.payload.address_type_master_id
            ? {
                ...type,
                address_type: action.payload.address_type,
                description: action.payload.description,
              }
            : type
        );
      })
      .addCase(actionEditAddressTypeMaster.rejected, (state, action) => {
        state.updateAddressTypeMasterLoader[action.meta.arg.id] = false;
      });
  },
});
export default addressTypeMasterSlice.reducer;
