import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import dayjs from "dayjs";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFetcher, useLocation, useNavigate } from "react-router-dom";
import deletemaster from "../../assets/img/deleteMaster.svg";
import plusSvg from "../../assets/img/plus.svg";
import addIconBlack from "../../assets/img/plusBlack.svg";
import quotationIcon from "../../assets/img/quotationIcon.svg";
import {
  actionAddBankDetailMaster,
  actionGetBankDetailsMaster,
} from "../../store/services/bankDetailsMasterService";
import { actionGetAllCustomers } from "../../store/services/customerService";
import {
  actionAddProduct,
  actionGetAllProducts,
  actionGetSingleProductByIndex,
  actionResetSingleProductsIndexData,
} from "../../store/services/productService";
import {
  actionAddQuotation,
  actionEditQuotation,
  actionGetSingleQuotation,
} from "../../store/services/quotationService";
import { actionGetQuotationStatusMaster } from "../../store/services/quotationStatusMasterService";
import { actionGetTaxMaster } from "../../store/services/taxMasterService";
import {
  actionAddUnitMaster,
  actionGetUnitMaster,
} from "../../store/services/unitMasterService";
import AddPopover from "../common/AddPopover";
import BackArrowButton from "../common/BackArrowButton";
import CustomIconText from "../common/CustomIconText";
import CreateCustomer from "../customers/CreateCustomer";
import { gridSettings } from "../helper/constants";
import {
  adjustProductIndexes,
  clearSingleProductsIndexData,
  removeSingleProductIndex,
} from "../../store/slices/productSlice";

const createOptions = (data, labelKey, valueKey) =>
  data?.map((item) => ({
    key: item[valueKey],
    label: item[labelKey],
    value: item[valueKey],
  })) || [];

const CreateQuotation = () => {
  const [form] = Form.useForm();
  const [addProductForm] = Form.useForm();
  const [isItemRemoved, setIsItemRemoved] = useState(false);
  const [unitForm] = Form.useForm();
  const [addBankDetailForm] = Form.useForm();
  const { state } = useLocation();
  const { quotationId } = state || {};

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { authData } = useSelector((state) => state.auth);
  const { myCurrency } = useSelector((state) => state.auth);

  const { unitMasterData, getUnitMasterLoader, addUnitMasterLoader } =
    useSelector((state) => state.unitMaster);
  const { allCustomers, getAllCustomersLoader } = useSelector(
    (state) => state.customer
  );
  const { taxMasterData } = useSelector((state) => state.taxMaster);
  const {
    getAllProductsLoader,
    allProducts,
    addProductLoader,
    getSingleProductsIndexLoader,
    allSingleProductsIndexData,
  } = useSelector((state) => state.product);
  const { getQuotationStatusMasterLoader, quotationStatusMasterData } =
    useSelector((state) => state.quotationStatusMaster);

  const {
    getSingleQuotationData,
    getSingleQuotationLoader,
    addQuotationLoader,
    updateQuotationLoader,
  } = useSelector((state) => state.quotation);

  const {
    bankDetailsMasterData,
    getBankDetailsMasterLoader,
    addBankDetailsMasterLoader,
  } = useSelector((state) => state.bankDetails);

  const { company } = authData || {};

  const selectRef = useRef(null);

  const [dropdownOpenStates, setDropdownOpenStates] = useState({});
  const [addUnitPopoverOpen, setAddUnitPopoverOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addBankDetailModalOpen, setAddBankDetailModalOpen] = useState(false);
  const [summary, setSummary] = useState({
    subTotal: 0,
    taxes: [],
    totalTaxAmount: 0,
    discountAmount: 0,
    adjustment: 0,
    total: 0,
  });

  const [isAddItemModalVisible, setIsAddItemModalVisible] = useState(false);

  useEffect(() => {
    if (quotationId) {
      dispatch(actionGetSingleQuotation(quotationId));
    } else {
      dispatch(actionResetSingleProductsIndexData());

      form.resetFields();
      form.setFieldsValue({
        items: [{ quantity: 1, discount_type: "FLAT" }],
      });
    }

    return () => {
      dispatch(actionResetSingleProductsIndexData());
    };
  }, [quotationId, dispatch]);

  useEffect(() => {
    dispatch(actionGetUnitMaster());
    dispatch(actionGetAllCustomers());
    dispatch(actionGetAllProducts());
    dispatch(actionGetQuotationStatusMaster());
    dispatch(actionGetTaxMaster());
    dispatch(actionGetBankDetailsMaster());
  }, [dispatch]);

  useEffect(() => {
    if (company) {
      form.setFieldsValue({
        quotation_description: company?.description,
        terms_and_conditions: company?.terms_and_conditions,
      });
    }
  }, [company, form]);

  useEffect(() => {
    const resetFormAndSummary = () => {
      form.resetFields();
      form.setFieldsValue({
        items: [{ quantity: 1, discount_type: "FLAT" }],
      });
      setSummary({
        subTotal: 0,
        taxes: [],
        totalTaxAmount: 0,
        discountAmount: 0,
        adjustment: 0,
        total: 0,
      });
    };

    const fetchProductDetails = async (items) => {
      const productPromises = items.map((item, index) =>
        item.material_id
          ? dispatch(
              actionGetSingleProductByIndex({ id: item.material_id, index })
            )
          : null
      );
      await Promise.all(productPromises);
    };

    const setFormValues = (quotationData) => {
      const initialItems =
        quotationData?.quotation_items?.map((item, index) => ({
          key: index,
          material_id: item.material?.id,
          quotation_items_description: item.quotation_items_description,
          rate: item.total_item_amount,
          subTotal: item.total_item_amount,
          unit_master_id: item.unit_master_id,
          quantity: item.quantity,
          discount: item.discount,
          discount_type: item.discount_type,
          amount: item.amount,
        })) || [];

      form.setFieldsValue({
        quotation_status_master_id:
          quotationData?.quotation_status?.quotation_status_master_id,
        customer_id: quotationData?.customer?.customers_id,
        quotation_subject: quotationData?.quotation_subject,
        tin_number: quotationData?.tin_number,
        quotation_description: quotationData.quotation_description,
        terms_and_conditions: quotationData.terms_and_conditions,
        quotation_date: quotationData.quotation_date
          ? dayjs(quotationData.quotation_date, "YYYY-MM-DD")
          : null,
        expiry_date: quotationData.expiry_date
          ? dayjs(quotationData.expiry_date, "YYYY-MM-DD")
          : null,
        authorized_by: quotationData?.authorized_by,
        company_bank_master_id:
          quotationData?.company_bank_master?.company_bank_master_id,
        items: initialItems,
      });

      return initialItems;
    };

    const updateSummaryData = (quotationData) => {
      const taxesItems =
        quotationData?.taxes?.map((tax) => ({
          key: tax.tax_id,
          label: tax.name,
          value: tax.tax_id,
          rate: tax.tax_rate,
        })) || [];

      setSummary({
        subTotal: quotationData.sub_total || 0,
        taxes: taxesItems,
        totalTaxAmount: quotationData.tax_amount || 0,
        discountAmount: quotationData.discount_amount || 0,
        adjustment: quotationData.adjustment || 0,
        total: quotationData.total || 0,
      });
    };

    const setupQuotationItems = async () => {
      if (quotationId && quotationId === getSingleQuotationData?.id) {
        dispatch(clearSingleProductsIndexData());
        const initialItems = setFormValues(getSingleQuotationData);
        await fetchProductDetails(initialItems);
        updateSummaryData(getSingleQuotationData);
      } else {
        resetFormAndSummary();
      }
    };

    if (quotationId) {
      setupQuotationItems();
    }
  }, [quotationId, getSingleQuotationData, dispatch, form]);

  useEffect(() => {
    if (
      !isItemRemoved &&
      quotationId &&
      allSingleProductsIndexData &&
      Object.keys(allSingleProductsIndexData).length > 0
    ) {
      const currentItems = form.getFieldValue("items") || [];
      const existingItems = getSingleQuotationData.quotation_items || [];

      const uniqueProducts = {};
      Object.entries(allSingleProductsIndexData).forEach(([index, product]) => {
        uniqueProducts[product.id] = {
          ...product,
          index: Number(index),
        };
      });

      const updatedItems = existingItems.map((item) => {
        const uniqueProduct = uniqueProducts[item.material?.id];
        if (uniqueProduct) {
          const amount = calculateAmount(
            item.quotation_items_rate,
            item.quantity || 1,
            item.discount || 0,
            item.discount_type || "FLAT"
          );
          return {
            ...item,
            material_id: item.material?.id,
            quotation_items_description: item.quotation_items_description,
            unit_master_id: uniqueProduct.unit?.unit_master_id,
            rate: item.quotation_items_rate,
            image: uniqueProduct.material_image?.url,
            quantity: item.quantity || 1,
            discount: item.discount || 0,
            discount_type: item.discount_type || "FLAT",
            amount: amount,
          };
        }
        return item;
      });

      const validNewItems = currentItems.filter(
        (item) => item.material_id && item.quantity > 0
      );

      const combinedItems = [
        ...updatedItems,
        ...validNewItems.filter(
          (newItem) =>
            !updatedItems.some(
              (existingItem) => existingItem.material_id === newItem.material_id
            )
        ),
      ];

      form.setFieldsValue({ items: combinedItems });
      updateSummary(form);
    }
    setIsItemRemoved(false);
  }, [allSingleProductsIndexData, getSingleQuotationData, quotationId]);

  useEffect(() => {
    if (taxMasterData && taxMasterData.length > 0) {
      const taxes = taxMasterData
        .filter((tax) => tax.status === "Active")
        .map((tax) => ({
          key: tax.tax_master_id,
          label: tax.name,
          value: tax.tax_master_id,
          rate: tax.rate,
        }));
      setSummary({ ...summary, taxes });
    }
  }, [taxMasterData]);

  const unitMasterOptions = useMemo(
    () => createOptions(unitMasterData, "unit_code", "unit_master_id"),
    [unitMasterData]
  );

  const customerOptions = useMemo(() => {
    return (
      allCustomers &&
      allCustomers.length > 0 &&
      allCustomers.map((item) => ({
        value: item.customers_id,
        label: item.customer_name,
        item: item,
      }))
    );
  }, [allCustomers]);

  // bank detail code
  const bankDetailOptions = useMemo(() => {
    return (
      bankDetailsMasterData &&
      bankDetailsMasterData.length > 0 &&
      bankDetailsMasterData.map((item) => ({
        value: item.company_bank_master_id,
        // label: item.bank_name - item.bank_account_number ,
        label: <p>{`${item.bank_name} - ${item.bank_account_number}`}</p>,
      }))
    );
  }, [bankDetailsMasterData]);

  const handleCancelAddBankDetail = () => {
    setAddBankDetailModalOpen(false);
    addBankDetailForm.resetFields();
  };
  const handleAddBankDetail = (values) => {
    dispatch(
      actionAddBankDetailMaster({
        values,
        handleCancel: handleCancelAddBankDetail,
        form,
      })
    );
  };

  const productOptions = useMemo(
    () => createOptions(allProducts, "material_name", "id"),
    [allProducts]
  );

  const quotationStatusOptions = useMemo(
    () =>
      createOptions(
        quotationStatusMasterData,
        "quotation_status",
        "quotation_status_master_id"
      ),
    [quotationStatusMasterData]
  );

  const showAddItemModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsAddItemModalVisible(true);
  };

  const handleAddItemModalCancel = () => {
    setIsAddItemModalVisible(false);
  };

  const onAddProduct = (values) => {
    const req = {
      ...values,
      currency_master_id: authData?.company?.currency?.currency_master_id,
    };
    dispatch(
      actionAddProduct({
        req,
        setIsAddItemModalVisible,
        addProductForm,
      })
    );
  };

  const handleProductSelect = useCallback(
    async (value, index) => {
      try {
        const { payload } = await dispatch(
          actionGetSingleProductByIndex({ id: value, index })
        );
        const selectedProduct = payload.data;

        if (selectedProduct) {
          const amount = calculateAmount(
            selectedProduct.material_rate,
            1,
            0,
            "FLAT"
          );

          // Get current items from the form
          const currentItems = form.getFieldValue("items") || [];

          // Create a new array with the updated item
          const updatedItems = [...currentItems];

          updatedItems[index] = {
            material_id: selectedProduct.id,
            quotation_items_description: selectedProduct.material_description,
            unit_master_id: selectedProduct.unit?.unit_master_id,
            rate: selectedProduct.material_rate,
            image: selectedProduct.material_image?.url,
            quantity: 1,
            discount: 0,
            discount_type: "FLAT",
            amount: amount,
          };

          form.setFieldsValue({
            items: updatedItems,
          });
          updateSummary(form);
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    },
    [dispatch, form]
  );

  const handleDiscountTypeChange = (index, value) => {
    const currentItem = form.getFieldValue(["items", index]);
    const { rate, quantity, discount } = currentItem;

    const newAmount = calculateAmount(rate, quantity, discount, value);

    form.setFieldsValue({
      items: {
        [index]: {
          ...currentItem,
          discount_type: value,
          amount: newAmount || 0,
        },
      },
    });

    updateSummary(form);
  };

  const handleRemoveItem = useCallback(
    async (index, remove) => {
      remove(index);
      setIsItemRemoved(true);

      await dispatch(removeSingleProductIndex(index));

      dispatch(adjustProductIndexes(index));

      form.validateFields(["items"]);

      setTimeout(() => updateSummary(form), 0);
    },
    [form, dispatch, updateSummary]
  );

  const columns = (fields, remove) => [
    {
      title: (
        <span className="text-[14px]">
          Items <span className="text-red-500">*</span>
        </span>
      ),

      fixed: "left",
      dataIndex: "material_id",
      align: "middle",
      width: 250,

      render: (_, record, index) => {
        const items = form.getFieldValue("items") || [];

        if (!items || !items[index]) {
          return <span>No item found</span>;
        }

        const selectedMaterialIds =
          items &&
          items.length > 0 &&
          items
            .map((item) => item?.material_id)
            .filter((id) => id !== undefined);

        // Get the current item's material_id, with a check for undefined
        const currentMaterialId = items[index]?.material_id;

        const filteredOptions = productOptions.filter(
          (option) =>
            option.value === currentMaterialId ||
            !selectedMaterialIds.includes(option.value)
        );

        return (
          <Form.Item
            className="!mb-0"
            rules={[{ required: true, message: "Please select product" }]}
            name={[index, "material_id"]}
          >
            <Select
              className="custom-select-height"
              ref={selectRef}
              allowClear
              showSearch
              listHeight={200}
              onSelect={(val) => handleProductSelect(val, index)}
              loading={getAllProductsLoader}
              disabled={getAllProductsLoader}
              placeholder="Select Product"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: "8px 0",
                    }}
                  />
                  <Space
                    style={{
                      padding: "0 8px 4px",
                    }}
                  >
                    <Button
                      onClick={showAddItemModal}
                      type="text"
                      icon={<PlusOutlined />}
                    >
                      Add item
                    </Button>
                  </Space>
                </>
              )}
              open={dropdownOpenStates[index]}
              onDropdownVisibleChange={(open) => {
                setDropdownOpenStates((prev) => ({ ...prev, [index]: open }));
              }}
              options={filteredOptions}
            />
          </Form.Item>
        );
      },
    },
    {
      title: <span className="text-[14px]">Description</span>,
      dataIndex: "quotation_items_description",
      render: (_, record, index) => (
        <Form.Item
          className="!mb-0"
          name={[index, "quotation_items_description"]}
        >
          <Input allowClear={true} placeholder="Description" />
        </Form.Item>
      ),
    },
    {
      title: <span className="text-[14px]">Image</span>,
      dataIndex: "image",
      width: 80,
      render: (_, record, index) => {
        const items = form.getFieldValue("items") || [];
        const item = items[index] || {};
        const imageUrl = item.image;

        return (
          <Form.Item className="!mb-0" name={[index, "image"]}>
            {imageUrl ? (
              <Image
                preview={{ mask: <EyeOutlined /> }}
                src={imageUrl}
                width={30}
                height={30}
                alt="product"
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <p className="text-gray-500 text-center">No Image</p>
              </div>
            )}
          </Form.Item>
        );
      },
    },
    {
      title: <span className="text-[14px]">Unit</span>,
      dataIndex: "unit_master_id",
      align: "middle",
      width: 200,
      render: (_, record, index) => (
        <Form.Item className="!mb-0" name={[index, "unit_master_id"]}>
          <Select
            className="custom-select-height"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            loading={getUnitMasterLoader}
            disabled={true}
            options={unitMasterOptions}
            placeholder="Select Unit"
            style={{ width: "100%" }}
          />
        </Form.Item>
      ),
    },
    {
      title: <span className="text-[14px]">Rate ({myCurrency})</span>,
      dataIndex: "rate",
      align: "middle",
      render: (_, record, index) => (
        <Form.Item className="!mb-0" name={[index, "rate"]}>
          <Input
            onChange={() => updateItemAmount(index, fields, form)}
            allowClear
            placeholder="00.00"
            style={{ width: "100%" }}
          />
        </Form.Item>
      ),
    },
    {
      title: <span className="text-[14px]">Quantity</span>,
      dataIndex: "quantity",
      width: 200,
      align: "middle",
      render: (_, record, index) => (
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please enter quantity",
            },
            () => ({
              validator(_, value) {
                if (value && value < 1) {
                  return Promise.reject("Quantity must be greater than 0");
                }
                return Promise.resolve();
              },
            }),
          ]}
          normalize={(value) => value && value.replace(/[^\d]/g, "")}
          className="!mb-0"
          name={[index, "quantity"]}
        >
          <Input
            onChange={() => updateItemAmount(index, fields, form)}
            min={1}
            controls={false}
            placeholder="00.00"
            style={{ width: "100%" }}
          />
        </Form.Item>
      ),
    },

    {
      title: <span className="text-[14px]">Discount</span>,
      dataIndex: "discount",
      width: 250,
      align: "middle",
      render: (_, record, index) => (
        <Space.Compact>
          <Form.Item
            normalize={(value) =>
              value && value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
            }
            className="!mb-0"
            name={[index, "discount"]}
            style={{ width: "60%" }}
          >
            <Input
              className="py-[9.5px]"
              style={{ width: "100%", borderRight: 0 }}
              min={0}
              controls={false}
              placeholder="Discount"
              onChange={() => updateItemAmount(index, fields, form)}
            />
          </Form.Item>

          <Form.Item
            className="!mb-0"
            name={[index, "discount_type"]}
            style={{ width: "40%" }}
          >
            <Select
              onChange={(value) => handleDiscountTypeChange(index, value)}
              style={{ width: "100%", borderLeft: 0 }}
            >
              <Select.Option value="PERCENT">%</Select.Option>
              <Select.Option value="FLAT">{myCurrency}</Select.Option>
            </Select>
          </Form.Item>
        </Space.Compact>
      ),
    },
    {
      title: <span className="text-[14px]">Amount</span>,
      dataIndex: "amount",
      align: "middle",
      render: (_, record, index) => (
        <Form.Item className="!mb-0" name={[index, "amount"]}>
          <Input
            disabled
            allowClear
            placeholder="00.00"
            style={{ width: "100%" }}
          />
        </Form.Item>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      align: "middle",
      render: (_, record, index) => (
        <div
          onClick={() => handleRemoveItem(index, remove)}
          className={`flex justify-center items-center gap-2 cursor-pointer ${
            fields.length === 1 ? "opacity-50 pointer-events-none" : ""
          }`}
        >
          <img src={deletemaster} alt="Delete" className="cursor-pointer" />
        </div>
      ),
    },
  ];

  function calculateAmount(rate, quantity, discount, discount_type) {
    const itemTotal = rate * quantity;
    let discountAmount = 0;

    if (discount) {
      discountAmount =
        discount_type === "PERCENT"
          ? (itemTotal * discount) / 100
          : parseFloat(discount);
    }

    return Math.max(itemTotal - discountAmount, 0);
  }

  const updateItemAmount = (index, fields, form) => {
    const { rate, quantity, discount, discount_type } = form.getFieldValue([
      "items",
      index,
    ]);

    if (rate && quantity) {
      const amount = calculateAmount(rate, quantity, discount, discount_type);
      form.setFieldsValue({
        items: {
          [index]: {
            amount: amount || 0,
            subTotal: rate,
          },
        },
      });
      updateSummary(form);
    }
  };

  function updateSummary(form) {
    const items = form.getFieldValue("items") || [];

    let total = 0;
    let totalDiscountAmount = 0;
    let subTotal = 0;

    items.forEach((item) => {
      if (item && item.rate && item.quantity) {
        const itemSubTotal = Number(item.rate) * Number(item.quantity);
        subTotal += itemSubTotal;
        total += Number(item.amount || 0);

        if (item.discount) {
          totalDiscountAmount +=
            item.discount_type === "PERCENT"
              ? (itemSubTotal * Number(item.discount)) / 100
              : Number(item.discount);
        }
      }
    });

    const totalTaxRate =
      summary.taxes.reduce((sum, tax) => sum + tax.rate, 0) / 100;
    const totalTaxAmount = total - total / (1 + totalTaxRate);
    const adjustedTotal = Math.max(total + summary.adjustment, 0);

    setSummary((prevSummary) => ({
      ...prevSummary,
      subTotal: Number(subTotal.toFixed(2)),
      totalTaxAmount: Number(totalTaxAmount.toFixed(2)),
      discountAmount: Number(totalDiscountAmount.toFixed(2)),
      total: Number(adjustedTotal.toFixed(2)),
    }));
  }

  useEffect(() => {
    updateSummary(form);
  }, [form]);

  /*  const handleAdjustmentChange = (value) => {
    const newAdjustment = Number(value) || 0;
    setSummary((prevSummary) => {
      const newSummary = { ...prevSummary, adjustment: newAdjustment };
      const newTotal = Number(
        (
          newSummary.subTotal -
          newSummary.discountAmount +
          newAdjustment
        ).toFixed(2)
      );

      return { ...newSummary, total: newTotal };
    });
  }; */

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = (values) => {
    const items = values.items.map((item) => ({
      material_id: item.material_id,
      quotation_items_description: item.quotation_items_description,
      quantity: parseInt(item.quantity),
      discount: parseFloat(item.discount),
      quotation_items_rate: parseInt(item.rate),
      discount_type: item.discount_type,
    }));

    const req = {
      ...values,
      customer_id: values.customer_id ? values.customer_id : null,
      quotation_date: values.quotation_date
        ? dayjs(values.quotation_date).format("YYYY-MM-DD")
        : null,
      expiry_date: values.expiry_date
        ? dayjs(values.expiry_date).format("YYYY-MM-DD")
        : null,
      quotation_status_master_id: values.quotation_status_master_id
        ? values.quotation_status_master_id
        : null,
      company_bank_master_id: values.company_bank_master_id
        ? values.company_bank_master_id
        : null,
      adjustment: summary.adjustment,
      items: items,
    };

    console.log({ req });

    quotationId
      ? dispatch(
          actionEditQuotation({
            id: quotationId,
            req,
            navigate,
          })
        )
      : dispatch(
          actionAddQuotation({
            req,
            navigate,
          })
        );
  };

  // add unit
  const showAddUnitForm = (form) => {
    return (
      <div className="min-w-[350px]">
        <Form
          form={form}
          onFinish={(values) =>
            dispatch(
              actionAddUnitMaster({
                values,
                handleCancel: closeUnitFormPopover,
                form: addProductForm,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="unit_code"
            label="Unit"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input unit!",
              },
            ]}
          >
            <Input placeholder="Enter Unit" className="py-[10px]" />
          </Form.Item>

          <div className="flex justify-end mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                onClick={closeUnitFormPopover}
                type="button"
                className="cancel-btn  min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addUnitMasterLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    );
  };

  const closeUnitFormPopover = () => {
    setAddUnitPopoverOpen(false);
    unitForm.resetFields();
  };

  const handleCustomerSelect = (value, option) => {
    const customer = option.item;
    form.setFieldValue("tin_number", customer.tin_number);
  };

  return (
    <Spin spinning={getSingleQuotationLoader}>
      <div className="flex items-center gap-x-3 justify-between">
        <BackArrowButton />
        <p className="text-lg font-monaSansSemiBold">
          {quotationId ? "Update Quotation" : "Create Quotation"}
        </p>
      </div>
      <Form
        initialValues={{
          quotation_date: dayjs(),
          expiry_date: dayjs(form.getFieldValue("quotation_date")).add(
            30,
            "days"
          ),
          authorized_by: "Prakash Bhat",
          items: [
            {
              quantity: 1,
              discount_type: "FLAT",
            },
          ],
        }}
        scrollToFirstError={true}
        form={form}
        layout="vertical"
        onValuesChange={(changedValues, allValues) => {
          if (
            changedValues.items /*  || changedValues.adjustment !== undefined */
          ) {
            updateSummary(form);
          }
        }}
        name="add-quotation-form"
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Card className=" mt-5 w-full">
          <CustomIconText
            image={quotationIcon}
            title={quotationId ? "Update Quotation" : "Create Quotation"}
            backArrow={true}
          />

          <Row className="mt-5" gutter={24}>
            {/* <Col {...gridSettings}>
              <Form.Item
                name="quotation_subject"
                label="Quotation Subject"
                rules={[
                  {
                    required: true,
                    message: "Please input quotation subject!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Quotation Subject"
                  className="py-[10px]"
                />
              </Form.Item>
            </Col> */}
            <Col {...gridSettings}>
              <div
                className="flex items-center cursor-pointer absolute right-0 mr-4 z-10"
                onClick={() => setIsModalOpen(true)}
              >
                <Image
                  src={plusSvg}
                  alt={"Add Icon"}
                  height={20}
                  width={20}
                  preview={false}
                />
                <h1 className="text-[15px] font-monaSansSemiBold ml-1 text-secondary">
                  Add Customer
                </h1>
              </div>

              <Form.Item
                label="Customer Name"
                name="customer_id"
                //  preserve={true}
                rules={[
                  {
                    required: true,
                    message: "Please select customer name!",
                  },
                ]}
              >
                <Select
                  showSearch
                  loading={getAllCustomersLoader}
                  disabled={getAllCustomersLoader}
                  placeholder="Select Customer"
                  optionFilterProp="children"
                  className=""
                  onSelect={handleCustomerSelect}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={customerOptions}
                />
              </Form.Item>
            </Col>
            <Col {...gridSettings}>
              <Form.Item
                normalize={(value) => value.replace(/[^\d]/g, "")}
                label="TIN Number"
                name="tin_number"
                rules={[
                  {
                    required: true,
                    message: "Please input TIN number!",
                  },
                  {
                    max: 9,
                    message: "TIN number cannot exceed 9 digits!",
                  },
                ]}
              >
                <Input className="py-[10px]" placeholder="Enter TIN Number" />
              </Form.Item>
            </Col>

            <Col {...gridSettings}>
              <Form.Item
                label="Quotations Status"
                name="quotation_status_master_id"
                rules={[
                  {
                    required: true,
                    message: "Please select quotation status!",
                  },
                ]}
              >
                <Select
                  showSearch
                  loading={getQuotationStatusMasterLoader}
                  disabled={getQuotationStatusMasterLoader}
                  placeholder="Select Quotation Status"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={quotationStatusOptions}
                />
              </Form.Item>
            </Col>

            <Col {...gridSettings}>
              <Form.Item
                label="Quotation Date"
                name="quotation_date"
                rules={[
                  {
                    required: true,
                    message: "Please select quotation date!",
                  },
                ]}
              >
                <DatePicker
               //   disabledDate={(current) => current < dayjs().startOf("day")} // Disable past dates
                  className="py-[10px] w-full"
                  placeholder="Select Quotation Date"
                  onChange={(date) => {
                    form.setFieldsValue({
                      expiry_date: date ? date.add(30, "days") : null,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col {...gridSettings}>
              <Form.Item
                label="Expiry Date"
                name="expiry_date"
                rules={[
                  {
                    required: true,
                    message: "Please select expiry date!",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) => {
                    const quotationDate = form.getFieldValue("quotation_date");
                    return (
                      quotationDate &&
                      current &&
                      current.isBefore(quotationDate, "day")
                    );
                  }}
                  className="py-[10px] w-full"
                  placeholder="Select Expiry Date"
                />
              </Form.Item>
            </Col>

            <Col {...gridSettings}>
              <Form.Item
                label="Authorised By"
                name="authorized_by"
                rules={[
                  {
                    required: true,
                    message: "Please input authorised by!",
                  },
                ]}
              >
                <Input
                  disabled
                  placeholder="Enter login person name"
                  className="py-[10px]"
                />
              </Form.Item>
            </Col>
            <Col {...gridSettings}>
              {/*   <div
                className="flex items-center cursor-pointer absolute right-0 mr-4 z-10"
                onClick={() => setAddBankDetailModalOpen(true)}
              >
                <Image
                  src={plusSvg}
                  alt={"Add Icon"}
                  height={20}
                  width={20}
                  preview={false}
                />
                <h1 className="text-[15px] font-monaSansMedium ml-1 text-secondary">
                  Add Bank Detail
                </h1>
              </div> */}

              <Form.Item
                label="Bank Detail"
                name="company_bank_master_id"
                //  preserve={true}
                rules={[
                  {
                    required: true,
                    message: "Please select bank detail!",
                  },
                ]}
              >
                <Select
                  showSearch
                  loading={getBankDetailsMasterLoader}
                  disabled={getBankDetailsMasterLoader}
                  placeholder="Select Bank Detail"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={bankDetailOptions}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card className="mt-5 w-full">
          <div className="flex items-center justify-start gap-x-3 mb-2 lg:mb-5">
            <Image
              src={quotationIcon}
              alt="image"
              height={20}
              width={20}
              preview={false}
            />

            <h1 className="lg:text-lg text-base font-monaSansSemiBold">
              Items
              <span className="text-red-500 ml-1">*</span>
            </h1>
          </div>
          <Form.List name="items">
            {(fields, { add, remove }) => (
              <ConfigProvider
                theme={{
                  components: {
                    Table: {
                      cellPaddingBlock: 5,
                    },
                    Input: {
                      paddingBlock: 8,
                      borderRadius: 5,
                    },
                    InputNumber: {
                      paddingBlock: 8,
                      borderRadius: 5,
                    },
                    Select: {
                      paddingBlock: 0,
                      borderRadius: 5,
                    },
                  },
                }}
              >
                <Table
                  loading={getSingleProductsIndexLoader}
                  columns={columns(fields, remove)}
                  dataSource={fields}
                  pagination={false}
                  bordered
                  className="mt-4 custom-table"
                  scroll={{ x: "max-content", y: 400 }}
                />
                <div
                  onClick={() =>
                    add({
                      discount_type: "FLAT",
                      quantity: 1,
                    })
                  }
                  className={`inline-flex mt-5 cursor-pointer  items-center gap-x-2 ${
                    addQuotationLoader ||
                    updateQuotationLoader ||
                    getSingleProductsIndexLoader
                      ? "opacity-50 pointer-events-none"
                      : ""
                  }`}
                >
                  <Image
                    preview={false}
                    src={addIconBlack}
                    height={20}
                    width={20}
                    alt="add-button-white"
                  />
                  <span className="text-secondary text-[20px]">Add New</span>
                </div>
              </ConfigProvider>
            )}
          </Form.List>
        </Card>
        <Card className="mt-5 w-full">
          <Row gutter={24}>
            <Col span={11}>
              <Form.Item label="Terms & Conditions" name="terms_and_conditions">
                <Input
                  className="py-[10px] w-full"
                  placeholder="Enter Terms & Conditions"
                />
              </Form.Item>

              <Form.Item label="Description" name="quotation_description">
                <Input.TextArea
                  rows={4}
                  className="py-[10px] w-full"
                  placeholder="Enter Description"
                />
              </Form.Item>
            </Col>
            <Col span={2} />
            <Col span={10}>
              <div className="p-4">
                <div className="flex justify-between mb-4">
                  <span className="text-[17px] text-black font-monaSansBold">
                    Sub Total ({myCurrency})
                  </span>
                  <span className="text-[17px] text-black font-monaSansBold">
                    {summary.subTotal ? summary.subTotal : 0}
                  </span>
                </div>

                {/*  {summary.taxes.map((tax) => (
                  <div key={tax.key} className="flex justify-between mb-4">
                    <span className="text-[17px] text-black font-monaSansMedium">
                     {tax.label} {" "} {tax.rate.toFixed(2)}%
                      <span className="text-[#7C7C7C] text-sm ml-1">Included</span>
                    </span>
                  </div>
                ))}
 */}

                {summary.taxes.map((tax) => (
                  <div key={tax.key} className="flex justify-between mb-4">
                    <span className="text-[17px] text-black font-monaSansMedium">
                      {tax.label}
                    </span>
                    <span className="text-[17px] text-[#7C7C7C] font-monaSansMedium">
                      {tax.rate.toFixed(2)}%
                    </span>
                  </div>
                ))}
                {/*   <div className="flex justify-between mb-4">
                  <span className="text-[17px] text-black font-monaSansMedium">
                    <span className="text-black">Tax Amount </span>
                    <span className="text-[#7C7C7C]">
                      (tax inclusive in price)
                    </span>{" "}
                  </span>
                  <span className="text-[17px] text-[#7C7C7C] font-monaSansMedium">
                    {summary.totalTaxAmount.toFixed(2)}
                  </span>
                </div> */}
                <div className="flex justify-between mb-4">
                  <span className="text-[17px] text-black font-monaSansMedium">
                    Discount Amount
                  </span>
                  <span className="text-[17px] text-[#7C7C7C] font-monaSansMedium">
                    - {summary.discountAmount}
                  </span>
                </div>
                {/* <div className="flex justify-between mb-4">
                  <div className="flex items-center gap-x-1">
                    <span className="text-[17px] text-black font-monaSansMedium">
                      Adjustment
                    </span>
                  </div>
                  <InputNumber
                    className="w-32 text-left py-[6px] rounded-md"
                    placeholder="0.00"
                    value={summary.adjustment}
                    onChange={handleAdjustmentChange}
                  />
                </div> */}
                <div className="flex justify-between mt-6">
                  <span className="text-xl font-monaSansBold">
                    Total ({myCurrency})
                  </span>
                  <span className="text-xl font-monaSansBold">
                    {summary.total.toFixed(2)}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Card>

        <div className="flex gap-x-3 justify-center mt-4 lg:mt-6">
          <Form.Item className="mb-0">
            <Button
              onClick={async () => {
                navigate(-1);
              }}
              type="button"
              className="cancel-btn min-h-[45px] min-w-[120px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <Button
            loading={addQuotationLoader || updateQuotationLoader}
            type="primary"
            className="root-btn min-h-[45px] min-w-[120px]"
            htmlType="submit"
          >
            {quotationId ? "Update" : "Save"}
          </Button>
        </div>
      </Form>

      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        width={1000}
        footer={false}
        style={{ top: 20 }}
        destroyOnClose={handleCancel}
      >
        <CreateCustomer
          customerForm={form}
          isModal={true}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>

      <Modal
        title={
          <p className="font-monaSansSemiBold text-lg pb-3">Add New Product</p>
        }
        open={isAddItemModalVisible}
        okText="Submit"
        onCancel={handleAddItemModalCancel}
        onOk={() => addProductForm.submit()}
        okButtonProps={{
          loading: addProductLoader,
          className: "okBtn-modal",
        }}
        cancelButtonProps={{
          className: "cancelBtn-modal",
        }}
      >
        <Form
          scrollToFirstError={true}
          form={addProductForm}
          layout="vertical"
          name="add-product-form"
          onFinish={onAddProduct}
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label="Product"
                name="material_name"
                rules={[
                  {
                    required: true,
                    message: "Please input product name!",
                  },
                ]}
              >
                <Input placeholder="Enter Product Name" className="py-[10px]" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Rate"
                name="material_rate"
                rules={[
                  {
                    required: true,
                    message: "Please input rate!",
                  },
                ]}
              >
                <InputNumber
                  controls={false}
                  type="number"
                  placeholder="Rate"
                  className="py-0 w-full"
                  suffix={
                    <div className="border-l border-l-[#BCB4B2] py-[10px] pl-[10px]">
                      <p className="text-[#8E8A8B] text-[15px] font-monaSansRegular">
                        {authData?.company?.currency?.currency?.currency}
                      </p>
                    </div>
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <AddPopover
                content={() => showAddUnitForm(unitForm)}
                isOpen={addUnitPopoverOpen}
                onOpenChange={(visible) => {
                  if (!visible) {
                    setAddUnitPopoverOpen(false);
                    unitForm.resetFields();
                  }
                }}
                text="Add Unit"
                showModal={() => setAddUnitPopoverOpen(!addUnitPopoverOpen)}
              />
              <Form.Item
                label="Unit"
                name="unit_master_id"
                rules={[
                  {
                    required: true,
                    message: "Please select unit!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  loading={getUnitMasterLoader}
                  disabled={getUnitMasterLoader}
                  placeholder="Select Unit"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={unitMasterOptions}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        open={addBankDetailModalOpen}
        className="input-custom"
        centered
        onOk={() => handleCancelAddBankDetail()}
        onCancel={() => handleCancelAddBankDetail()}
        footer={false}
        width={500}
        title=""
      >
        <h1 className="text-black text-[20px] mt-[5px] font-monaSansMedium">
          Bank Details
        </h1>
        <Form
          form={addBankDetailForm}
          onFinish={handleAddBankDetail}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="bank_name"
            label="Bank Name"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input bank name!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Bank Name"
              className="bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>
          <Form.Item
            name="bank_beneficiary"
            label="Beneficiary"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input beneficiary!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Beneficiary"
              className="bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>
          <Form.Item
            name="bank_swift_code"
            label="Swift Code"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input swift code!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Swift Code"
              className="bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>
          <Form.Item
            normalize={(value) => value && value.replace(/[^\d]/g, "")}
            name="bank_account_number"
            label="A/C No"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input account number!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Account Number"
              className="bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <div className="flex justify-center mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                onClick={handleCancelAddBankDetail}
                type="button"
                className="cancel-btn min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  type="primary"
                  loading={addBankDetailsMasterLoader}
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </Spin>
  );
};

export default React.memo(CreateQuotation);
