import { Button, Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="min-h-svh flex justify-center items-center">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link to="/">
            <Button
              type="primary"
              className="root-btn mt-3 text-sm  border-none rounded-[10px]  min-h-[40px]"
              htmlType="submit"
            >
              Back to Dashboard
            </Button>
          </Link>
        }
      />
    </div>
  );
};

export default NotFound;
