import { createSlice } from "@reduxjs/toolkit";
import { actionAddRoleMaster, actionAssignRoleProfilePermission, actionDeleteRoleMaster, actionEditRoleMaster, actionGetRoleMaster, actionGetSingleRoleMaster } from "../services/roleService";

const roleSlice = createSlice({
  name: "role",
  initialState: {
    getRoleMasterLoader:false,
    roleMasterData:"",
    getSingleRoleMasterLoader:false,
    getSingleRoleMasterData:"",
    addRoleMasterLoader:false,
    deleteRoleMasterLoader:{},
    updateRoleMasterLoader:{},
    assignRoleProfilePermissionLoader:false

  },
  extraReducers: (builder) => {
    builder
      //get all role start here
      .addCase(actionGetRoleMaster.pending, (state) => {
        state.getRoleMasterLoader = true;
      })
      .addCase(actionGetRoleMaster.fulfilled, (state, action) => {
        state.getRoleMasterLoader = false;
        state.roleMasterData = action.payload.data;
      })
      .addCase(actionGetRoleMaster.rejected, (state) => {
        state.getRoleMasterLoader = false;
      })

      //get single role
      .addCase(actionGetSingleRoleMaster.pending, (state) => {
        state.getSingleRoleMasterLoader = true;
      })
      .addCase(actionGetSingleRoleMaster.fulfilled, (state, action) => {
        state.getSingleRoleMasterData = action.payload.data;
        state.getSingleRoleMasterLoader = false;
      })
      .addCase(actionGetSingleRoleMaster.rejected, (state) => {
        state.getSingleRoleMasterLoader = false;
      })

      // add role
      .addCase(actionAddRoleMaster.pending, (state) => {
        state.addRoleMasterLoader = true;
      })
      .addCase(actionAddRoleMaster.fulfilled, (state, action) => {
        state.addRoleMasterLoader = false;
        if (action.payload) {
          if (
            !state.roleMasterData ||
            !Array.isArray(state.roleMasterData)
          ) {
            state.roleMasterData = [action.payload];
          } else {
            state.roleMasterData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddRoleMaster.rejected, (state) => {
        state.addRoleMasterLoader = false;
      })

      //delete role
      .addCase(actionDeleteRoleMaster.pending, (state, action) => {
        state.deleteRoleMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionDeleteRoleMaster.fulfilled, (state, action) => {
        state.roleMasterData = state.roleMasterData.filter(
          (type) => type.id !== action.payload
        );
        state.deleteRoleMasterLoader[action.meta.arg.id] = false;
      })
      .addCase(actionDeleteRoleMaster.rejected, (state, action) => {
        state.deleteRoleMasterLoader[action.meta.arg.id] = false;
      })

      //update role
      .addCase(actionEditRoleMaster.pending, (state, action) => {
        state.updateRoleMasterLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditRoleMaster.fulfilled, (state, action) => {
        state.updateRoleMasterLoader[action.meta.arg.id] = false;
        state.roleMasterData = state.roleMasterData.map((type) =>
          type.id === action.payload.id
            ? {
                ...type,
                name: action.payload.name,
                description: action.payload.description,
              }
            : type
        );
      })
      .addCase(actionEditRoleMaster.rejected, (state, action) => {
        state.updateRoleMasterLoader[action.meta.arg.id] = false;
      })

      .addCase(actionAssignRoleProfilePermission.pending, (state) => {
        state.assignRoleProfilePermissionLoader = true;
      })
      .addCase(actionAssignRoleProfilePermission.fulfilled, (state, action) => {
        state.assignRoleProfilePermissionLoader = false;
      })
      .addCase(actionAssignRoleProfilePermission.rejected, (state) => {
        state.assignRoleProfilePermissionLoader = false;
      })
  },
});

export default roleSlice.reducer;
 