import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import BackArrowButton from "../common/BackArrowButton";
import CustomIconText from "../common/CustomIconText";
import userIconSingle from "../../assets/img/userIconSingle.svg";
import addressIcon from "../../assets/img/addressIcon.svg";
import AddPopover from "../common/AddPopover";
import { useDispatch, useSelector } from "react-redux";
import PhoneInputComp from "../common/PhoneInputComponent";
import { useLocation, useNavigate } from "react-router-dom";
// import { PhoneInput } from "react-international-phone";
import { toast } from "sonner";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import editPrimaryWhite from "../../assets/img/editPrimaryWhite.svg";
import {
  actionAddAddressTypeMaster,
  actionGetAddressTypeMaster,
} from "../../store/services/addressTypeMasterService";
import {
  actionAddCountryTypeMaster,
  actionGetAllCountry,
} from "../../store/services/countryMasterService";
import {
  actionAddUser,
  actionEditUser,
  actionGetSingleUser,
} from "../../store/services/userService";
import {
  actionGetAllStates,
  actionGetStates,
} from "../../store/services/stateMasterService";
import { actionGetRoleMaster } from "../../store/services/roleService";
import PhoneInputComponent from "../common/PhoneInputComponent";
import { BASE_URL_UPLOAD } from "../../config/web-config";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CreateUser = () => {
  const [addAddressTypePopoverOpen, setAddAddressTypePopoverOpen] =
    useState(false);
  const [userProfilePicUrl, setUserProfilePicUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [countrySelected, setCountrySelected] = useState(false);
  const [value, setValue] = useState({
    fullPhoneNumber: "",
    iso: "in",
    phone: "",
    isValid: true,
    dialCode: "91",
  });

  const [submitted, setSubmitted] = useState(false);

  const [AddressTypForm] = Form.useForm();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { userId, own } = location.state || {};

  const {
    addUserLoader,
    updateUserLoader,
    getSingleUserData,
    getSingleUserLoader,
  } = useSelector((state) => state.user);

  const {
    addAddressTypeMasterLoader,
    addressTypeMasterData,
    getAddressTypeMasterLoader,
  } = useSelector((state) => state.addressTypeMaster);

  const { allCountryMasterData, getAllCountryMasterLoader } = useSelector(
    (state) => state.countryMaster
  );
  const { getAllStatesLoader, allStatesData } = useSelector(
    (state) => state.stateMaster
  );
  const { getRoleMasterLoader, roleMasterData } = useSelector(
    (state) => state.role
  );

  useEffect(() => {
    dispatch(actionGetAddressTypeMaster());
    dispatch(actionGetAllCountry());
    dispatch(actionGetRoleMaster());
  }, [dispatch]);

  useEffect(() => {
    if (userId && getSingleUserData && getSingleUserData.country) {
      dispatch(actionGetAllStates(getSingleUserData.country.id));
      setCountrySelected(true);
    }
  }, [userId, getSingleUserData, dispatch]);

  useEffect(() => {
    if (userId) {
      dispatch(actionGetSingleUser(userId));
    }
  }, [userId]);

  useEffect(() => {
    if (getSingleUserData && userId) {
      form.setFieldsValue({
        first_name: getSingleUserData.first_name,
        last_name: getSingleUserData.last_name,
        email: getSingleUserData.email,
        // phone: getSingleUserData.phone,

        pin_code: getSingleUserData.pin_code,
        country_id: getSingleUserData.country
          ? getSingleUserData.country.id
          : null,
        states_id: getSingleUserData.states
          ? getSingleUserData.states.id
          : null,
        city_district: getSingleUserData.city_district,
        department: getSingleUserData.department,
        role_id: getSingleUserData.role ? getSingleUserData.role.id : null,
        address_type_master_id: getSingleUserData.address_type_master
          ? getSingleUserData.address_type_master.address_type_master_id
          : null,
        address_line_1: getSingleUserData.address_line_1,
        address_line_2: getSingleUserData.address_line_2,
      });

      setUserProfilePicUrl(getSingleUserData.profile_pic);

      const fullPhoneNumber = `+${getSingleUserData.country_code}${getSingleUserData.phone}`;

      setValue({
        ...value,
        fullPhoneNumber,
        iso: getSingleUserData?.phone_country_code?.toLowerCase(),
        phone: getSingleUserData.phone,
        isValid: true,
        dialCode: getSingleUserData.country_code,
      });
    }
  }, [getSingleUserData, userId]);

  const addressTypeOptions = useMemo(() => {
    return (
      addressTypeMasterData &&
      addressTypeMasterData.length > 0 &&
      addressTypeMasterData.map((item) => ({
        value: item.address_type_master_id,
        label: item.address_type,
      }))
    );
  }, [addressTypeMasterData]);

  const countryOptions = useMemo(() => {
    return (
      allCountryMasterData &&
      allCountryMasterData.length > 0 &&
      allCountryMasterData.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
  }, [allCountryMasterData]);

  const stateOptions = useMemo(() => {
    return (
      allStatesData &&
      allStatesData.length > 0 &&
      allStatesData.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
  }, [allStatesData]);

  const rolesOptions = useMemo(() => {
    return (
      roleMasterData &&
      roleMasterData.length > 0 &&
      roleMasterData.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
  }, [roleMasterData]);

  // state and country
  const handleGetState = (val, data) => {
    dispatch(actionGetAllStates(data.value));
    setCountrySelected(true);
    form.setFieldsValue({
      states_id: null,
    });
  };

  const handleClearState = () => {
    setCountrySelected(false);
    form.setFieldsValue({
      states_id: null,
    });
  };

  const handleChangeUserProfilePic = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setUserProfilePicUrl(info.file.response.data);
        toast.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  // add address type
  const showAddAddressTypeForm = () => {
    return (
      <div className="min-w-[350px]">
        <Form
          form={AddressTypForm}
          onFinish={(values) =>
            dispatch(
              actionAddAddressTypeMaster({
                values,
                handleCancel: closeAddressTypeFormPopover,
                form,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="address_type"
            label="Address Type Name"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input address type name!",
              },
            ]}
          >
            <Input
              placeholder="Enter Address Type Name"
              className="py-[10px]"
            />
          </Form.Item>

          <div className="flex justify-end mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                onClick={closeAddressTypeFormPopover}
                type="button"
                className="cancel-btn  min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addAddressTypeMasterLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    );
  };

  const closeAddressTypeFormPopover = () => {
    setAddAddressTypePopoverOpen(false);
    AddressTypForm.resetFields();
  };

  const handlePhoneChange = (
    fullPhoneNumber,
    phone,
    iso,
    isValid,
    dialCode
  ) => {
    setValue({
      phone: phone,
      fullPhoneNumber: fullPhoneNumber,
      iso: iso,
      isValid: isValid,
      dialCode,
    });
  };

  const handleSubmit = (values) => {
    setSubmitted(true);

    if (value.isValid) {
      const req = {
        ...values,
        phone_country_code: value.iso ? value.iso.toUpperCase() : null,
        states_id: values.states_id ? values.states_id : null,
        country_id: values.country_id ? values.country_id : null,
        role_id: values.role_id ? values.role_id : null,
        address_type_master_id: values.address_type_master_id
          ? values.address_type_master_id
          : null,
        phone: value.phone || null,
        country_code: value.dialCode || null,
        profile_pic_id: userProfilePicUrl ? userProfilePicUrl.upload_id : null,
      };
      userId
        ? dispatch(actionEditUser({ id: userId, req, navigate }))
        : dispatch(actionAddUser({ req, navigate }));
    }
  };

  const onFinishFailed = ({ values, errorFields }) => {
    console.log(errorFields);
  };

  return (
    <Spin spinning={getSingleUserLoader}>
      <div className="flex items-center gap-x-3 justify-between">
        <BackArrowButton />
        <p className="text-lg font-monaSansSemiBold">
          {userId ? "Update User" : "Create User"}
        </p>
      </div>
      <Form
        scrollToFirstError={true}
        form={form}
        initialValues={{
          phone_country_code: "in",
        }}
        layout="vertical"
        name="basic"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Card
          className={`mt-3 w-full ${
            addUserLoader || updateUserLoader
              ? "opacity-50 pointer-events-none"
              : ""
          }`}
        >
          <CustomIconText
            image={userIconSingle}
            title={"User"}
            backArrow={true}
          />
          <Row gutter={24}>
            <Col span={24} align="middle">
              <Form.Item
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: false,
                    message: "Please enter profile pic!",
                  },
                ]}
              >
                {userProfilePicUrl ? (
                  <div className="relative border-secondary  h-[102px]  w-[102px] rounded-[6px] mb-2">
                    <img
                      src={userProfilePicUrl?.url}
                      alt="avatar"
                      className="h-full w-full rounded-[6px]"
                    />

                    <ImgCrop rotationSlider aspect={1 / 1} fillColor="white">
                      <Upload
                        id=""
                        accept=".png,.jpg,.jpeg,.pdf,.doc"
                        name="file"
                        listType="picture-card"
                        className="border-0 absolute main-upload bottom-0 right-0 avatar-uploader"
                        showUploadList={false}
                        action={BASE_URL_UPLOAD}
                        openFileDialogOnClick={true}
                        headers={{
                          Authorization:
                            "Bearer " + localStorage.getItem("qmsToken"),
                        }}
                        onChange={handleChangeUserProfilePic}
                      >
                        <div className=" px-1.5 py-1 border-0 absolute right-0 bottom-0 bg-red-500 rounded-tl-[6px] rounded-br-[6px] cursor-pointer flex">
                          <Image
                            width={15}
                            height={15}
                            src={editPrimaryWhite}
                            className="rounded-lg"
                            preview={false}
                          />
                        </div>
                      </Upload>
                    </ImgCrop>
                  </div>
                ) : (
                  <ImgCrop aspect={1 / 1} rotationSlider fillColor="white">
                    <Upload
                      name="file"
                      listType="picture-card"
                      className="avatar-uploader mb-2"
                      showUploadList={false}
                      action={BASE_URL_UPLOAD}
                      openFileDialogOnClick={true}
                      headers={{
                        Authorization:
                          "Bearer " + localStorage.getItem("qmsToken"),
                      }}
                      onChange={handleChangeUserProfilePic}
                    >
                      <button
                        style={{
                          border: 0,
                          background: "none",
                        }}
                        type="button"
                      >
                        {loading ? (
                          <LoadingOutlined />
                        ) : (
                          <PlusOutlined style={{ color: "#2B2526" }} />
                        )}
                        <div
                          style={{
                            marginTop: 8,
                          }}
                        >
                          Profile
                        </div>
                      </button>
                    </Upload>
                  </ImgCrop>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="First Name"
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: "Please input first name!",
                  },
                ]}
              >
                <Input placeholder="Enter First Name" className="py-[10px]" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[
                  {
                    required: false,
                    message: "Please input last name!",
                  },
                ]}
              >
                <Input placeholder="Enter Last Name" className="py-[10px]" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    message: "Please input valid email!",
                  },
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  disabled={userId}
                  placeholder="Email"
                  className="py-[10px]"
                />
              </Form.Item>
            </Col>
            {!userId && (
              <Col span={12}>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    () => ({
                      validator(_, value) {
                        if (value && value.length < 8) {
                          return Promise.reject(
                            "Please enter a valid 8-digit password!"
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    className="py-[10px]"
                  />
                </Form.Item>
              </Col>
            )}
            <Col xs={24} md={12}>
              <PhoneInputComponent
                required={false}
                value={value.fullPhoneNumber}
                onChange={handlePhoneChange}
                isValid={value.isValid}
                submitted={submitted}
              />
            </Col>
            <Col span={12}>
              <Form.Item
                label="Department"
                name="department"
                rules={[
                  {
                    required: false,
                    message: "Please input department!",
                  },
                ]}
              >
                <Input placeholder="Enter Department" className="py-[10px]" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Role"
                name="role_id"
                rules={[
                  {
                    required: true,
                    message: "Please select role!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  loading={getRoleMasterLoader}
                  disabled={getRoleMasterLoader}
                  placeholder="Select Role"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={rolesOptions}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Card className=" mt-3 w-full">
          <CustomIconText
            image={addressIcon}
            title={"Address"}
            backArrow={true}
          />
          <Row gutter={24}>
            <Col span={12}>
              <AddPopover
                content={showAddAddressTypeForm}
                isOpen={addAddressTypePopoverOpen}
                onOpenChange={(visible) => {
                  if (!visible) {
                    setAddAddressTypePopoverOpen(false);
                    AddressTypForm.resetFields();
                  }
                }}
                text="Add Address Type"
                showModal={() =>
                  setAddAddressTypePopoverOpen(!addAddressTypePopoverOpen)
                }
              />
              <Form.Item label="Address Type" name="address_type_master_id">
                <Select
                  allowClear
                  showSearch
                  loading={getAddressTypeMasterLoader}
                  disabled={getAddressTypeMasterLoader}
                  placeholder="Select Address Type"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  // options={categoryOptions}
                  options={addressTypeOptions}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Address Line 1" name="address_line_1">
                <Input placeholder="Enter Address" className="py-[10px]" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Address Line 2" name="address_line_2">
                <Input placeholder="Enter Address" className="py-[10px]" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Country"
                name="country_id"
                rules={[
                  {
                    required: true,
                    message: "Please select country!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  loading={getAllCountryMasterLoader}
                  disabled={getAllCountryMasterLoader}
                  onClear={handleClearState}
                  onSelect={handleGetState}
                  placeholder="Select Country"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={countryOptions}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="State"
                name="states_id"
                rules={[
                  {
                    required: true,
                    message: "Please select state!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  loading={getAllStatesLoader}
                  disabled={!countrySelected}
                  placeholder="Select State"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={stateOptions}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="City / District"
                name="city_district"
                rules={[
                  {
                    required: true,
                    message: "Please input city / district!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter City / District"
                  className="py-[10px]"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Postal Code / Zip Code / Pin Code"
                name="pin_code"
                rules={[
                  {
                    required: false,
                    message: "Please input postal Code / zip Code / pin Code!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Postal Code / Zip Code / Pin Code"
                  className="py-[10px]"
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Row gutter={24}>
          <Col span={24} className="flex gap-x-3 justify-center mt-2 lg:mt-4">
            <Button
              onClick={async () => {
                navigate(-1);
              }}
              type="button"
              className="cancel-btn min-h-[45px] min-w-[120px]"
            >
              Cancel
            </Button>

            <Form.Item className="mb-0">
              <Button
                loading={addUserLoader || updateUserLoader}
                type="primary"
                className="root-btn min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                {userId ? "Update" : "Save"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default CreateUser;
