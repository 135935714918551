import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import axios from "axios";
import { toast } from "sonner";
import { handleDeleteResponse, handleGetResponse } from "./commonService";
import { handleErrorMessage } from "../../components/helper/common-function";

//get all listing of the bank details
export const actionGetBankDetailsMaster = createAsyncThunk(
  "actionGetBankDetailsMaster",
  async ({ search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `company-bank-master?getall=YES${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//add bank details
export const actionAddBankDetailMaster = createAsyncThunk(
  "actionAddBankDetailMaster",
  async ({ values, handleCancel, form }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/company-bank-master`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        form &&
          form.setFieldsValue({
            company_bank_master_id: data?.company_bank_master_id,
          });
        handleCancel && handleCancel();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//delete bank details
export const actionDeleteBankDetailMaster = createAsyncThunk(
  "actionDeleteBankDetailMaster",
  async ({ id, setEditingKey }) => {
    return handleDeleteResponse(`company-bank-master/${id}`, id, setEditingKey);
  }
);

//get single bank details
export const actionGetSingleBankDetailsMaster = createAsyncThunk(
  "actionGetSingleBankDetailsMaster",
  (id) => {
    return handleGetResponse(`company-bank-master/${id}`);
  }
);

//update bank details
export const actionEditBankDetailsMaster = createAsyncThunk(
  "actionEditBankDetailsMaster",
  async ({ id, req, setEditingKey, setEdited }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/company-bank-master/${id}`,
        req
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setEditingKey && setEditingKey("");
        setEdited && setEdited(false);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
