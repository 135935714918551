import { toast } from "sonner";

export const getFirstLetterCapital = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function formatString(input) {
  let formatted = input?.replace(/[^a-zA-Z0-9\s/]/g, " ");
  formatted = formatted?.toLowerCase();
  formatted = formatted?.replace(/(?:^|[\s/])\w/g, (char) =>
    char.toUpperCase()
  );
  return formatted?.trim();
}

export const handleErrorMessage = (error) => {
  const message = error.response?.data?.message;

  if (Array.isArray(message)) {
    toast.error(message[0]);
  } else if (message && message !== null) {
    toast.error(message);
  } else {
    toast.error(error.message);
  }

  throw new Error(error.message);
};

export const ALLOWED_SEARCH_REGEX = /^[a-zA-Z0-9 @.-]*$/;
export const isValidSearchString = (value) => ALLOWED_SEARCH_REGEX.test(value);

export const getFullName = (firstName, lastName) => {
  const isValidName = (name) =>
    name !== null && name !== undefined && name.trim() !== "";

  // Capitalize first letter of both names
  const formattedFirstName = isValidName(firstName)
    ? getFirstLetterCapital(firstName)
    : "";
  const formattedLastName = isValidName(lastName)
    ? getFirstLetterCapital(lastName)
    : "";

  if (formattedFirstName && formattedLastName) {
    return `${formattedFirstName} ${formattedLastName}`;
  }
  return formattedFirstName || formattedLastName || "-";
};

/* export function formatAddress(address) {
  const addressLine1 = address.address_line_1 || "";
  const addressLine2 = address.address_line_2
    ? address.address_line_2 + ", "
    : "";
  const city = address.city_district || "";
  const state = address.states ? address.states.name : "";
  const country = address.country ? address.country.name : "";
  const pinCode = address.pin_code || "";

  return {
    addressLine1: shortenText(addressLine1, 28),
    addressLine2: shortenText(addressLine2, 28),
    city: shortenText(city, 28),
    state: shortenText(state, 28),
    country: shortenText(country, 18),
    pinCode: shortenText(pinCode, 10),
  };
} */

export function formatAddress(address) {
  const addressLine1 = address?.address_line_1 || ""; // Use address_line_1 if present
  const addressLine2 = address?.address_line_2
    ? address.address_line_2 + ", "
    : ""; // Add address_line_2 if present
  const city = address?.city_district || ""; // Use city_district if present
  const state = address?.states ? address.states.name : ""; // Use state name if present
  const pinCode = address?.pin_code || ""; // Use pin_code if present
  const country = address?.country ? address.country.name : ""; // Use country name if present

  // Concatenate state and pin code on the same line
  return `${addressLine1}, ${addressLine2}${city}, ${state}, ${pinCode}, ${country}`;
}

function numberToWords(num) {
  const singleDigits = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const twoDigits = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tensMultiple = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const placeValues = ["", "Thousand", "Million", "Billion", "Trillion"];

  function getHundreds(n) {
    let result = "";
    if (n > 99) {
      result += singleDigits[Math.floor(n / 100)] + " Hundred ";
      n %= 100;
    }
    if (n > 19) {
      result += tensMultiple[Math.floor(n / 10)] + " ";
      n %= 10;
    }
    if (n > 0) {
      result += (n < 10 ? singleDigits[n] : twoDigits[n - 10]) + " ";
    }
    return result.trim();
  }

  function convertNumberToWords(n) {
    if (n === 0) return "Zero";

    let wordRepresentation = "";
    let place = 0;

    while (n > 0) {
      const chunk = n % 1000;
      if (chunk > 0) {
        wordRepresentation =
          getHundreds(chunk) +
          " " +
          placeValues[place] +
          " " +
          wordRepresentation;
      }
      n = Math.floor(n / 1000);
      place++;
    }

    return wordRepresentation.trim();
  }

  return convertNumberToWords(num);
}

export function amountInWords(amount, currency) {
  if (!amount || isNaN(amount)) {
    return "-"; // Return a fallback value if amount is invalid
  }
  const amountParts = amount.toFixed(2).split(".");
  const integerPart = parseInt(amountParts[0]);
  const decimalPart = parseInt(amountParts[1]);

  let currencyInWords = "";
  switch (currency) {
    case "USD":
      currencyInWords = numberToWords(integerPart) + " Dollars";
      if (decimalPart > 0)
        currencyInWords += " and " + numberToWords(decimalPart) + " Cents";
      break;
    case "EUR":
      currencyInWords = numberToWords(integerPart) + " Euros";
      if (decimalPart > 0)
        currencyInWords += " and " + numberToWords(decimalPart) + " Cents";
      break;
    case "INR":
      currencyInWords = numberToWords(integerPart) + " Rupees";
      if (decimalPart > 0)
        currencyInWords += " and " + numberToWords(decimalPart) + " Paise";
      break;
    case "RWF":
      currencyInWords = numberToWords(integerPart) + " Rwandan Franc";
      if (decimalPart > 0)
        currencyInWords += " and " + numberToWords(decimalPart) + " Centimes";
      break;
    default:
      currencyInWords = numberToWords(integerPart);
      if (decimalPart > 0)
        currencyInWords +=
          " and " + numberToWords(decimalPart) + " Fractional Units";
      break;
  }

  return currencyInWords + " Only";
}
