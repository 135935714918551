import { createSlice } from "@reduxjs/toolkit";
import {
  actionGetUsers,
  actionAddUser,
  actionDeleteUser,
  actionEditUser,
  actionGetSingleUser,
} from "../services/userService";

const userSlice = createSlice({
  name: "users",
  initialState: {
    getUsersLoader: false,
    addUserLoader: false,
    deleteUserLoader: false,
    updateUserLoader: false,
    getSingleUserData: {},
    getSingleUserLoader: false,
    usersData: [],
    allUsersCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      //get all users
      .addCase(actionGetUsers.pending, (state) => {
        state.getUsersLoader = true;
      })
      .addCase(actionGetUsers.fulfilled, (state, action) => {
        state.getUsersLoader = false;
        state.users = action.payload.data;
        state.allUsersCount = action.payload.total_records;
      })
      .addCase(actionGetUsers.rejected, (state) => {
        state.getUsersLoader = false;
      })

      //add user
      .addCase(actionAddUser.pending, (state) => {
        state.addUserLoader = true;
      })
      .addCase(actionAddUser.fulfilled, (state, action) => {
        state.addUserLoader = false;
        if (!state.users || !Array.isArray(state.users)) {
          state.users = [action.payload];
        } else {
          state.users.unshift(action.payload);
        }
      })
      .addCase(actionAddUser.rejected, (state) => {
        state.addUserLoader = false;
      })

      //delete user
      .addCase(actionDeleteUser.pending, (state, action) => {
        state.deleteUserLoader = true;
      })
      .addCase(actionDeleteUser.fulfilled, (state, action) => {
        state.users = state.users.filter((user) => user.id !== action.payload);
        state.deleteUserLoader = false;
      })
      .addCase(actionDeleteUser.rejected, (state, action) => {
        state.deleteUserLoader = false;
      })

      //get single user
      .addCase(actionGetSingleUser.pending, (state) => {
        state.getSingleUserLoader = true;
      })
      .addCase(actionGetSingleUser.fulfilled, (state, action) => {
        state.getSingleUserData = action.payload.data;
        state.getSingleUserLoader = false;
      })
      .addCase(actionGetSingleUser.rejected, (state) => {
        state.getSingleUserLoader = false;
      })

      //update user
      .addCase(actionEditUser.pending, (state, action) => {
        state.updateUserLoader = true;
      })
      .addCase(actionEditUser.fulfilled, (state, action) => {
        state.updateUserLoader = false;
      })
      .addCase(actionEditUser.rejected, (state, action) => {
        state.updateUserLoader = false;
      });
  },
});

export default userSlice.reducer;
