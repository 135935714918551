import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Radio,
  Row,
  Spin,
  Switch,
} from "antd";
import customerIcon from "../../assets/img/customerIcon.svg";
import addressIcon from "../../assets/img/addressIcon.svg";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
/* import {
    actionAssignRoleProfilePermission
} from "../../../store/services/settingService"; */
import { formatString, getFirstLetterCapital } from "../helper/common-function";
import BackArrowButton from "../common/BackArrowButton";
import CustomIconText from "../common/CustomIconText";
import {
  actionAssignRoleProfilePermission,
  actionGetSingleRoleMaster,
} from "../../store/services/roleService";

const ModulePermissions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { roleId } = location.state || {};
  const {
    getSingleRoleMasterLoader,
    getSingleRoleMasterData,
    assignRoleProfilePermissionLoader,
  } = useSelector((state) => state.role);

  const [addRoleForm] = Form.useForm();
  const [selectedKey, setSelectedKey] = useState("");
  const [switchStates, setSwitchStates] = useState({});
  const [globalPermissions, setGlobalPermissions] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const determineSwitchState = (permissions) => {
    return permissions.some((perm) => perm.is_selected);
  };

  useEffect(() => {
    if (roleId) {
      dispatch(actionGetSingleRoleMaster(roleId));
    }
  }, [roleId, dispatch]);

  useEffect(() => {
    if (
      getSingleRoleMasterData &&
      getSingleRoleMasterData.access_rights.length > 0
    ) {
      setSelectedKey(
        getSingleRoleMasterData.access_rights[0].resource_category_id
      );

      const initialSwitchStates = {};
      const initialGlobalPermissions = {};
      getSingleRoleMasterData.access_rights.forEach((section) => {
        if (section.resources && section.resources.length > 0) {
          section.resources.forEach((data) => {
            initialSwitchStates[data.resources_id] = determineSwitchState(
              data.permissions
            );
            initialGlobalPermissions[data.resources_id] = initializePermissions(
              data.permissions
            );
          });
        }
      });
      setSwitchStates(initialSwitchStates);
      setGlobalPermissions(initialGlobalPermissions);
    }
  }, [getSingleRoleMasterData]);

  const initializePermissions = (permissions) => {
    const initialSelected = {};
    const initialChecked = {};
    permissions.forEach((perm) => {
      if (!initialChecked[perm.actions]) {
        initialChecked[perm.actions] = false;
      }
      if (perm.is_selected) {
        initialChecked[perm.actions] = true;
        initialSelected[perm.actions] = perm.possessions;
      }
    });
    return { selected: initialSelected, checked: initialChecked };
  };

  const handleSwitchChange = useCallback((resourcesId, checked) => {
    setSwitchStates((prevState) => ({
      ...prevState,
      [resourcesId]: checked,
    }));

    if (checked) {
      setGlobalPermissions((prevState) => ({
        ...prevState,
        [resourcesId]: {
          checked: { create: true, read: true, update: true, delete: true },
        },
      }));
    } else {
      setGlobalPermissions((prevState) => ({
        ...prevState,
        [resourcesId]: {
          checked: { create: false, read: false, update: false, delete: false },
        },
      }));
    }

    setUnsavedChanges(true);
  }, []);

  const handleCheckboxChange = useCallback((resourcesId, action, checked) => {
    setGlobalPermissions((prevState) => {
      const updatedPermissions = {
        ...prevState,
        [resourcesId]: {
          ...prevState[resourcesId],
          checked: {
            ...prevState[resourcesId]?.checked,
            [action]: checked,
          },
        },
      };

      // Automatically set read checkbox based on create, update, delete
      if (
        checked &&
        (action === "create" || action === "update" || action === "delete")
      ) {
        updatedPermissions[resourcesId] = {
          ...updatedPermissions[resourcesId],
          checked: {
            ...updatedPermissions[resourcesId]?.checked,
            read: true,
          },
        };
      } else if (!checked && action === "read") {
        updatedPermissions[resourcesId] = {
          ...updatedPermissions[resourcesId],
          checked: {
            create: false,
            update: false,
            delete: false,
            read: false,
          },
        };
      }

      // Check if all actions are false, then turn off the switch
      const allFalse = Object.keys(
        updatedPermissions[resourcesId]?.checked
      ).every((key) => !updatedPermissions[resourcesId]?.checked[key]);

      setSwitchStates((prevState) => ({
        ...prevState,
        [resourcesId]: !allFalse,
      }));

      return updatedPermissions;
    });

    setUnsavedChanges(true);
  }, []);

  const handleSavePermissions = async () => {
    const updatedRoleProfileDetail = JSON.parse(
      JSON.stringify(getSingleRoleMasterData)
    );

    const permissionsArray = [];

    await updatedRoleProfileDetail.access_rights.forEach((section) => {
      if (section.resources) {
        section.resources.forEach((resource) => {
          const permissions = resource.permissions;
          const globalPermission = globalPermissions[resource.resources_id];

          if (globalPermission && globalPermission.checked) {
            permissions.forEach((perm) => {
              if (globalPermission.checked[perm.actions]) {
                perm.is_selected = true;
              } else {
                perm.is_selected = false;
              }

              permissionsArray.push({
                permission_id: perm.permissions_id,
                is_selected: perm.is_selected,
              });
            });
          } else {
            console.log(
              `No globalPermission found for resource id ${resource.resources_id}`
            );
          }
        });
      }
    });

    const req = {
      role_id: roleId,
      permission: permissionsArray,
    };
    dispatch(actionAssignRoleProfilePermission({ req, setUnsavedChanges }));
  };

  return (
    <Spin spinning={getSingleRoleMasterLoader}>
      <section className="main-section">
        <div className="flex justify-between">
          <BackArrowButton />
          <div className="flex justify-between items-center">
            <Button
              loading={assignRoleProfilePermissionLoader}
              disabled={!unsavedChanges}
              onClick={handleSavePermissions}
              className="cancel-btn  min-h-[35px] min-w-[120px]"
            >
              Save Permissions
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-start gap-x-3 bg-white border border-[#BCB4B2] mt-2 px-3 py-2 rounded-lg">
          <Image
            src={customerIcon}
            alt="image"
            height={20}
            width={20}
            preview={false}
          />
          <h1 className="lg:text-lg text-base font-monaSansSemiBold">
            {getSingleRoleMasterData?.name}
          </h1>
        </div>
        {getSingleRoleMasterData?.access_rights &&
          getSingleRoleMasterData?.access_rights.length > 0 &&
          getSingleRoleMasterData?.access_rights.map((accessRight) => (
            <Card className="mt-3" key={accessRight.resource_category_id}>
              <CustomIconText
                image={addressIcon}
                title={
                  accessRight.resource_category_name[0] +
                  accessRight.resource_category_name.slice(1).toLowerCase()
                }
                backArrow={true}
              />
              <div className="h-full flex">
                <div className="px-10 xl:w-[75%] w-[80%] pt-7">
                  {accessRight.resources &&
                    accessRight.resources.length > 0 &&
                    accessRight.resources.map((resource, index) => (
                      <Row
                        justify={"space-between"}
                        gutter={24}
                        key={resource.resources_id}
                        className="flex max-w-full py-6 border-b border-[#DDDAE9]"
                      >
                        <Col span={10} className="flex justify-between">
                          <h1 className="lg:text-lg text-base font-monaSansRegular text-black">
                            {getFirstLetterCapital(resource.resources_name)}
                          </h1>
                          <Switch
                            checked={switchStates[resource.resources_id]}
                            onChange={(checked) =>
                              handleSwitchChange(resource.resources_id, checked)
                            }
                          />
                        </Col>
                        <Col span={2} />
                        <Col span={12} className="flex justify-between">
                          <PermissionGroup
                            data={resource}
                            switchState={switchStates}
                            globalPermissions={globalPermissions}
                            onCheckboxChange={(action, checked) =>
                              handleCheckboxChange(
                                resource.resources_id,
                                action,
                                checked
                              )
                            }
                          />
                        </Col>
                      </Row>
                    ))}
                </div>
              </div>
            </Card>
          ))}
      </section>
    </Spin>
  );
};

const PermissionGroup = ({
  data,
  switchState,
  globalPermissions,
  onPossessionChange,
  onCheckboxChange,
}) => {
  const [groupedPermissions, setGroupedPermissions] = useState({});
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (data && Array.isArray(data.permissions)) {
      const grouped = data.permissions.reduce((acc, perm) => {
        if (!acc[perm.actions]) {
          acc[perm.actions] = { any: null, own: null };
        }
        acc[perm.actions][perm.possessions] = perm;
        return acc;
      }, {});

      setGroupedPermissions(grouped);

      const switchOn = switchState[data.resources_id];
      setDisabled(!switchOn);
    }
  }, [data, switchState]);

  return Object.keys(groupedPermissions).map((action, index) => (
    <Fragment key={index}>
      <Checkbox
        className="flex items-center"
        checked={globalPermissions[data.resources_id]?.checked[action]}
        onChange={(e) => onCheckboxChange(action, e.target.checked)}
        disabled={disabled}
      >
        {action.charAt(0).toUpperCase() + action.slice(1)}
      </Checkbox>
    </Fragment>
  ));
};

export default ModulePermissions;
