import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "sonner";
import setAuthToken from "../../config/setAuthToken";
import { BASE_URL } from "../../config/web-config";
import { handleErrorMessage } from "../../components/helper/common-function";

export const actionLogin = createAsyncThunk(
  "actionLogin",
  async (data, { rejectWithValue }) => {
    const { navigate, credentials, remember_me } = data;

    try {
      const res = await axios.post(`${BASE_URL}/auth/sign-in`, credentials);
      const { status, data: responseData, message: customMsg } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg);
        setAuthToken(responseData.accessToken);

        navigate && navigate("/");
        if (remember_me) {
          localStorage.setItem("qmsEmail", credentials.email);
          localStorage.setItem("qmsPassword", credentials.password);
        } else {
          localStorage.removeItem("qmsEmail");
          localStorage.removeItem("qmsPassword");
        }
        return responseData;
      } else {
        return rejectWithValue(customMsg); // Pass error message to component
      }
    } catch (error) {
      if (error?.code === "ERR_NETWORK") {
        return rejectWithValue(
          "Unable to reach the server. Please try again later."
        );
      }
      return rejectWithValue(
        error.response?.data?.message || "An error occurred."
      );
    }
  }
);

// Logout User
export const actionLogout = createAsyncThunk(
  "actionLogout",
  async ({ navigate, setVisibleLogoutModal }) => {
    setVisibleLogoutModal && setVisibleLogoutModal(false);
    localStorage.removeItem("qmsToken");

    setAuthToken(false);
    navigate("/login");
  }
);

//forgot password
// export const actionForgotPassword = createAsyncThunk(
//   "actionForgotPassword",
//   async (data) => {
//     const { values, navigate } = data;
//     try {
//       const response = await axios.post(
//         `${BASE_URL}/auth/forgot-password`,
//         values
//       );
//       const { status, message: customMsg } = response.data;
//       if (parseInt(status) === 200) {
//         toast.success(customMsg, 5);
//         navigate && navigate("/login");
//       } else {
//         toast.error(customMsg, 5);
//       }
//     } catch (error) {
//       toast.error(error?.message, 5);
//     }
//   }
// );
export const actionForgotPassword = createAsyncThunk(
  "actionForgotPassword",
  async (data, { rejectWithValue }) => {
    const { values, navigate } = data;
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/forgot-password`,
        values
      );
      const { status, message: customMsg } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/login");
      } else {
        return rejectWithValue(customMsg); // Reject with error message
      }
    } catch (error) {
      return rejectWithValue(error?.message || "Something went wrong!"); // Handle network error
    }
  }
);

//reset password
export const actionResetPassword = createAsyncThunk(
  "actionResetPassword",
  async (data) => {
    const { request, navigate } = data;
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/reset-password`,
        request
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/login");
      } else {
        toast.error(customMsg, 5);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

//changes own password
export const actionChangeOwnPassword = createAsyncThunk(
  "actionChangeOwnPassword",
  async (data) => {
    const { request, navigate } = data;
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/change-password-own`,
        request
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/");
      } else {
        toast.error(customMsg, 5);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// Auth me
export const authMe = createAsyncThunk(
  "authMe",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${BASE_URL}/auth/me`);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        return data;
      } else if (parseInt(status) === 401) {
        localStorage.removeItem("qmsToken");
        localStorage.removeItem("collapsed");
        setAuthToken(false);
        window.location.replace("/login");
        toast.error(customMsg, 5);
      } else {
        toast.error(customMsg, 5);
        throw new Error(`API Error: ${customMsg}`);
      }
    } catch (error) {
      if (error.response.data.message === "Unauthorized") {
        localStorage.removeItem("qmsToken");
        setAuthToken(false);
        window.location.replace("/login");
        toast.error(error?.message, 5);
      } else if (error?.code === "ERR_NETWORK") {
        toast.error(
          "Network error: Unable to reach the server. Please try again later."
        );
      }
      return rejectWithValue(error.message);
    }
  }
);

export const actionChangePassword = createAsyncThunk(
  "actionChangePassword",
  async (data) => {
    const { values, navigate, id } = data;
    try {
      const response = await axios.post(
        `${BASE_URL}/auth/change-password/${id}`,
        values
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/");
      } else {
        toast.error(customMsg, 5);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

export const actionGetDashboardData = createAsyncThunk(
  "actionGetDashboardData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/auth/dashboard`);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        return data;
      } else {
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);
