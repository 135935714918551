import { Image } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const CustomIconText = ({ image, icon, title }) => {
  const navigate = useNavigate();

  return (
      <div className="flex items-center justify-start gap-x-3 mb-2 lg:mb-3">
        {icon
          ? icon
          : image && (
              <Image
                src={image}
                alt="image"
                height={20}
                width={20}
                preview={false}
              />
            )}
        <h1 className="lg:text-lg text-base font-monaSansSemiBold">{title}</h1>
      </div>
  );
};

export default CustomIconText;
