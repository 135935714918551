import { Navigate, Outlet } from "react-router-dom";

export const PrivateRoute = ({ component }) => {
  const token = localStorage.getItem("qmsToken");

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return component;
};

export const PrivateRouteWithoutLayout = () => {
  const token = localStorage.getItem("qmsToken");

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export const PublicRoute = () => {
  const token = localStorage.getItem("qmsToken");

  if (token) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};
