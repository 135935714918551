import {
  Button,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import plusIcon from "../../assets/img/add-button.svg";
import deletemaster from "../../assets/img/deleteMaster.svg";
import editedMaster from "../../assets/img/editedMaster.svg";
import editMaster from "../../assets/img/editMaster.svg";
import CustomSearchInput from "../common/CustomSearchInput";
import ConfigProviderWrapper from "../common/ConfigProviderWrapper";
import SortIcon from "../common/SortIcon";
import {
  actionAddUnitMaster,
  actionDeleteUnitMaster,
  actionEditUnitMaster,
  actionGetUnitMaster,
  actionGetSingleUnitMaster,
} from "../../store/services/unitMasterService";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { isValidSearchString } from "../helper/common-function";

const UnitMaster = () => {
  const [editableForm] = Form.useForm();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    unitMasterData,
    getUnitMasterLoader,
    addUnitMasterLoader,
    deleteUnitMasterLoader,
    updateUnitMasterLoader,
    getSingleUnitMasterData,
  } = useSelector((state) => state.unitMaster);

  // Generating 10 items of dummy data
  const [search, setSearch] = useState("");
  const [edited, setEdited] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isEditing = (record) => record.unit_master_id === editingKey;

  useEffect(() => {
    dispatch(actionGetUnitMaster({ search }));
  }, []);

  //add
  const handleAddAddressTypeMethod = (values) => {
    const req = {
      ...values,
      description: values.description ? values.description : "",
    };
    dispatch(actionAddUnitMaster({ values: req, handleCancel }));
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (isValidSearchString(value)) {
      setSearch(value);
      if (value === "") {
        dispatch(
          actionGetUnitMaster({
            search: value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    const value = e.target.value;
    if (search && isValidSearchString(value)) {
      dispatch(
        actionGetUnitMaster({
          search: value,
        })
      );
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetUnitMaster({
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "select" ? <Select /> : <Input className="!text-[14px]" />;
    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: title === "Unit Code" ? true : false,
                message: ``,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="table-cell-text"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(actionGetSingleUnitMaster(record.unit_master_id));
    setEdited(!edited);
  };

  useEffect(() => {
    if (getSingleUnitMasterData && edited) {
      editableForm.setFieldsValue({
        unit_code: getSingleUnitMasterData.unit_code,
        description: getSingleUnitMasterData.description,
      });
      setEditingKey(getSingleUnitMasterData.unit_master_id);
    }
  }, [getSingleUnitMasterData]);

  const save = async (id) => {
    try {
      const row = await editableForm.validateFields();
      const { unit_code, description } = row;
      const req = { unit_code, description };
      dispatch(actionEditUnitMaster({ id, req, setEditingKey, setEdited }));
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Unit Code",
      dataIndex: "unit_code",
      key: "2",
      width: "22%",
      editable: true,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "3",
      width: "60%",
      editable: true,
      ellipsis: true,
      render: (data) => (data ? data : "-"),
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Action",
      key: "4",
      width: 100,
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);

        return (
          <div className="flex items-center">
            {editable ? (
              <div className="flex items-center">
                {updateUnitMasterLoader[record.unit_master_id] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link onClick={() => save(record.unit_master_id)}>
                    <img src={editMaster} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                >
                  <img src={editedMaster} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              <Popconfirm
                okButtonProps={{
                  loading: deleteUnitMasterLoader[record.unit_master_id],
                }}
                description="Are you sure you want to delete?"
                onConfirm={() =>
                  dispatch(
                    actionDeleteUnitMaster({
                      id: record.unit_master_id,
                      setEditingKey,
                    })
                  )
                }
              >
                <img src={deletemaster} alt="" className="cursor-pointer" />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "unit_code" ? "input" : "input",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div className="master-input">
      <div className="flex justify-between mt-[15px]">
        <h1 className="text-black text-[20px] font-monaSansMedium">
          Unit 
        </h1>
        <div>
          <Button
            onClick={showModal}
            className="root-btn text-white hover:text-white py-5 flex-center radius"
            icon={<Image src={plusIcon} alt="PlusIcon Icon" preview={false} />}
          >
            Add Unit
          </Button>
          <Modal
            className="input-custom"
            centered
            onOk={() => handleCancel()}
            onCancel={() => handleCancel()}
            footer={false}
            width={500}
            title=""
            open={isModalOpen}
          >
            <h1 className="text-black text-[20px]  mt-[5px] font-monaSansMedium">
              Unit
            </h1>
            <Form
              form={form}
              onFinish={handleAddAddressTypeMethod}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Form.Item
                name="unit_code"
                label="Unit Code"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please input unit code!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Unit Code"
                  className=" bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: false,
                    message: "Please input description!",
                  },
                ]}
              >
                <Input.TextArea
                  rows={3}
                  style={{ background: "white" }}
                  placeholder="Enter Description"
                  className=" bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <div className="flex justify-center mt-[40px]">
                <Form.Item className="mb-0">
                  <Button
                    onClick={handleCancel}
                    type="button"
                    className="cancel-btn  min-h-[40px] min-w-[100px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      type="primary"
                      loading={addUnitMasterLoader}
                      className="root-btn min-h-[40px] min-w-[100px]"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
      <div className="form-table-design mt-[15px]">
        <div className="relative">
          <div className="search-bar">
            <CustomSearchInput
              handleSearch={handleSearch}
              handleBlurAndPressEnter={handleBlurAndPressEnter}
              searchValue={search}
            />
          </div>

          <div className="pt-3 pb-[75px]">
            <Form form={editableForm} component={false}>
              <ConfigProviderWrapper>
                <Table
                  bordered
                  onChange={handleSortChange}
                  className="ant-table-padding"
                  rowKey="unit_master_id"
                  columns={mergedColumns}
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  loading={getUnitMasterLoader}
                  dataSource={unitMasterData}
                  pagination={false}
                  rowClassName="editable-row"
                />
              </ConfigProviderWrapper>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitMaster;
