import React, { useEffect, useState } from "react";
import { PhoneInput } from "react-international-phone";
import { useSelector } from "react-redux";

const PhoneInputComponent = ({
  value,
  onChange,
  required = false,
  isValid = true,
  submitted = false,
  label = "Contact No",
}) => {
  const { authData } = useSelector((state) => state.auth);
  const { company } = authData || {};

  const [error, setError] = useState("");
  const [internalIsValid, setInternalIsValid] = useState(isValid);

  useEffect(() => {
    setInternalIsValid(isValid);
  }, [isValid]);

  const handlePhoneChange = (val, data) => {
    const match = data.inputValue.match(/^\+\d+/);
    let countryCode = "";
    let phoneNumber = "";
    let error = "";
    let newIsValid = true;

    countryCode = match ? match[0] : "";
    phoneNumber = data.inputValue.slice(countryCode.length).trimStart();

    if (!phoneNumber && required) {
      error = "Please enter a valid phone number!";
      newIsValid = false;
    }

    if (phoneNumber.length > 15) {
      error = "Phone number must be 15 digits or less!";
      newIsValid = false;
    }

    onChange(
      data.inputValue,
      phoneNumber,
      data?.country.iso2.toUpperCase(),
      newIsValid,
      data?.country.dialCode
    );
    setError(error);
    setInternalIsValid(newIsValid);

    return newIsValid;
  };

  const showError = submitted && !internalIsValid;

  const curr = company?.currency?.currency?.iso3.toLowerCase();

  return (
    <div className="">
      {label && (
        <div className=" block text-[#000000] text-[17px] font-monaSansMedium mb-2">
          {label}{" "}
          {required && (
            <span className="required text-[#ff4d4f] text-[17px]">*</span>
          )}
        </div>
      )}

      <PhoneInput
        forceDialCode={true}
        disableFormatting={true}
        placeholder="Enter Phone Number"
        defaultCountry={company?.currency?.currency?.iso2.toLowerCase()}
        className={`w-full ${showError ? "custom-err-border" : "mb-3.5"}`}
        value={value}
        onChange={handlePhoneChange}
      />
      {showError && (
        <div className="ant-form-item-explain-error text-[#ff4d4f]">
          {error}
        </div>
      )}
    </div>
  );
};

export default PhoneInputComponent;
