import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import roleSlice from "./slices/roleSlice";
import addressTypeMasterSlice from "./slices/addressTypeMasterSlice";
import countryMasterSlice from "./slices/countryMasterSlice";
import stateMasterSlice from "./slices/stateMasterSlice";
import unitMasterSlice from "./slices/unitMasterSlice";
import currencyMasterSlice from "./slices/currencyMasterSlice";
import taxMasterSlice from "./slices/taxMasterSlice";
import quotationStatusMasterSlice from "./slices/quotationStatusMasterSlice";
import companyDetailSlice from "./slices/companyDetailSlice";
import userSlice from "./slices/userSlice";
import customerSlice from "./slices/customerSlice";
import productSlice from "./slices/productSlice";
import quotationSlice from "./slices/quotationSlice";
import bankDetailsMasterSlice from "./slices/bankDetailsMasterSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    role: roleSlice,
    addressTypeMaster: addressTypeMasterSlice,
    countryMaster: countryMasterSlice,
    stateMaster: stateMasterSlice,
    unitMaster: unitMasterSlice,
    currencyMaster: currencyMasterSlice,
    taxMaster: taxMasterSlice,
    quotationStatusMaster: quotationStatusMasterSlice,
    companyDetail: companyDetailSlice,
    user: userSlice,
    customer: customerSlice,
    product: productSlice,
    quotation: quotationSlice,
    bankDetails: bankDetailsMasterSlice
  },

  /* middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }), */
});
