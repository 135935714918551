import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import BackArrowButton from "../common/BackArrowButton";
import { BASE_URL_UPLOAD } from "../../config/web-config";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import editPrimaryWhite from "../../assets/img/editPrimaryWhite.svg";
import { toast } from "sonner";
import CustomIconText from "../common/CustomIconText";
import productIcon from "../../assets/img/productIcon.svg";
import ImgCrop from "antd-img-crop";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import AddPopover from "../common/AddPopover";
import {
  actionAddUnitMaster,
  actionGetUnitMaster,
} from "../../store/services/unitMasterService";
import {
  actionAddProduct,
  actionEditProduct,
  actionGetSingleProduct,
} from "../../store/services/productService";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CreateProduct = () => {
  const [materialImageUrl, setMaterialImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addUnitPopoverOpen, setAddUnitPopoverOpen] = useState(false);
  const [unitForm] = Form.useForm();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { productId } = location.state || {};
  const [fileList, setFileList] = useState([]);
  const { authMeLoader, authData } = useSelector((state) => state.auth);
  const { unitMasterData, getUnitMasterLoader, addUnitMasterLoader } =
    useSelector((state) => state.unitMaster);
  const {
    addProductLoader,
    updateProductLoader,
    getSingleProductLoader,
    getSingleProductData,
  } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(actionGetUnitMaster());
  }, []);

  const onChangeOtherImages = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleChangeMaterialImage = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setMaterialImageUrl(info.file.response?.data);
        toast.success("Uploaded Successfully", 5);
      });
    } else if (info.file.status === "error") {
      setLoading(false);
      toast.error(`${info.file.name} file upload failed.`);
    }
  };

  useEffect(() => {
    if (productId) {
      dispatch(actionGetSingleProduct(productId));
    }
  }, [productId]);

  useEffect(() => {
    if (getSingleProductData && productId) {
      form.setFieldsValue({
        material_name: getSingleProductData.material_name,
        material_description: getSingleProductData.material_description,
        material_rate: getSingleProductData.material_rate,
        material_number: getSingleProductData.material_number,
        unit_master_id: getSingleProductData.unit?.unit_master_id,
        material_rate: getSingleProductData.material_rate,
      });

      setMaterialImageUrl(getSingleProductData?.material_image);

      const ImagesArray = getSingleProductData?.material_images?.map((data) => ({
        uid: data.material_images.upload_id,
        name: data.material_images.originalname,
        url: data.material_images.url,
      })) || []; 
  
      setFileList(ImagesArray);
    }
  }, [getSingleProductData, productId]);


  const unitData = useMemo(() => {
    return (
      unitMasterData &&
      unitMasterData.length > 0 &&
      unitMasterData.map((item) => ({
        key: item.unit_master_id,
        value: item.unit_master_id,
        label: item.unit_code,
      }))
    );
  }, [unitMasterData]);

  // add unit
  const showAddUnitForm = () => {
    return (
      <div className="min-w-[350px]">
        <Form
          form={unitForm}
          onFinish={(values) =>
            dispatch(
              actionAddUnitMaster({
                values,
                handleCancel: closeUnitFormPopover,
                form,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="unit_code"
            label="Unit"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input unit!",
              },
            ]}
          >
            <Input placeholder="Enter Unit" className="py-[10px]" />
          </Form.Item>

          <div className="flex justify-end mt-[40px]">
            <Form.Item className="mb-0">
              <Button
                onClick={closeUnitFormPopover}
                type="button"
                className="cancel-btn  min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addUnitMasterLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    );
  };

  const closeUnitFormPopover = () => {
    setAddUnitPopoverOpen(false);
    unitForm.resetFields();
  };

  const handleSubmit = (values) => {
    const req = {
      ...values,
      material_description: values.material_description ? values.material_description : null,
      material_number: values.material_number ? values.material_number : null,
      currency_master_id: authData?.company?.currency?.currency_master_id,
      material_image_id: materialImageUrl ? materialImageUrl.upload_id : null,
      material_attechments_id:
      fileList && fileList.length > 0
        ? fileList.map((file) => file?.response?.data?.upload_id || file?.uid)
        : null,
    };

    console.log(req);
    productId
      ? dispatch(actionEditProduct({ id: productId, req, navigate }))
      : dispatch(actionAddProduct({ req, navigate }));
  };

  const onFinishFailed = ({ values, errorFields }) => {
    console.log(errorFields);
  };

  return (
    <Spin spinning={false}>
      <div className="flex items-center gap-x-3 justify-between">
        <BackArrowButton />
        <p className="text-lg font-monaSansSemiBold">
          {productId ? "Update Product" : "Create Product"}
        </p>
      </div>
      <Form
      preserve={true}
        scrollToFirstError={true}
        form={form}
        initialValues={{
          phone_country_code: "in",
        }}
        layout="vertical"
        name="basic"
        onFinish={handleSubmit}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Card
          className={`mt-3 w-full ${
            addProductLoader || updateProductLoader
              ? "opacity-50 pointer-events-none"
              : ""
          }`}
        >
          <CustomIconText
            image={productIcon}
            title={"Product"}
            backArrow={true}
          />
          <Row gutter={24}>
            <Col span={24} align="middle">
              <Form.Item
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please upload material image!",
                  },
                ]}
              >
                {materialImageUrl ? (
                  <div className="relative border-secondary  h-[102px]  w-[102px] rounded-[6px] mb-2">
                    <img
                      src={materialImageUrl.url}
                      alt="avatar"
                      className="h-full w-full rounded-[6px]"
                    />

                    <ImgCrop rotationSlider aspect={1 / 1} fillColor="white">
                      <Upload
                        id=""
                        accept=".png,.jpg,.jpeg"
                        name="file"
                        listType="picture-card"
                        className="border-0 absolute main-upload bottom-0 right-0 avatar-uploader"
                        showUploadList={false}
                        action={BASE_URL_UPLOAD}
                        openFileDialogOnClick={true}
                        headers={{
                          Authorization:
                            "Bearer " + localStorage.getItem("qmsToken"),
                        }}
                        onChange={handleChangeMaterialImage}
                      >
                        <div className=" px-1.5 py-1 border-0 absolute right-0 bottom-0 bg-red-500 rounded-tl-[6px] rounded-br-[6px] cursor-pointer flex">
                          <Image
                            width={15}
                            height={15}
                            src={editPrimaryWhite}
                            className="rounded-lg"
                            preview={false}
                          />
                        </div>
                      </Upload>
                    </ImgCrop>
                  </div>
                ) : (
                  <ImgCrop aspect={1 / 1} rotationSlider fillColor="white">
                    <Upload
                      name="file"
                      accept=".png,.jpg,.jpeg"
                      listType="picture-card"
                      className="avatar-uploader mb-2"
                      showUploadList={false}
                      action={BASE_URL_UPLOAD}
                      openFileDialogOnClick={true}
                      headers={{
                        Authorization:
                          "Bearer " + localStorage.getItem("qmsToken"),
                      }}
                      onChange={handleChangeMaterialImage}
                    >
                      <button
                        style={{
                          border: 0,
                          background: "none",
                        }}
                        type="button"
                      >
                        {loading ? (
                          <LoadingOutlined />
                        ) : (
                          <PlusOutlined style={{ color: "#2B2526" }} />
                        )}
                        <div
                          style={{
                            marginTop: 5,
                          }}
                          className="font-monaSansRegular w-[50%] text-center mx-auto"
                        >
                          Product Image
                        </div>
                      </button>
                    </Upload>
                  </ImgCrop>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Product"
                name="material_name"
                rules={[
                  {
                    required: true,
                    message: "Please input product name!",
                  },
                ]}
              >
                <Input placeholder="Enter Product Name" className="py-[10px]" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Rate"
                name="material_rate"
                rules={[
                  {
                    required: true,
                    message: "Please input rate!",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  controls={false}
                  placeholder="Rate"
                  className="py-0 w-full"
                  suffix={
                    <div className="border-l border-l-[#BCB4B2] py-[10px] pl-[10px]">
                      <p className="text-[#8E8A8B] text-[15px] font-monaSansRegular">
                        {authData?.company?.currency?.currency?.currency}
                      </p>
                    </div>
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <AddPopover
                content={showAddUnitForm}
                isOpen={addUnitPopoverOpen}
                onOpenChange={(visible) => {
                  if (!visible) {
                    setAddUnitPopoverOpen(false);
                    unitForm.resetFields();
                  }
                }}
                text="Add Unit"
                showModal={() => setAddUnitPopoverOpen(!addUnitPopoverOpen)}
              />
              <Form.Item
                label="Unit"
                name="unit_master_id"
                rules={[
                  {
                    required: true,
                    message: "Please select unit!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  loading={getUnitMasterLoader}
                  disabled={getUnitMasterLoader}
                  placeholder="Select Unit"
                  optionFilterProp="children"
                  className=""
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={unitData}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Model No / Product"
                name="material_number"
                rules={[
                  {
                    required: false,
                    message: "Please input model no / product!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Model No / Product"
                  className="py-[10px]"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="material_description"
                label="Description"
                className="mb-[12px]"
                size="small"
              >
                <Input.TextArea
                  rows={3}
                  placeholder="Enter Description"
                  className=" rounded-[10px]"
                />
              </Form.Item>
            </Col>
            <Col span={24} className="mt-2">
              <Form.Item
                label="Other Images"
                rules={[
                  {
                    required: false,
                    message: "Please enter profile pic!",
                  },
                ]}
              >
                <ImgCrop rotationSlider>
                  <Upload
                    accept=".png,.jpg,.jpeg"
                    action={BASE_URL_UPLOAD}
                    headers={{
                      Authorization:
                        "Bearer " + localStorage.getItem("qmsToken"),
                    }}
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChangeOtherImages}
                  >
                    <div className="flex flex-col justify-center items-center gap-2">
                      <PlusOutlined style={{ color: "#2B2526" }} />

                      <div>Upload</div>
                    </div>
                  </Upload>
                </ImgCrop>
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <Row gutter={24}>
          <Col span={24} className="flex gap-x-3 justify-center mt-2 lg:mt-4">
            <Button
              onClick={async () => {
                navigate(-1);
              }}
              type="button"
              className="cancel-btn min-h-[45px] min-w-[120px]"
            >
              Cancel
            </Button>

            <Form.Item className="mb-0">
              <Button
                loading={addProductLoader || updateProductLoader}
                type="primary"
                className="root-btn min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                {productId ? "Update" : "Save"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default CreateProduct;
