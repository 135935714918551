const BASE_URL =
  process.env.REACT_APP_ENV_MODE == "DEV"
    ? process.env.REACT_APP_DEV_BASE_URL
    : process.env.REACT_APP_PROD_BASE_URL;

const BASE_URL_UPLOAD =
  process.env.REACT_APP_ENV_MODE == "DEV"
    ? process.env.REACT_APP_DEV_UPLOAD_BASE_URL
    : process.env.REACT_APP_PROD_UPLOAD_BASE_URL;

export { BASE_URL, BASE_URL_UPLOAD };
