import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import axios from "axios";
import { toast } from "sonner";
import { handleDeleteResponse, handleGetResponse } from "./commonService";
import { handleErrorMessage } from "../../components/helper/common-function";
import { setProductData } from "../slices/productSlice";

//get listing of the products
export const actionGetProducts = createAsyncThunk(
  "actionGetProducts",
  async ({ offset = "", search = "", orderByColumn = "", order = "" } = {}) => {
    return handleGetResponse(
      `material?limit=10&offset=${offset}${search ? `&search=${search}` : ""}${
        orderByColumn ? `&orderByColumn=${orderByColumn}` : ""
      }${order ? `&order=${order}` : ""}`
    );
  }
);

//add Product
export const actionAddProduct = createAsyncThunk(
  "actionAddProduct",
  async (
    { req, navigate, setIsAddItemModalVisible, addProductForm },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`${BASE_URL}/material`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/products");
        setIsAddItemModalVisible && setIsAddItemModalVisible(false);
        addProductForm && addProductForm.resetFields();
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      // toast.error(error);
      handleErrorMessage(error);
    }
  }
);

//delete product
export const actionDeleteProduct = createAsyncThunk(
  "actionDeleteProduct",
  async ({ productId, handleCloseProductDeleteModal }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${BASE_URL}/material/${productId}`);
      const { status, message: customMsg } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        handleCloseProductDeleteModal && handleCloseProductDeleteModal(false);
        return productId;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

//get single product
export const actionGetSingleProduct = createAsyncThunk(
  "actionGetSingleProduct",
  (id) => {
    return handleGetResponse(`material/${id}`);
  }
);

//update product
export const actionEditProduct = createAsyncThunk(
  "actionEditProduct",
  async ({ id, req, navigate }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/material/${id}`, req);
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate("/products");
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionGetAllProducts = createAsyncThunk(
  "actionGetAllProducts",
  async () => {
    return handleGetResponse("material?getall=YES");
  }
);

export const actionGetSingleProductByIndex = createAsyncThunk(
  "actionGetSingleProductByIndex",
  async (
    { index, id,  },
    { dispatch, rejectWithValue }
  ) => {
    try {
      
      const res = await axios.get(`${BASE_URL}/material/${id}`);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        dispatch(setProductData({ index, data }));
        return { data, index };
      } else {
        // toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionResetSingleProductsIndexData = createAsyncThunk(
  "actionResetSingleProductsIndexData",
  async (index) => {
    return { index, data: null };
  }
);
