import { LoadingOutlined } from "@ant-design/icons";
import {
    Button,
    Form,
    Image,
    Input,
    Modal,
    Popconfirm,
    Select,
    Table,
    Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import plusIcon from "../../assets/img/add-button.svg";
import deletemaster from "../../assets/img/deleteMaster.svg";
import editedMaster from "../../assets/img/editedMaster.svg";
import editMaster from "../../assets/img/editMaster.svg";
import { actionAddBankDetailMaster, actionDeleteBankDetailMaster, actionEditBankDetailsMaster, actionGetBankDetailsMaster, actionGetSingleBankDetailsMaster } from "../../store/services/bankDetailsMasterService";
import {
    actionGetSingleQuotationStatusMaster
} from "../../store/services/quotationStatusMasterService";
import ConfigProviderWrapper from "../common/ConfigProviderWrapper";
import CustomSearchInput from "../common/CustomSearchInput";
import SortIcon from "../common/SortIcon";
import { isValidSearchString } from "../helper/common-function";

const BankDetailsMaster = () => {
    const [editableForm] = Form.useForm();
    const dispatch = useDispatch();
    const [form] = Form.useForm();


    const {
        bankDetailsMasterData,
        getBankDetailsMasterLoader,
        addBankDetailsMasterLoader,
        deleteBankDetailsMasterLoader,
        updateBankDetailsMasterLoader,
        getSingleBankDetailsMasterData,
        getSingleBankDetailsMasterLoader,
    } = useSelector(state => state.bankDetails);

    // Generating 10 items of dummy data
    const [search, setSearch] = useState("");
    const [edited, setEdited] = useState(false);
    const [editingKey, setEditingKey] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const isEditing = (record) =>
        record.company_bank_master_id === editingKey;

    useEffect(() => {
        dispatch(actionGetBankDetailsMaster({ search }));
    }, []);

    //add
    const handleAddBankDetailsMethod = (values) => {
        dispatch(actionAddBankDetailMaster({ values, handleCancel }));
    };

    const handleSearch = (e) => {
        const value = e.target.value;
        if (isValidSearchString(value)) {
            setSearch(value);
            if (value === "") {
                dispatch(
                    actionGetBankDetailsMaster({
                        search: value,
                    })
                );
            }
        }
    };

    const handleBlurAndPressEnter = (e) => {
        const value = e.target.value;
        if (search && isValidSearchString(value)) {
            dispatch(
                actionGetBankDetailsMaster({
                    search: value,
                })
            );
        }
    };

    const handleSortChange = (pagination, filters, sorter) => {
        const sorterParams = {
            field: sorter.field,
            order:
                sorter.order === "descend"
                    ? "DESC"
                    : sorter.order === "ascend"
                        ? "ASC"
                        : "",
        };

        dispatch(
            actionGetBankDetailsMaster({
                search: search,
                orderByColumn: sorterParams.field,
                order: sorterParams.order,
            })
        );
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        const inputNode =
            inputType === "select" ? <Select /> : <Input className="!text-[14px]" />;
        return (
            <td {...restProps} className="input-height">
                {editing ? (
                    <Form.Item
                        className="mb-0 editable-order"
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: title === "Quotation Status" ? true : false,
                                message: ``,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    <Typography.Paragraph
                        style={{ marginBottom: 0 }}
                        ellipsis={{ rows: 1, expandable: false, tooltip: true }}
                        className="table-cell-text"
                    >
                        {children}
                    </Typography.Paragraph>
                )}
            </td>
        );
    };

    const edit = (record) => {
        dispatch(
            actionGetSingleBankDetailsMaster(record.company_bank_master_id)
        );
        setEdited(!edited);
    };

    console.log(editingKey);

    console.log(getSingleBankDetailsMasterData)

    useEffect(() => {
        if (getSingleBankDetailsMasterData && edited) {
            editableForm.setFieldsValue({
                bank_name: getSingleBankDetailsMasterData.bank_name,
                bank_beneficiary: getSingleBankDetailsMasterData.bank_beneficiary,
                bank_swift_code: getSingleBankDetailsMasterData.bank_swift_code,
                bank_account_number: getSingleBankDetailsMasterData.bank_account_number,
            });
            setEditingKey(
                getSingleBankDetailsMasterData.company_bank_master_id
            );
        }
    }, [getSingleBankDetailsMasterData]);

    const save = async (id) => {
        try {
            const row = await editableForm.validateFields();
            const { bank_name,
                bank_beneficiary,
                bank_swift_code,
                bank_account_number } = row;
            const req = {
                bank_name,
                bank_beneficiary,
                bank_swift_code,
                bank_account_number
            };
            dispatch(
                actionEditBankDetailsMaster({ id, req, setEditingKey, setEdited })
            );
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };


    const columns = [
        {
            title: "Bank Name",
            dataIndex: "bank_name",
            key: "1",
            width: "20%",
            editable: true,
            ellipsis: true,
            sorter: (a, b) => true,
            sortIcon: () => <SortIcon />,
        },
        {
            title: "Bank Beneficiary",
            dataIndex: "bank_beneficiary",
            key: "2",
            width: "20%",
            editable: true,
            ellipsis: true,
            sorter: (a, b) => true,
            sortIcon: () => <SortIcon />,
        },
        {
            title: "Swift Code",
            dataIndex: "bank_swift_code",
            key: "3",
            width: "20%",
            editable: true,
            ellipsis: true,
            sorter: (a, b) => true,
            sortIcon: () => <SortIcon />,
        },
        {
            title: "A/C No",
            dataIndex: "bank_account_number",
            key: "4",
            width: "20%",
            editable: true,
            ellipsis: true,
            sorter: (a, b) => true,
            sortIcon: () => <SortIcon />,
        },
        {
            title: "Action",
            key: "4",
            width: 100,
            dataIndex: "operation",
            render: (_, record) => {
                const editable = isEditing(record);

                return (
                    <div className={`flex items-center`}>
                        {editable ? (
                            <div className="flex items-center">
                                {updateBankDetailsMasterLoader[
                                    record.company_bank_master_id
                                ] ? (
                                    <LoadingOutlined />
                                ) : (
                                    <Typography.Link
                                        onClick={() => save(record.company_bank_master_id)}
                                    >
                                        <img src={editMaster} alt="" />
                                    </Typography.Link>
                                )}
                            </div>
                        ) : (
                            <div className="flex items-center gap-x-2 justify-center">
                                <Typography.Link
                                    disabled={editingKey !== ""}
                                    onClick={() => edit(record)}
                                >
                                    <img src={editedMaster} alt="" />
                                </Typography.Link>
                            </div>
                        )}
                        <div className="ml-5">
                            <Popconfirm
                                okButtonProps={{
                                    loading:
                                        deleteBankDetailsMasterLoader[
                                        record.company_bank_master_id
                                        ],
                                }}
                                description="Are you sure you want to delete?"
                                onConfirm={() =>
                                    dispatch(
                                        actionDeleteBankDetailMaster({
                                            id: record.company_bank_master_id,
                                            setEditingKey,
                                        })
                                    )
                                }
                            >
                                <img src={deletemaster} alt="" className="cursor-pointer" />
                            </Popconfirm>
                        </div>
                    </div>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: "input",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <div className="master-input">
            <div className="flex justify-between mt-[15px]">
                <h1 className="text-black text-[20px] font-monaSansMedium">
                    Bank Details
                </h1>
                <div>
                    <Button
                        onClick={showModal}
                        className="root-btn text-white hover:text-white py-5 flex-center radius"
                        icon={<Image src={plusIcon} alt="PlusIcon Icon" preview={false} />}
                    >
                        Add Bank Details
                    </Button>
                    <Modal
                        className="input-custom"
                        centered
                        onOk={() => handleCancel()}
                        onCancel={() => handleCancel()}
                        footer={false}
                        width={500}
                        title=""
                        open={isModalOpen}
                    >
                        <h1 className="text-black text-[20px] mt-[5px] font-monaSansMedium">
                            Bank Details
                        </h1>
                        <Form
                            form={form}
                            onFinish={handleAddBankDetailsMethod}
                            layout="vertical"
                            className="mt-[20px] mb-0"
                        >
                            <Form.Item
                                name="bank_name"
                                label="Bank Name"
                                className="mb-[12px]"
                                size="small"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input bank name!",
                                    },
                                ]}
                            >
                                <Input
                                    style={{ background: "white" }}
                                    placeholder="Enter Bank Name"
                                    className="bg-white focus:bg-white active:bg-white rounded-[10px]"
                                />
                            </Form.Item>
                            <Form.Item
                                name="bank_beneficiary"
                                label="Beneficiary"
                                className="mb-[12px]"
                                size="small"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input beneficiary!",
                                    },
                                ]}
                            >
                                <Input
                                    style={{ background: "white" }}
                                    placeholder="Enter Beneficiary"
                                    className="bg-white focus:bg-white active:bg-white rounded-[10px]"
                                />
                            </Form.Item>
                            <Form.Item
                                name="bank_swift_code"
                                label="Swift Code"
                                className="mb-[12px]"
                                size="small"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input swift code!",
                                    },
                                ]}
                            >
                                <Input
                                    style={{ background: "white" }}
                                    placeholder="Enter Swift Code"
                                    className="bg-white focus:bg-white active:bg-white rounded-[10px]"
                                />
                            </Form.Item>
                            <Form.Item normalize={(value) => value && value.replace(/[^\d]/g, "")}

                                name="bank_account_number"
                                label="A/C No"
                                className="mb-[12px]"
                                size="small"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input account number!",
                                    },
                                ]}
                            >
                                <Input
                                    style={{ background: "white" }}
                                    placeholder="Enter Account Number"
                                    className="bg-white focus:bg-white active:bg-white rounded-[10px]"
                                />
                            </Form.Item>

                            <div className="flex justify-center mt-[40px]">
                                <Form.Item className="mb-0">
                                    <Button
                                        onClick={handleCancel}
                                        type="button"
                                        className="cancel-btn min-h-[40px] min-w-[100px]"
                                    >
                                        Cancel
                                    </Button>
                                </Form.Item>

                                <div className="ml-4">
                                    <Form.Item className="mb-0">
                                        <Button
                                            type="primary"
                                            loading={addBankDetailsMasterLoader}
                                            className="root-btn min-h-[40px] min-w-[100px]"
                                            htmlType="submit"
                                        >
                                            Save
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </Modal>
                </div>
            </div>
            <div className="form-table-design mt-[15px]">
                <div className="relative">
                    <div className="search-bar">
                        <CustomSearchInput
                            handleSearch={handleSearch}
                            handleBlurAndPressEnter={handleBlurAndPressEnter}
                            searchValue={search}
                        />
                    </div>

                    <div className="pt-3 pb-[75px]">
                        <Form form={editableForm} component={false}>
                            <ConfigProviderWrapper>
                                <Table
                                    bordered
                                    onChange={handleSortChange}
                                    className="ant-table-padding"
                                    rowKey="company_bank_master_id"
                                    columns={mergedColumns}
                                    components={{
                                        body: {
                                            cell: EditableCell,
                                        },
                                    }}
                                    scroll={{ y: 400 }}
                                    loading={getBankDetailsMasterLoader}
                                    dataSource={bankDetailsMasterData}
                                    pagination={false}
                                    rowClassName="editable-row"
                                />
                            </ConfigProviderWrapper>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BankDetailsMaster;
