import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  EditOutlined,
  LogoutOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import {
  Button,
  ConfigProvider,
  Dropdown,
  Image,
  Layout,
  Menu,
  Modal,
  Result,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import acceptIcon from "../../assets/img/acceptIcon.svg";
import allUserICon from "../../assets/img/allUserICon.svg";
import allUserActiveICon from "../../assets/img/allUserActiveICon.svg";
import createquotationsIcon from "../../assets/img/createquotationsIcon.svg";
import declineIcon from "../../assets/img/declineIcon.svg";
import logo from "../../assets/img/newLogo.svg";
import masterActiveIcon from "../../assets/img/masterActiveIcon.svg";
import companyActiveIcon from "../../assets/img/companyActiveIcon.svg";
import masterIcon from "../../assets/img/masterIcon.svg";
import companyIcons from "../../assets/img/companyIcons.svg";
import activeCompanyIcon from "../../assets/img/activeCompanyIcon.svg";
import dashboardIcon from "../../assets/img/dashboardIcon.svg";
import dashboardActiveIcon from "../../assets/img/dashboardActiveIcon.svg";
import quotationsIcon from "../../assets/img/quotationsIcon.svg";
import quotationsActiveIcon from "../../assets/img/quotationsActiveIcon.svg";
import sentToCustomerIcon from "../../assets/img/sentToCustomerIcon.svg";
import userActiveICon from "../../assets/img/userActiveICon.svg";
import userICon from "../../assets/img/userICon.svg";
import productActiveICon from "../../assets/img/productActiveIcon.svg";
import productIcon from "../../assets/img/productIcon.svg";
import allProductIcon from "../../assets/img/allProductIcon.svg";
import userProfileIcon from "../../assets/img/userProfileIcon.svg";
import CompanyDetail from "../company/CompanyDetail";
import { actionLogout, authMe } from "../../store/services/authService";
import { getFirstLetterCapital } from "../helper/common-function";
const { Header, Sider, Content } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const Root = () => {
  const { authMeLoader, authData } = useSelector((state) => state.auth);
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = localStorage.getItem("collapsed");
    return savedState ? JSON.parse(savedState) : false;
  });
  const [key, setKey] = useState("1");
  const [visibleLogoutModal, setVisibleLogoutModal] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const history = useLocation();

  const currentPath = history.pathname;
  useMemo(() => {
    const routeKeyMapping = {
      "": "1",
      users: "2",
      "create-user": "2",
      "update-user": "2",
      quotations: "7",
      "quotations?status=accepted": "7",
      "quotations?status=declined": "7",
      "quotations?status=sent-to-customer": "7",
      "create-quotation": "7",
      "update-quotation": "7",
      masters: "3",
      "assign-permission": "3",
      customers: "4",
      "create-customer": "4",
      "update-customer": "4",
      "company-detail": "5",
      products: "6",
      "create-product": "6",
      "update-product": "6",
    };
    const pathParts = currentPath && currentPath.split("/");
    const pathKey = routeKeyMapping[pathParts[1]] || "1";
    setKey(pathKey);
  }, [currentPath]);

  const hasPermission = (resource, action) => {
    if (!authData || !authData.role || !authData.role.permissions) {
      return false;
    }
    return authData.role.permissions.some(
      (p) =>
        p.permissions.resources === resource && p.permissions.actions === action
    );
  };

  const itemData = [
    {
      label: "Dashboard",
      icon: key === "1" ? dashboardActiveIcon : dashboardIcon,
      key: "1",
      link: "/",
    },
    {
      label: "Quotations",
      icon: key === "7" ? quotationsActiveIcon : quotationsIcon,
      key: "7",
      visible: hasPermission("quotation", "read"),
      subItems: [
        {
          key: "7.2",
          label: "All Quotations",
          icon: quotationsIcon,
          link: "/quotations",
          visible: hasPermission("quotation", "read"),
        },
        {
          key: "7.6",
          label: "Request For Quotations",
          icon: quotationsIcon,
          link: "/request-quotations",
          visible: hasPermission("request-for-quotation", "read"),
        },
        {
          key: "7.1",
          label: "Create Quotation",
          icon: createquotationsIcon,
          link: "/create-quotation",
          visible: hasPermission("request-for-quotation", "create"),
        },
        {
          key: "7.3",
          label: "Sent Quotation",
          icon: sentToCustomerIcon,
          link: "/quotations?status=Sent",
        },
        {
          key: "7.4",
          label: "Accepted",
          icon: acceptIcon,
          link: "/quotations?status=Accepted",
        },
        {
          key: "7.5",
          label: "Rejected",
          icon: declineIcon,
          link: "/quotations?status=Rejected",
        },
      ],
    },
    {
      label: "Users",
      icon: key === "2" ? userActiveICon : userICon,
      key: "2",
      link: "/users",
      visible: hasPermission("users", "read"),
      subItems: [
        {
          key: "2.1",
          label: "All User",
          icon: allUserICon,
          link: "/users",
          visible: hasPermission("users", "read"),
        },
        {
          key: "2.2",
          label: "New User",
          icon: userICon,
          link: "/create-user",
          visible: hasPermission("users", "create"),
        },
      ],
    },
    {
      label: "Products",
      icon: key === "6" ? productActiveICon : productIcon,
      key: "6",
      link: "/products",
      visible: hasPermission("material", "read"),
      subItems: [
        {
          key: "6.1",
          label: "All Products",
          icon: allProductIcon,
          link: "/products",
          visible: hasPermission("material", "read"),
        },
        {
          key: "6.2",
          label: "New Product",
          icon: productIcon,
          link: "/create-product",
          visible: hasPermission("material", "create"),
        },
      ],
    },
    {
      label: "Customers",
      icon: key === "4" ? allUserActiveICon : allUserICon,
      key: "4",
      link: "/customers",
      visible: hasPermission("customers", "read"),
      subItems: [
        {
          key: "4.1",
          label: "All Customers",
          icon: allUserICon,
          link: "/customers",
          visible: hasPermission("customers", "read"),
        },
        {
          key: "4.2",
          label: "New Customer",
          icon: userICon,
          link: "/create-customer",
          visible: hasPermission("customers", "create"),
        },
      ],
    },
    {
      label: "Masters",
      icon: key === "3" ? masterActiveIcon : masterIcon,
      key: "3",
      link: "/masters",
    },
    {
      label: "Company Details",
      icon: key === "5" ? activeCompanyIcon : companyIcons,
      key: "5",
      link: "/company-detail",
      visible: hasPermission("company", "read"),
    },
  ];

  useEffect(() => {
    localStorage.setItem("collapsed", JSON.stringify(collapsed));
  }, [collapsed]);

  const { pathname } = useLocation();
  const isActive = (path) => pathname.startsWith(path);

  const filteredItemData = itemData
    .filter((item) => item.visible !== false)
    .map((item) => ({
      ...item,
      subItems: item.subItems
        ? item.subItems.filter((subItem) => subItem.visible !== false)
        : undefined,
    }));

  const items = [];
  filteredItemData.forEach((data) => {
    const isActiveItem =
      isActive(data.link) ||
      (data &&
        data.subItems &&
        data.subItems.length > 0 &&
        data.subItems.find((subItem) => isActive(subItem.link)));
    const item = getItem(
      data.subItems ? (
        <div className={` ${isActive ? "sub-active" : ""}`} onClick={() => {}}>
          {data.label}
        </div>
      ) : (
        <NavLink
          className={({ isActive, isPending }) =>
            isPending ? "pending" : isActive ? "sidebar-active" : ""
          }
          to={data.link}
        >
          {data.label}
        </NavLink>
      ),
      data.key,
      <img width={20} src={data.icon} alt={data.label} />,
      data.subItems && data.subItems.length > 0
        ? data.subItems.map((subItem) =>
            getItem(
              <NavLink
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active-link" : ""
                }
                to={subItem.link}
              >
                {subItem.label}
              </NavLink>,
              `${data.key}.${subItem.key}`,
              <img width={20} src={subItem.icon} alt={subItem.label} />
            )
          )
        : null
    );
    items.push(item);
  });

  const showLogoutModal = () => {
    setVisibleLogoutModal(true);
  };

  const handleLogout = () => {
    setVisibleLogoutModal(false);
  };

  const handleCancelLogout = () => {
    setVisibleLogoutModal(false);
  };

  //  profile itmes
  const profileMenuItems = [
    {
      key: "1",
      label: <p className="font-monaSansRegular text-[15px]">Edit Profile</p>,
      icon: <EditOutlined />,
      onClick: () =>
        navigate("/update-user", {
          state: {
            userId: authData?.id,
            own: true,
          },
        }),
    },
    {
      key: "2",
      label: (
        <p className="font-monaSansRegular text-[15px]">Change Password</p>
      ),
      icon: <KeyOutlined />,
      onClick: () => navigate("/change-password"),
    },
    {
      key: "3",
      label: (
        <div
          className="flex items-center font-monaSansRegular text-[15px]"
          onClick={showLogoutModal}
        >
          <LogoutOutlined className="pr-2.5" />
          Logout
        </div>
      ),
    },
  ];

  return (
    <Layout className="max-h-screen overflow-hidden">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={250}
        breakpoint="xxl"
        onCollapse={(c) => {
          setCollapsed(collapsed);
        }}
        className="side-menu duration-1000 max-h-screen fixed overflow-auto ease-in"
        style={{
          height: "100vh",
        }}
      >
        <div
          className={`bg-primary w-full h-[64px] px-3 flex items-center gap-x-2 lg:gap-x-4 ${
            collapsed ? "" : "lg:px-5"
          }`}
        >
          <Image src={logo} alt="logo" preview={false} />
          <p
            className={`text-[15px] font-monaSansMedium text-[#ededed] ${
              collapsed ? "hidden" : ""
            }`}
          >
            Fine Furniture
          </p>
        </div>

        <ConfigProvider
          theme={{
            components: {
              Menu: {
                itemHeight: 50,
                iconSize: 20,
                collapsedIconSize: 20,
                borderRadius: 0,
              },
            },
          }}
        >
          <Menu mode="inline" selectedKeys={[key]} items={items} />
        </ConfigProvider>
      </Sider>
      <Layout className="max-h-screen bg-[#FCF9F8]">
        <Header className="bg-white flex justify-between items-center">
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
            }}
          />
          <Dropdown
            className="pr-4 cursor-pointer flex items-center justify-center"
            menu={{
              items: profileMenuItems,
            }}
            trigger={"hover"}
          >
            <div className="flex gap-x-2 items-center justify-center">
              <p className="text-black text-[16px] font-monaSansRegular">
                {`
                 ${getFirstLetterCapital(authData?.first_name || "")} 
                 ${getFirstLetterCapital(authData?.last_name || "")}
                  `}
              </p>
              <Image
                src={authData?.profile_pic?.url}
                alt="image"
                preview={false}
                className="rounded-md"
                width={"31px"}
                height={"31px"}
              />
            </div>
          </Dropdown>
        </Header>
        <Content className="px-6 py-6 overflow-y-auto  min-h-[280px]">
          {/* <div className="2xl:mx-4 xl:mx-3 lg:mx-3 mx-3"> */}
          <Outlet />
          {/* </div> */}
        </Content>
        <Modal
          centered
          open={visibleLogoutModal}
          onOk={handleLogout}
          onCancel={handleCancelLogout}
          footer={null}
        >
          <Result
            className="!text-text-secondary"
            status="warning"
            title="Are you sure you want to logout?"
            extra={[
              <button
                key="cancel"
                onClick={handleCancelLogout}
                className="rounded-[6px] border cancel-btn min-h-[40px] min-w-[120px] text-[15px] font-montserratMedium"
              >
                Cancel
              </button>,
              <Button
                type="primary"
                className="root-btn min-h-[40px] min-w-[120px] text-[15px] font-montserratMedium"
                key="logout"
                onClick={() => {
                  dispatch(actionLogout({ navigate, setVisibleLogoutModal }));
                }}
              >
                Logout
              </Button>,
            ]}
          />
        </Modal>
      </Layout>
    </Layout>
  );
};

export default Root;
