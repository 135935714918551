import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import logoInvoice from "../../assets/img/logoInvoice.png";
import img1 from "../../assets/img/img1.png";
import MonaSansRegular from "../../assets/fonts/Mona-Sans-Regular.ttf";
import MonaSansMedium from "../../assets/fonts/Mona-Sans-Medium.ttf";
import MonaSemiBold from "../../assets/fonts/Mona-Sans-SemiBold.ttf";
import MonaSansBold from "../../assets/fonts/Mona-Sans-Bold.ttf";
import { useParams } from "react-router-dom";

Font.register({
  family: "Mona-Sans-Bold",
  src: MonaSansBold,
});

Font.register({
  family: "Mona-Sans-Regular",
  src: MonaSansRegular,
});
Font.register({
  family: "Mona-Sans-SemiBold",
  src: MonaSemiBold,
});
Font.register({
  family: "Mona-Sans-Medium",
  src: MonaSansMedium,
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    height: "100vh",
  },

  mainSection: {
    margin: "10px",
    border: "1px solid #A1A1A1",
    width: "100vh",
  },
  header: {
    padding: "10px 8px",
    display: "flex",
    flexDirection: "row",
    alignContent: "space-around",
  },
  logo: {
    height: "65px",
    width: "137px",
  },
  menu: {
    borderTop: "1px solid #A1A1A1",
    display: "flex",
    flexDirection: "row",
  },
});

const Invoice = () => {
  const { id } = useParams() || {};

  console.log(id, "id");

  return (
    <PDFViewer width="100%" style={{ height: "100vh" }}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.mainSection}>
            <View style={styles.header}>
              <View
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  marginRight: "190px",
                }}
              >
                <View style={styles.logo}>
                  <Image src={logoInvoice} alt="logo" />
                </View>
              </View>
              <View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "20px",
                      fontFamily: "Mona-Sans-SemiBold",
                      color: "black",
                      paddingBottom: "3px",
                    }}
                  >
                    PROFORMA INVOICE
                  </Text>
                  <Text
                    style={{
                      fontSize: "9px",
                      fontWeight: "500",
                      color: "black",
                      paddingBottom: "3px",
                    }}
                  >
                    #44 KN1 RD, Muhima, Kigali
                  </Text>
                  <Text
                    style={{
                      fontSize: "9px",
                      fontWeight: "500",
                      color: "black",
                      paddingBottom: "3px",
                    }}
                  >
                    info@finefurniture.rw | www.finefurniture.rw
                  </Text>
                  <Text
                    style={{
                      fontSize: "9px",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    +250 788 330 000 | +250 738 330 000 | +250 722 330 000
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.menu}>
              <View
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems:"center",
                  borderRight: "1px solid #A1A1A1",
                  height: "128px,",
                }}
              >
                <View
                  style={{
                    padding: "10px 10px",
                    fontSize: "9px",
                    fontFamily: "Mona-Sans-Bold",
                  }}
                >
                  <Text style={{ color: "black", padding: "5px 0" }}>
                    Performa Invoice No:
                  </Text>
                  <Text style={{ color: "black", padding: "5px 0" }}>
                    Quotation Date:
                  </Text>
                  <Text style={{ color: "black", padding: "5px 0" }}>
                    Validity:
                  </Text>
                  <Text style={{ color: "black", padding: "5px 0" }}>
                    Due date:
                  </Text>
                  <Text style={{ color: "black", padding: "5px 0" }}>TIN:</Text>
                </View>

                <View
                  style={{
                    padding: "10px",
                    fontSize: "9px",
                    fontWeight: "500",
                  }}
                >
                  <Text style={{ color: "black", padding: "5px 0" }}>
                    INV-17
                  </Text>
                  <Text style={{ color: "black", padding: "3px 0" }}>
                    12/09/2024
                  </Text>
                  <Text style={{ color: "black", padding: "5px 0" }}>
                    Due on Receipt
                  </Text>
                  <Text style={{ color: "black", padding: "3px 0" }}>
                    12/09/2024
                  </Text>
                  <Text style={{ color: "black", padding: "5px 0" }}>
                    103240433
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "50%",
                  height: "128px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <View style={{ width: "100%" }}>
                  <Text
                    style={{
                      fontSize: "10px",
                      fontFamily: "Mona-Sans-Bold",
                      textAlign: "center",
                      color: "white",
                      backgroundColor: "#2B2526",
                      padding: "5px",
                    }}
                  >
                    Customer Details{" "}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10x",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      width: "40%",
                      fontSize: "9px",
                      fontFamily: "Mona-Sans-Bold",
                    }}
                  >
                    <Text style={{ color: "black", paddingTop: "1px" }}>
                      Company Name:
                    </Text>
                    <Text style={{ color: "black", padding: "8px 0" }}>
                      Address:
                    </Text>
                    <Text
                      style={{
                        color: "black",
                        paddingTop: "22px",
                        paddingBottoma: "8px",
                      }}
                    >
                      Mobile Number:
                    </Text>
                    <Text style={{ color: "black", paddingTop: "10px" }}>
                      TIN Number:
                    </Text>
                  </View>

                  <View
                    style={{
                      width: "60%",
                      fontSize: "9px",
                      fontWeight: "500",
                      textAlign: "left",
                    }}
                  >
                    <Text style={{ color: "black" }}>
                      PD Prakasa Wibowo (Persero) Tbk
                    </Text>
                    <Text style={{ color: "black", paddingTop: "7px" }}>
                      4140 Parker Rd. Allentown, New
                    </Text>
                    <Text style={{ color: "black" }}>
                      311344140 Parker Rd. Allentown, New
                    </Text>
                    <Text style={{ color: "black" }}>Mexico 31134</Text>
                    <Text style={{ color: "black", padding: "9px 0" }}>
                      +91 3216520168
                    </Text>
                    <Text style={{ color: "black" }}>102005213</Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.table}>
              <View
                style={{
                  borderBottom: "1px solid #A1A1A1",
                  borderTop: "1px solid #A1A1A1",
                  backgroundColor: "#F9F6F4",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    height: "45px",
                    width: "5%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRight: "1px solid #A1A1A1",
                    padding: "5px 10px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      fontFamily: "Mona-Sans-SemiBold",
                    }}
                  >
                    #
                  </Text>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "18%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      fontFamily: "Mona-Sans-SemiBold",
                    }}
                  >
                    Products
                  </Text>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "12%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      fontFamily: "Mona-Sans-SemiBold",
                    }}
                  >
                    Image
                  </Text>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "25%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      fontFamily: "Mona-Sans-SemiBold",
                    }}
                  >
                    Description
                  </Text>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "10%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      fontFamily: "Mona-Sans-SemiBold",
                    }}
                  >
                    Qty
                  </Text>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "15%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      fontFamily: "Mona-Sans-SemiBold",
                    }}
                  >
                    Rate
                  </Text>
                  <Text
                    style={{
                      fontSize: "11px",
                      fontFamily: "Mona-Sans-SemiBold",
                    }}
                  >
                    ( RWF )
                  </Text>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "15%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      fontFamily: "Mona-Sans-SemiBold",
                    }}
                  >
                    Discount
                  </Text>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "15%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    padding: "0px 10px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      fontFamily: "Mona-Sans-SemiBold",
                    }}
                  >
                    Amount
                  </Text>
                </View>
              </View>

              <View
                style={{
                  borderBottom: "1px solid #A1A1A1",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    height: "45px",
                    width: "5%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    borderRight: "1px solid #A1A1A1",
                    padding: "5px 10px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      fontFamily: "Mona-Sans-SemiBold",
                    }}
                  >
                    1
                  </Text>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "18%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    borderRight: "1px solid #A1A1A1",
                    paddingLeft: "10px",
                    paddingRight:"5px"
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontSize: "9px",
                        fontFamily: "Mona-Sans-Regular",
                      }}
                    >
                      Brochure Design
                    </Text>
                    <Text
                      style={{
                        fontSize: "10px",
                        color: "##000000",
                        fontFamily: "Mona-Sans-Medium",
                      }}
                    >
                      -M001
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "12%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRight: "1px solid #A1A1A1",
                  }}
                >
                  <View>
                    <Image
                      src={img1}
                      alt="image"
                      style={{
                        height: "35px",
                        width: "35px",
                      }}
                    />
                  </View>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "25%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text style={{ fontSize: "8px", color: "#676767", lineHeight:"1.3px" }}>
                    Brochure Design Single Sided Color
                  </Text>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "10%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>1.00</Text>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "15%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>300.00</Text>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "15%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>0.00</Text>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "15%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0px 10px",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>300.00</Text>
                </View>
              </View>
              <View
                style={{
                  borderBottom: "1px solid #A1A1A1",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    height: "45px",
                    width: "5%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    borderRight: "1px solid #A1A1A1",
                    padding: "5px 10px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      fontFamily: "Mona-Sans-SemiBold",
                    }}
                  >
                    2
                  </Text>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "18%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    borderRight: "1px solid #A1A1A1",
                    paddingLeft: "10px",
                    paddingRight:"5px"
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontSize: "9px",
                        fontFamily: "Mona-Sans-Regular",
                      }}
                    >
                      Poster Design
                    </Text>
                    <Text
                      style={{
                        fontSize: "10px",
                        color: "##000000",
                        fontFamily: "Mona-Sans-Medium",
                      }}
                    >
                      -P001
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "12%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRight: "1px solid #A1A1A1",
                  }}
                >
                  <View>
                    <Image
                      src={img1}
                      alt="image"
                      style={{
                        height: "35px",
                        width: "35px",
                      }}
                    />
                  </View>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "25%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text style={{ fontSize: "8px", color: "#676767", lineHeight:"1.3px"}}>
                    Brochure Design Single Sided Color
                  </Text>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "10%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>1.00</Text>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "15%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>400.00</Text>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "15%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>0.00</Text>
                </View>

                <View
                  style={{
                    height: "45px",
                    width: "15%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0px 10px",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>400</Text>
                </View>
              </View>
              <View
                style={{
                  borderBottom: "1px solid #A1A1A1",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    // height: "45px",
                    width: "5%",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      fontFamily: "Mona-Sans-SemiBold",
                    }}
                  >
                    3
                  </Text>
                </View>

                <View
                  style={{
                    // height: "45px",
                    width: "18%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    borderRight: "1px solid #A1A1A1",
                    paddingLeft: "10px",
                    paddingRight:"5px"
                  }}
                >
                  <View>
                    <Text
                      style={{
                        fontSize: "9px",
                        fontFamily: "Mona-Sans-Regular",
                      }}
                    >
                      Orthopedic Chair
                    </Text>
                    <Text
                      style={{
                        fontSize: "10px",
                        color: "##000000",
                        fontFamily: "Mona-Sans-Medium",
                      }}
                    >
                      -LA- 825A
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    // height: "45px",
                    width: "12%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRight: "1px solid #A1A1A1",
                  }}
                >
                  <View>
                    <Image
                      src={img1}
                      alt="image"
                      style={{
                        height: "35px",
                        width: "35px",
                      }}
                    />
                  </View>
                </View>

                <View
                  style={{
                    // height: "45px",
                    width: "25%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    borderRight: "1px solid #A1A1A1",
                    padding: "5px 10px",
                  }}
                >
                  <Text style={{ fontSize: "8px", color: "#676767" ,lineHeight:"1.3px"}}>
                    High back office chair 63*70*120 /128.5cm ORTHOPEDIC CHAIR/
                    LA - 825A 1, Upholstery-- mesh back, cushion seat
                  </Text>
                </View>

                <View
                  style={{
                    // height: "45px",
                    width: "10%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>1.00</Text>
                </View>

                <View
                  style={{
                    // height: "45px",
                    width: "15%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>400.00</Text>
                </View>

                <View
                  style={{
                    // height: "45px",
                    width: "15%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    borderRight: "1px solid #A1A1A1",
                    padding: "0px 10px",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>0.00</Text>
                </View>

                <View
                  style={{
                    // height: "45px",
                    width: "15%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0px 10px",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>400</Text>
                </View>
              </View>
              
            </View>

            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                borderBottom: "1px solid #A1A1A1",
              }}
            >
              <View style={{ width: "60%", borderRight: "1px solid #A1A1A1" }}>
                <View style={{ padding: "15px 10px" }}>
                  <Text
                    style={{ fontSize: "11px", fontFamily: "Mona-Sans-Bold" }}
                  >
                    Total in Words
                  </Text>
                  <Text
                    style={{
                      fontSize: "9px",
                      color: "#7C7C7C",
                      paddingTop: "3px",
                    }}
                  >
                    Rwanda Francs Six Million Five Hundred Thrity Five Thousand
                    Only.
                  </Text>
                </View>

                <View style={{ padding: "5px 10px" }}>
                  <Text
                    style={{ fontSize: "11px", fontFamily: "Mona-Sans-Bold" }}
                  >
                    Terms & Conditions
                  </Text>
                  <Text
                    style={{
                      fontSize: "9px",
                      color: "#7C7C7C",
                      paddingTop: "3px",
                    }}
                  >
                    Your company's Terms and Conditions will be displayed here.
                    You can add it in the Invoice Preferences page under
                    Settings
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "20px",
                  }}
                >
                  <View style={{}}>
                    <Text
                      style={{ fontSize: "11px", fontFamily: "Mona-Sans-Bold" }}
                    >
                      Prepared by:
                    </Text>
                  </View>
                  <View style={{}}>
                    <Text
                      style={{ fontSize: "11px", fontFamily: "Mona-Sans-Bold" }}
                    >
                      Authorised by:
                    </Text>
                  </View>
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "40%",
                  justifyContent: "space-between",
                  padding: "5px 10px",
                }}
              >
                <View style={{ flexGrow: "1" }}>
                  <View style={{ padding: "10px 0" }}>
                    <Text
                      style={{
                        fontSize: "11px",
                        fontFamily: "Mona-Sans-Bold",
                      }}
                    >
                      Sub Total (RWF)
                    </Text>
                  </View>
                  <View style={{ padding: "10px 0" }}>
                    <Text style={{ fontSize: "10px" }}>VAT (%)</Text>
                  </View>
                  <View style={{ padding: "10px 0" }}>
                    <Text style={{ fontSize: "10px" }}>Tax Amount</Text>
                  </View>
                  <View style={{ padding: "10px 0" }}>
                    <Text style={{ fontSize: "10px" }}>Discount Amount</Text>
                  </View>
                  <View style={{ padding: "10px 0" }}>
                    {/* <Text style={{ fontSize: "10px" }}>Adjustment</Text> */}
                  </View>

                  <View
                    style={{
                      padding: "11px 0",
                      width: "100%",
                      borderTop: "1px solid #DCDCDC",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: "13px",
                          fontFamily: "Mona-Sans-SemiBold",
                        }}
                      >
                        Total (RWF)
                      </Text>
                    </View>
                  </View>
                </View>

                <View style={{}}>
                  <View
                    style={{
                      padding: "10px 0",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "10px",
                        fontFamily: "Mona-Sans-SemiBold",
                        textAlign: "right",
                      }}
                    >
                      847.46
                    </Text>
                  </View>
                  <View
                    style={{
                      padding: "10px 0",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "10px",
                        textAlign: "end",
                        color: "#7C7C7C",
                      }}
                    >
                      18%
                    </Text>
                  </View>
                  <View
                    style={{
                      padding: "10px 0",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "9px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        color: "#7C7C7C",
                      }}
                    >
                      152.54
                    </Text>
                  </View>
                  <View
                    style={{
                      padding: "10px 0",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Text style={{ fontSize: "10px", color: "#7C7C7C" }}>
                      00.00
                    </Text>
                  </View>
                  <View
                    style={{
                      padding: "11px 0",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    {/* <Text style={{ fontSize: "10px", color: "#7C7C7C" }}>
                      0.00
                    </Text> */}
                  </View>
                  <View
                    style={{
                      padding: "10px 0",
                      width: "100%",
                      borderTop: "1px solid #DCDCDC",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: "13px",
                          fontFamily: "Mona-Sans-Bold",
                        }}
                      >
                        1000.00
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <View
                style={{
                  width: "50%",
                  margin: "10px 0",
                  marginLeft: "10px",
                  border: "1px solid #A1A1A1",
                }}
              >
                <View
                  style={{
                    height: "37px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      borderRight: "1px solid #A1A1A1",
                      width: "40%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      display: "flex",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "10px",
                        fontFamily: "Mona-Sans-SemiBold",
                      }}
                    >
                      Bank Name:
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "60%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      display: "flex",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text style={{ fontSize: "10px" }}>Access Bank </Text>
                  </View>
                </View>

                <View
                  style={{
                    borderTop: "1px solid #A1A1A1",
                    borderBottom: "1px solid #A1A1A1",
                    height: "37px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      borderRight: "1px solid #A1A1A1",
                      width: "40%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      display: "flex",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "10px",
                        fontFamily: "Mona-Sans-SemiBold",
                      }}
                    >
                      Beneficiary:
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "60%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      display: "flex",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text style={{ fontSize: "10px" }}>Fine Furniture LTD</Text>
                  </View>
                </View>

                <View
                  style={{
                    height: "37px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      borderRight: "1px solid #A1A1A1",
                      width: "40%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      display: "flex",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "10px",
                        fontFamily: "Mona-Sans-SemiBold",
                      }}
                    >
                      Swift Code:
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "60%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      display: "flex",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text style={{ fontSize: "10px" }}>ABNGNGLA</Text>
                  </View>
                </View>

                <View
                  style={{
                    borderTop: "1px solid #A1A1A1",
                    height: "37px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      borderRight: "1px solid #A1A1A1",
                      width: "40%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      display: "flex",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "10px",
                        fontFamily: "Mona-Sans-SemiBold",
                      }}
                    >
                      A/C No:
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "60%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      display: "flex",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text style={{ fontSize: "10px" }}>1002100103694701</Text>
                  </View>
                </View>
              </View>

              <View
                style={{
                  width: "50%",
                  margin: "10px 10px",
                  border: "1px solid #A1A1A1",
                }}
              >
                <View
                  style={{
                    height: "37px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      borderRight: "1px solid #A1A1A1",
                      width: "40%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      display: "flex",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "10px",
                        fontFamily: "Mona-Sans-SemiBold",
                      }}
                    >
                      Bank Name:
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "60%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      display: "flex",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text style={{ fontSize: "10px" }}>
                      Bank of Kigali Limited
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    borderTop: "1px solid #A1A1A1",
                    borderBottom: "1px solid #A1A1A1",
                    height: "37px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      borderRight: "1px solid #A1A1A1",
                      width: "40%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      display: "flex",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "10px",
                        fontFamily: "Mona-Sans-SemiBold",
                      }}
                    >
                      Beneficiary:
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "60%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      display: "flex",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text style={{ fontSize: "10px" }}>Fine Furniture LTD</Text>
                  </View>
                </View>

                <View
                  style={{
                    height: "37px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      borderRight: "1px solid #A1A1A1",
                      width: "40%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      display: "flex",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "10px",
                        fontFamily: "Mona-Sans-SemiBold",
                      }}
                    >
                      Swift Code:
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "60%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      display: "flex",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text style={{ fontSize: "10px" }}>BKIGRWEW</Text>
                  </View>
                </View>

                <View
                  style={{
                    borderTop: "1px solid #A1A1A1",
                    height: "37px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <View
                    style={{
                      borderRight: "1px solid #A1A1A1",
                      width: "40%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      display: "flex",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "10px",
                        fontFamily: "Mona-Sans-SemiBold",
                      }}
                    >
                      A/C No:
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "60%",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      display: "flex",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text style={{ fontSize: "10px" }}>00040 0615465 20</Text>
                  </View>
                </View>
              </View>
            </View>

            <View>
              <Text
                style={{
                  fontSize: "9px",
                  color: "#FB2E2E",
                  padding: "0 10px",
                  textAlign: "left",
                  lineHeight: "1.5px",
                }}
              >
                Freight charges as well as transportation & accommodation costs
                of our technicians for assembling of furniture to any upcountry
                locations are not included in this quotation. these costs will
                be provided as & when required by our valued customers
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default Invoice;
