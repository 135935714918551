import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Dropdown,
  Image,
  Modal,
  Pagination,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomSearchInput from "../common/CustomSearchInput";
import PageTitle from "../common/PageTitle";
import SortIcon from "../common/SortIcon";
import { getFirstLetterCapital } from "../helper/common-function";
import productImg from "../../assets/img/productImg.svg";
import {
  actionDeleteProduct,
  actionGetProducts,
} from "../../store/services/productService";
import { selectHasPermission } from "../../store/slices/authSlice";

const Products = () => {
  const [search, setSearch] = useState("");
  const [productId, setProductId] = useState("");
  const [deleteProductModalOpen, setDeleteProductModalOpen] = useState(false);
  const offsetRef = useRef(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasPermission = useSelector(selectHasPermission);
  const { products, allProductsCount, getProductsLoader, deleteProductLoader } =
    useSelector((state) => state.product);

  const getApiCall = (
    offset = 0,
    search = "",
    orderByColumn = "",
    order = ""
  ) => {
    dispatch(
      actionGetProducts({
        offset,
        orderByColumn,
        order,
        search,
      })
    );
  };

  useEffect(() => {
    getApiCall(offsetRef.current);
  }, []);

  const columns = [
    {
      title: "Material Name",
      key: "material_name",
      width: 250,
      ellipsis: true,
      dataIndex: "material_name",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      fixed: "left",
      render: (text, data) => (
        <div
          className="flex cursor-pointer text-blue-600 hover:text-blue-600 items-center "
          onClick={() => {
            navigate("/update-product", {
              state: { productId: data.id },
            });
          }}
        >
          <Avatar
            style={{
              verticalAlign: "middle",
              borderRadius: "5px",
            }}
            src={data?.material_image?.url}
            size="large"
          />
          <Typography.Paragraph
            ellipsis={{ rows: 1, tooltip: true }}
            style={{ width: 200 }}
            className="ml-2 text-blue-600 hover:text-blue-600 font-monaSansRegular text-[15px] !mb-0"
          >
            {text || "-"}
          </Typography.Paragraph>
        </div>
      ),
    },
    {
      title: "Rate",
      key: "material_rate",
      width: 200,
      dataIndex: "material_rate",
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Currency",
      key: "currency",
      width: 200,
      dataIndex: "currency",
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{text?.currency?.currency || "-"}</span>
      ),
    },
    {
      title: "Unit",
      key: "unit",
      width: 200,
      ellipsis: true,
      dataIndex: "unit",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text?.unit_code || "-"}</span>,
    },
    {
      title: "Modal No",
      key: "material_number",
      width: 200,
      ellipsis: true,
      dataIndex: "material_number",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Description",
      key: "material_description",
      width: 100,
      ellipsis: true,
      dataIndex: "material_description",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2 font-monaSansRegular text-[15px] !mb-0"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Other Images",
      key: "material_images",
      ellipsis: true,
      width: 150,
      dataIndex: "material_images",
      render: (text, data) => {
        return data.material_images && data.material_images.length > 0 ? (
          <Image.PreviewGroup
            items={data.material_images.map((item) => ({
              src: `${item.material_images.url}`,
            }))}
          >
            <Badge count={data.material_images.length}>
              <Image
                width={45}
                height={45}
                className="object-contain"
                preview={{ mask: <EyeOutlined /> }}
                src={data.material_images[0].material_images.url}
                style={{
                  objectFit: "cover",
                  borderRadius: "5px",
                  border: "0",
                }}
              />
            </Badge>
          </Image.PreviewGroup>
        ) : (
          <span>-</span>
        );
      },
    },
    {
      title: "Created At",
      key: "created_at",
      ellipsis: true,
      width: 160,
      dataIndex: "created_at",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">
          {text ? dayjs(text).format("DD-MM-YYYY") : "-"}
        </span>
      ),
    },
    {
      title: "Updated At",
      key: "updated_at",
      ellipsis: true,
      width: 160,
      dataIndex: "updated_at",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">
          {text ? dayjs(text).format("DD-MM-YYYY") : "-"}
        </span>
      ),
    },
    {
      title: "Created By",
      key: "created_by",
      ellipsis: true,
      width: 200,
      dataIndex: "created_by",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">
          {`
    ${getFirstLetterCapital(text?.first_name || "")} 
    ${getFirstLetterCapital(text?.last_name || "")}
  `}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 150,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown.Button
            id="custom-drop"
            disabled={
              !hasPermission("material", "delete") &&
              !hasPermission("material", "update")
            }
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () =>
                    navigate("/update-product", {
                      state: { productId: data.id },
                    }),
                  disabled: !hasPermission("material", "update"),
                },
                {
                  label: "Delete",
                  key: "Delete",
                  icon: <DeleteOutlined style={{ fontSize: 12 }} />,
                  danger: true,
                  onClick: () => {
                    setDeleteProductModalOpen(true);
                    setProductId(data.id);
                  },
                  disabled: !hasPermission("material", "delete"),
                },
              ].filter(Boolean),
            }}
          >
            Action
          </Dropdown.Button>
        </>
      ),
    },
  ];

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      getApiCall(offsetRef.current, e.target.value);
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      getApiCall(offsetRef.current, e.target.value);
    }
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    getApiCall(
      offsetRef.current,
      search,
      sorterParams.field,
      sorterParams.order
    );
  };

  const handleCloseProductDeleteModal = () => {
    setProductId("");
    setDeleteProductModalOpen(false);
  };

  return (
    <>
      <PageTitle
        title="Products"
        buttonText="Add Product"
        buttonLink="/create-product"
        isShowButton={selectHasPermission("material", "create")}
      />
      <CustomSearchInput
        handleSearch={handleSearch}
        handleBlurAndPressEnter={handleBlurAndPressEnter}
        searchValue={search}
      />
      <Table
        sticky
        rowKey="id"
        scrollToFirstRowOnChange={true}
        sortDirections={["ascend", "descend"]}
        onChange={handleSortChange}
        bordered
        // virtual
        scroll={{
          x: "max-content",
          y: window.innerWidth < 1500 ? "53vh" : "61vh",
        }}
        pagination={false}
        columns={columns}
        dataSource={products}
        loading={getProductsLoader}
      />
      <div className="flex justify-end mt-3">
        <Pagination
          hideOnSinglePage
          responsive={true}
          current={offsetRef.current + 1}
          className="flex items-center"
          total={allProductsCount}
          onChange={(e) => {
            offsetRef.current = e - 1;
            getApiCall(offsetRef.current, search);
          }}
        />
        <Modal
          className="max-h-[100vh]"
          centered={true}
          footer={false}
          handleReset={handleCloseProductDeleteModal}
          afterClose={handleCloseProductDeleteModal}
          open={deleteProductModalOpen}
          title=""
          onCancel={handleCloseProductDeleteModal}
          onOk={handleCloseProductDeleteModal}
        >
          <p className="text-[19px] text-black font-monaSansMedium mt-2">
            Are you sure you want to delete this product ?
          </p>
          <div className=" flex justify-end mt-[25px] lg:mt-[30px]">
            <Button
              onClick={handleCloseProductDeleteModal}
              type="button"
              className="gray-button min-h-[45px] min-w-[110px]"
            >
              Cancel
            </Button>

            <div className="ml-4">
              <Button
                loading={deleteProductLoader}
                onClick={() => {
                  dispatch(
                    actionDeleteProduct({
                      productId,
                      handleCloseProductDeleteModal,
                    })
                  );
                }}
                className="delete-btn min-h-[45px] min-w-[110px]"
              >
                Delete
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Products;
